import { HTMLSelect, HTMLSelectProps, OptionProps } from '@blueprintjs/core';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';

interface ISelectInputProps extends HTMLSelectProps {
  name: string;
  options: Array<OptionProps>;
  resets?: Record<string, any>;
}

const SelectInput = ({ name, resets = {}, disabled, ...selectProps }: ISelectInputProps) => {
  const [{ value, onChange: formikOnChange, onBlur }] = useField(name);
  const fieldRef = useRef<HTMLSelectElement>();
  const formik = useFormikContext();

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    formikOnChange(event);
    Object.entries(resets).forEach(([key, value]) => formik.setFieldValue(key, value));
  };

  return (
    <HTMLSelect
      {...selectProps}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value || ''}
      elementRef={(ref) => (fieldRef.current = ref || undefined)}
      disabled={disabled || formik.isSubmitting}
    />
  );
};

export default SelectInput;
