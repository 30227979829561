import { gql } from '@apollo/client';
import ContactForm from './components/ContactForm';
import ContactInfo from './components/ContactInfo';
import EmailListItem from '../emails/components/EmailListItem';
import ContactListItem from './components/ContactListItem';

export const CONTACT_LIST_QUERY = gql`
  query ContactList($trashed: Trashed) {
    contacts(trashed: $trashed) {
      ...ContactListItem
    }
  }
  ${ContactListItem.fragments.contact}
`;

export const CONTACT_DETAIL_QUERY = gql`
  query ContactDetail($id: ID!) {
    contact(id: $id) {
      id
      ...ContactInfo
    }
  }
  ${ContactInfo.fragments.contact}
`;

export const CONTACT_EDIT_QUERY = gql`
  query ContactEdit($id: ID!) {
    contact(id: $id) {
      id
      ...ContactEditForm
    }
  }
  ${ContactForm.fragments.edit}
`;

export const CONTACT_EMAILS_QUERY = gql`
  query ContactEmails($id: ID!, $page: Int) {
    contact(id: $id) {
      id
      emails(first: 10, page: $page) {
        data {
          ...EmailListItem
        }
        paginatorInfo {
          currentPage
          hasMorePages
          lastPage
        }
      }
    }
  }
  ${EmailListItem.fragments.emails}
`;

export const CREATE_CONTACT_MUTATION = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      ...ContactInfo
    }
  }
  ${ContactInfo.fragments.contact}
`;

export const UPDATE_CONTACT_MUTATION = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      ...ContactInfo
    }
  }
  ${ContactInfo.fragments.contact}
`;

export const DELETE_CONTACT_MUTATION = gql`
  mutation DeleteContact($id: ID!) {
    deleteContact(id: $id) {
      id
    }
  }
`;

export const RESTORE_CONTACTS_MUTATION = gql`
  mutation RestoreContacts($ids: [ID!]!) {
    restoreContacts(ids: $ids) {
      id
    }
  }
`;

export const FORCE_DELETE_CONTACTS_MUTATION = gql`
  mutation ForceDeleteContacts($ids: [ID!]!) {
    forceDeleteContacts(ids: $ids) {
      id
    }
  }
`;

export const EMPTY_CONTACT_TRASH_MUTATION = gql`
  mutation EmptyContactTrash {
    emptyContactTrash {
      id
    }
  }
`;
