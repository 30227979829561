import React from 'react';
import FutureSignupList from './FutureSignupList';

type FutureSignupsTabProps = {
  isSoul: boolean;
  prospectId: string;
};

const FutureSignupsTab = ({ isSoul, prospectId }: FutureSignupsTabProps) => (
  <div className="overflow-y-auto -mx-5">
    <FutureSignupList isSoul={isSoul} prospectId={prospectId} />
  </div>
);

export default FutureSignupsTab;
