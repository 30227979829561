import { EmailListItemFragment, EmailStatus } from '../../generated/graphql';

export const dummyEmails: EmailListItemFragment[] = [
  {
    id: '1',
    status: EmailStatus.Sent,
    subject: 'Testemail wieder',
    sent_at: '2019-01-04 12:00:00',
  },
  {
    id: '2',
    status: EmailStatus.Sent,
    subject: 'Testemail wieder asdfs',
    sent_at: '2019-01-04 12:00:00',
  },
  {
    id: '3',
    status: EmailStatus.Sent,
    subject: 'Testemail ',
    sent_at: '2019-01-04 12:00:00',
  },
  {
    id: '4',
    status: EmailStatus.Sent,
    subject: 'Testemail wieder d',
    sent_at: '2019-01-04 12:00:00',
  },
  {
    id: '5',
    status: EmailStatus.Sent,
    subject: 'asdff assd wieder ddffsdsasdfasdf ',
    sent_at: '2019-01-04 12:00:00',
  },
  {
    id: '6',
    status: EmailStatus.Sent,
    subject: 'Testemail dfffasdfasdd dfasdf',
    sent_at: '2019-01-04 12:00:00',
  },
  {
    id: '7',
    status: EmailStatus.Sent,
    subject: 'Testemail dfffasdfasdd',
    sent_at: '2019-01-04 12:00:00',
  },
  {
    id: '8',
    status: EmailStatus.Sent,
    subject: 'Testemail dfasdf',
    sent_at: '2019-01-04 12:00:00',
  },
  {
    id: '9',
    status: EmailStatus.Sent,
    subject: 'Testemail dfffasdfasdd dfasdf hello you',
    sent_at: '2019-01-04 12:00:00',
  },
  {
    id: '10',
    status: EmailStatus.Sent,
    subject: 'Testemail dfffasdfasdd dfasdf',
    sent_at: '2019-01-04 12:00:00',
  },
];
