import React from 'react';
import { Elevation } from '@blueprintjs/core';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import NewsletterList from '../components/NewsletterList';
import NewsletterListFilter from '../components/NewsletterListFilter';
import Text from '../../../components/Text';

const NewsletterArchiveListPage = () => (
  <ContentCard elevation={Elevation.TWO} className="mb-5">
    <ContentCardHeader
      leftElement={
        <>
          <Text large className="mr-2">
            Newsletterarchiv
          </Text>
          <NewsletterListFilter archive />
        </>
      }
    />
    <NewsletterList />
  </ContentCard>
);

export default NewsletterArchiveListPage;
