/** @jsxImportSource @emotion/react */
import { Checkbox, Colors, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemListRenderer, ItemRenderer, Suggest2 } from '@blueprintjs/select';
import { css } from '@emotion/react';
import algoliasearch from 'algoliasearch/lite';
import { Fragment, useRef, useState } from 'react';
import { AutocompleteProvided, Configure, Hit } from 'react-instantsearch-core';
import { connectAutoComplete, Highlight, InstantSearch } from 'react-instantsearch-dom';
import { useNavigate } from 'react-router-dom';
import config from '../../../helpers/config';
import { formatDay } from '../../../helpers/dateTimeUtils';
import { getSubdomain } from '../../../helpers/utils';
import { UserHit } from '../../../types';

type CommonHit = {
  name: string;
};

type ModelHit = UserHit | CommonHit;

type SideWideHit = {
  id: number;
  model?: string | null;
  start?: string | null;
} & ModelHit;

type SearchHit = Hit<SideWideHit>;

interface HitSection {
  hits: SearchHit[];
  name: string;
}

enum HitModel {
  Event = 'Event',
  Soul = 'Soul',
  Companion = 'Companion',
  Employee = 'Employee',
  Contact = 'Contact',
  Donor = 'Donor',
  Location = 'Location',
  Organisation = 'Organisation',
}

const getSectionName = (hit: SearchHit): string => {
  switch (hit.model) {
    case HitModel.Soul:
      return 'Genießer';
    case HitModel.Companion:
      return 'Begleiter';
    case HitModel.Contact:
      return 'Ansprechpartner';
    case HitModel.Employee:
      return 'Mitarbeiter';
    case HitModel.Location:
      return 'Veranstaltungsorte';
    case HitModel.Event:
      return 'Veranstaltungen';
    case HitModel.Donor:
      return 'Spender';
    case HitModel.Organisation:
      return 'Organisationen';
    default:
      return 'Unbekannt';
  }
};

const getIconName = (hit: SearchHit) => {
  switch (hit.model) {
    case HitModel.Soul:
      return IconNames.HEART;
    case HitModel.Companion:
      return IconNames.HAND;
    case HitModel.Contact:
      return IconNames.COMMENT;
    case HitModel.Employee:
      return IconNames.PERSON;
    case HitModel.Location:
      return IconNames.MAP_MARKER;
    case HitModel.Event:
      return IconNames.TIMELINE_EVENTS;
    case HitModel.Donor:
      return IconNames.BANK_ACCOUNT;
    case HitModel.Organisation:
      return IconNames.HOME;
    default:
      return IconNames.BLANK;
  }
};

const buildSections = (hits: SearchHit[]) =>
  hits.reduce<HitSection[]>((acc, hit) => {
    const sectionName = getSectionName(hit);

    // Add section if it doesn't exist yet
    if (!acc.some((section) => section.name === sectionName)) {
      return [...acc, { hits: [hit], name: sectionName }];
    }

    // Add hit to existing section
    return acc.map((section) => {
      if (section.name === sectionName) {
        return { ...section, hits: [...section.hits, hit] };
      }
      return section;
    });
  }, []);

const renderHit: ItemRenderer<SearchHit> = (hit, { handleClick, modifiers }) => (
  <MenuItem
    active={modifiers.active}
    disabled={modifiers.disabled}
    key={hit.id}
    onClick={handleClick}
    icon={getIconName(hit)}
    text={renderHitText(hit)}
    label={renderHitLabel(hit)}
  />
);

const renderHitText = (hit: SearchHit) => {
  switch (hit.model) {
    case HitModel.Soul:
    case HitModel.Companion:
    case HitModel.Employee:
    case HitModel.Contact:
      return (
        <Fragment>
          <Highlight hit={hit} attribute="first_name" css={styles.highlight} />{' '}
          <Highlight hit={hit} attribute="last_name" css={styles.highlight} />
        </Fragment>
      );
    case HitModel.Location:
    case HitModel.Event:
    case HitModel.Donor:
    case HitModel.Organisation:
      return <Highlight hit={hit} attribute="name" css={styles.highlight} />;
    default:
      return 'Unbekannt';
  }
};

const renderHitLabel = (hit: SearchHit) => {
  switch (hit.model) {
    case HitModel.Event:
      return hit.start ? formatDay(hit.start) : '';
  }
};

const renderHitList: ItemListRenderer<SearchHit> = ({ items: hits, renderItem }) => {
  if (!hits || !hits.length) {
    return (
      <Menu>
        <MenuItem disabled={true} text="Keine Resultate." />
      </Menu>
    );
  }

  const sections = buildSections(hits);

  return (
    <Menu>
      {sections.map((section) => (
        <Fragment key={section.name}>
          <MenuDivider title={section.name} />
          {section.hits.map(renderItem)}
        </Fragment>
      ))}
    </Menu>
  );
};

const renderValue = (hit: SearchHit) => {
  switch (hit.model) {
    case HitModel.Soul:
    case HitModel.Companion:
    case HitModel.Employee:
    case HitModel.Contact:
      return (hit as UserHit).display_name;
    case HitModel.Location:
    case HitModel.Event:
    case HitModel.Donor:
    case HitModel.Organisation:
      return (hit as CommonHit).name;
    default:
      return hit.id.toString();
  }
};

const SearchInput = ({ hits, refine, currentRefinement }: AutocompleteProvided<SideWideHit>) => {
  const navigate = useNavigate();
  const goToEntity = (hit: SearchHit) => {
    switch (hit.model) {
      case HitModel.Soul:
        navigate(`/souls/${hit.id}`);
        break;
      case HitModel.Companion:
        navigate(`/companions/${hit.id}`);
        break;
      case HitModel.Employee:
        navigate(`/employees/${hit.id}`);
        break;
      case HitModel.Contact:
        navigate(`/contacts/${hit.id}`);
        break;
      case HitModel.Location:
        navigate(`/locations/${hit.id}`);
        break;
      case HitModel.Event:
        navigate(`/events/${hit.id}`);
        break;
      case HitModel.Donor:
        navigate(`/donors/${hit.id}`);
        break;
      case HitModel.Organisation:
        navigate(`/organisations/${hit.id}`);
        break;
      default:
    }
  };

  // Sort hits by model first so the keyboard navigation
  // keeps working
  const modelOrder = hits.reduce((acc, hit) => {
    if (!acc.includes(hit.model ?? 'Unknown')) {
      acc.push(hit.model ?? 'Unknown');
    }

    return acc;
  }, [] as string[]);

  hits.sort((a, b) =>
    a.model && b.model ? modelOrder.indexOf(a.model ?? 'Unknown') - modelOrder.indexOf(b.model ?? 'Unknown') : 0,
  );

  return (
    <div css={styles.searchBar}>
      <Suggest2<SearchHit>
        itemListRenderer={renderHitList}
        itemListPredicate={(_, items) => items} // Filtering done by Algolia obviously
        query={currentRefinement}
        onQueryChange={refine}
        openOnKeyDown
        resetOnClose
        inputProps={{
          leftIcon: IconNames.SEARCH,
          placeholder: 'Suchen...',
        }}
        fill
        inputValueRenderer={renderValue}
        itemRenderer={renderHit}
        itemsEqual="objectID"
        items={hits}
        onItemSelect={goToEntity}
        popoverProps={{ minimal: true }}
      />
    </div>
  );
};

const ConnectedSearchInput = connectAutoComplete<SideWideHit>(SearchInput);

const SiteSearch = () => {
  const searchClient = useRef(algoliasearch(config.algolia.appId, config.algolia.appSecret)).current;
  const [includeArchive, setIncludeArchive] = useState(false);

  return (
    <div className="flex flex-1 justify-start items-center">
      <InstantSearch searchClient={searchClient} indexName={`${getSubdomain()}_site_wide`}>
        <ConnectedSearchInput />
        <Configure hitsPerPage={12} filters={includeArchive ? '' : 'is_archived != 1'} />
        <Checkbox
          className="mb-0 ml-4"
          inline
          checked={includeArchive}
          label="Mit Archiv"
          onChange={() => setIncludeArchive(!includeArchive)}
        />
      </InstantSearch>
    </div>
  );
};

export default SiteSearch;

const styles = {
  searchBar: css`
    max-width: 500px;
    flex-basis: 100%;
  `,
  highlight: css`
    em {
      font-style: normal;
      font-weight: bold;
      color: ${Colors.BLUE1};
    }
  `,
};
