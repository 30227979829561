import { Button, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { useNavigate } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContactInfoFragment, useDeleteContactMutation } from '../../../generated/graphql';
import { showErrorMessage } from '../../../helpers/graphql';

type ContactDetailMenuProps = {
  contact: ContactInfoFragment;
  onEdit: () => void;
};

const ContactDetailMenu = ({ contact, onEdit }: ContactDetailMenuProps) => {
  const navigate = useNavigate();
  const [deleteContact, { loading: deleting }] = useDeleteContactMutation({
    variables: {
      id: contact.id,
    },
    onCompleted: () => {
      AppToaster.success(`${contact.display_name}  wurde in den Papierkorb gelegt`);
      navigate(-1);
    },
    onError: showErrorMessage,
  });

  return (
    <>
      <Button text="Bearbeiten" icon={IconNames.EDIT} className="ml-2" onClick={onEdit} />
      <Popover2
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteContact as any} />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} className="ml-2" loading={deleting} />
      </Popover2>
    </>
  );
};

export default ContactDetailMenu;
