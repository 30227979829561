import { Button, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import React, { useState } from 'react';
import AppToaster from '../../../AppToaster';
import EmailUsersOverlay from '../../../components/EmailUsersOverlay';
import { EventDetailPageFragment } from '../../../generated/graphql';
import AddSignupsOverlay from './AddSignupsOverlay';

type SignupsMenuProps = {
  event: EventDetailPageFragment;
};

const SignupsMenu = ({ event }: SignupsMenuProps) => {
  const [isAddSignupsOverlayOpen, setIsAddSignupsOverlayOpen] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogAlertOpen] = useState(false);

  const onEmailsSent = () => {
    AppToaster.success('Emails wurden versandt!');
    setIsEmailDialogAlertOpen(false);
  };

  const users = event.prospects
    .filter((prospect) => (prospect.soul ? prospect.soul.email : prospect.companion!.email))
    .map((prospect) =>
      prospect.soul
        ? {
            id: prospect.soul.id,
            label: prospect.soul.display_name,
          }
        : {
            id: prospect.companion!.id,
            label: prospect.companion!.display_name,
          },
    );

  return (
    <>
      <AddSignupsOverlay
        eventId={event.id}
        onClose={() => setIsAddSignupsOverlayOpen(false)}
        isOpen={isAddSignupsOverlayOpen}
      />
      <EmailUsersOverlay
        isOpen={isEmailDialogOpen}
        onSuccess={onEmailsSent}
        onClose={() => setIsEmailDialogAlertOpen(false)}
        users={users}
      />
      <Button
        text="Hinzufügen"
        icon={IconNames.ADD}
        className="ml-2"
        onClick={() => setIsAddSignupsOverlayOpen(true)}
      />
      <Popover2
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem
              text="Nachricht an alle Interessenten"
              icon={IconNames.ENVELOPE}
              onClick={() => setIsEmailDialogAlertOpen(true)}
            />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} className="ml-2" />
      </Popover2>
    </>
  );
};

export default SignupsMenu;
