import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ContactEditOverlay from '../components/ContactEditOverlay';
import ContactEmails from '../components/ContactEmails';
import ContactInfo from '../components/ContactInfo';

type RouteParams = {
  contactId?: string;
};

const ContactDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const { contactId } = useParams<RouteParams>();

  return (
    <>
      <ContactEditOverlay id={contactId || ''} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContactInfo onEdit={() => setIsEditDialogOpen(true)} />
      <ContactEmails />
    </>
  );
};

export default ContactDetailPage;
