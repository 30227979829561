import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { TagCreateFormFragment, Intent } from '../../../generated/graphql';
import TagForm from './TagForm';
import { IconNames } from '@blueprintjs/icons';

type TagCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
  onClose: () => void;
} & IOverlayProps;

const TagCreateOverlay = ({ onSuccess, ...overlayProps }: TagCreateOverlayProps) => {
  const initialValues: TagCreateFormFragment = {
    name: '',
    icon: IconNames.BLANK,
    intent: Intent.None,
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <TagForm
        onCancel={closeOverlay}
        onCreated={({ createTag }) => {
          onSuccess(createTag.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default TagCreateOverlay;
