import { Intent, Position, OverlayToaster } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

/** Singleton toaster instance. Create separate instances for different options. */
const AppToasterInstance = OverlayToaster.create({
  className: 'recipe-toaster',
  position: Position.TOP,
});

const AppToaster = {
  show: AppToasterInstance.show,
  success: (message: React.ReactNode) =>
    AppToasterInstance.show({ message, intent: Intent.SUCCESS, icon: IconNames.TICK }),
  danger: (message: React.ReactNode) =>
    AppToasterInstance.show({
      message,
      intent: Intent.DANGER,
      icon: IconNames.WARNING_SIGN,
    }),
};

export default AppToaster;
