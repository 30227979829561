import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useCurrentTenantDetailLazyQuery } from '../../../generated/graphql';
import SettingsForm from './SettingsForm';

type SettingsEditOverlayProps = {
  onClose: () => void;
} & IOverlayProps;

const SettingsEditOverlay = (overlayProps: SettingsEditOverlayProps) => {
  const [loadSettings, { data, called, loading, error }] = useCurrentTenantDetailLazyQuery();

  if (overlayProps.isOpen && !loading && !called) {
    loadSettings();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data?.currentTenant) return <ErrorCard />;

    // API Key is not editable
    const { legacy_api_key, ...initialValues } = data.currentTenant;

    return (
      <SettingsForm
        onCancel={() => overlayProps.onClose?.()}
        onSuccess={() => {
          loadSettings();
          overlayProps.onClose?.();
        }}
        initialValues={initialValues}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default SettingsEditOverlay;
