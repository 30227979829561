import { gql } from '@apollo/client';
import React from 'react';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { NewsletterListItemFragment, NewsletterStatus } from '../../../generated/graphql';
import { formatDateTime } from '../../../helpers/dateTimeUtils';
import { getMailcoachSegmentText, getNewsletterStatusText } from '../../../helpers/dataUtils';

export type NewsletterListProps = {
  item: NewsletterListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

const NewsletterListItem = ({ item, skeleton, onClick }: NewsletterListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <Text skeleton={skeleton}>{item.subject}</Text>
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{getMailcoachSegmentText(item.segment)}</Text>
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton} muted={item.status !== NewsletterStatus.Sent}>
        {item.sent_at ? formatDateTime(item.sent_at, 'dd.MM.yyyy HH:mm') : getNewsletterStatusText(item.status)}
      </Text>
    </TableCell>
  </tr>
);

NewsletterListItem.fragments = {
  newsletter: gql`
    fragment NewsletterListItem on Newsletter {
      id
      subject
      status
      sent_at
      segment
    }
  `,
};

export default NewsletterListItem;
