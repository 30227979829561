import React from 'react';

type TableHeaderCellProps = {
  colSpan?: number;
  minWidth?: number;
  width?: number;
  children?: React.ReactNode;
};

const TableHeaderCell = ({ colSpan, width, minWidth, children }: TableHeaderCellProps) => (
  <td
    style={styles.width(width, minWidth)}
    className="uppercase bg-light-gray-500 text-gray-100 relative first:pl-5 last:pr-5"
    colSpan={colSpan}
  >
    {!!children && children}
  </td>
);

export default TableHeaderCell;

const styles = {
  width: (width?: number, minWidth?: number) => ({
    ...(width && { width }),
    ...(minWidth && { minWidth }),
  }),
};
