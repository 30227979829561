import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useEmployeeEditLazyQuery } from '../../../generated/graphql';
import EmployeeForm from './EmployeeForm';

interface IEmployeeEditOverlayProps extends IOverlayProps {
  id: string;
  onClose: () => void;
}

const EmployeeEditOverlay = ({ id, ...overlayProps }: IEmployeeEditOverlayProps) => {
  const [loadEmployee, { data, called, loading, error }] = useEmployeeEditLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadEmployee();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data || !data.employee) return <ErrorCard />;
    return (
      <EmployeeForm
        onCancel={() => overlayProps.onClose?.()}
        onUpdated={() => {
          loadEmployee();
          overlayProps.onClose?.();
        }}
        initialValues={data.employee}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default EmployeeEditOverlay;
