import React from 'react';
import useInfiniteScroll, { UseInfiniteScrollProps } from '../hooks/useInfiniteScroll';

type InfiniteScrollWrapperProps = {
  children: React.ReactNode;
} & UseInfiniteScrollProps;

const InfiniteScrollWrapper = ({ children, ...infiniteScrollProps }: InfiniteScrollWrapperProps) => {
  const infiniteRef = useInfiniteScroll<HTMLDivElement>({
    ...infiniteScrollProps,
  });

  return <div ref={infiniteRef}>{children}</div>;
};

export default InfiniteScrollWrapper;
