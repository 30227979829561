import { Button, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { useNavigate } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { LocationInfoFragment, useDeleteLocationMutation } from '../../../generated/graphql';
import { showErrorMessage } from '../../../helpers/graphql';

type LocationDetailMenuProps = {
  location: LocationInfoFragment;
  onEdit: () => void;
};

const LocationDetailMenu = ({ location, onEdit }: LocationDetailMenuProps) => {
  const navigate = useNavigate();
  const [deleteLocation, { loading: deleting }] = useDeleteLocationMutation({
    variables: {
      id: location.id,
    },
    onCompleted: () => {
      AppToaster.success(`${location.name}  wurde in den Papierkorb gelegt`);
      navigate(-1);
    },
    onError: showErrorMessage,
  });

  return (
    <>
      <Button text="Bearbeiten" icon={IconNames.EDIT} className="ml-2" onClick={onEdit} />
      <Popover2
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteLocation as any} />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} className="ml-2" loading={deleting} />
      </Popover2>
    </>
  );
};

export default LocationDetailMenu;
