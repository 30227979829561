import { MenuItem } from '@blueprintjs/core';
import { ItemRenderer } from '@blueprintjs/select';
import React from 'react';
import DropdownInput from '../../../components/DropdownInput';
import { ICON_OPTIONS } from '../../../helpers/constants';
import { IconOption } from '../../../types';

type IconSelectProps = {
  name: string;
};

const renderIconOption: ItemRenderer<IconOption> = (item, { handleClick, modifiers, query }) =>
  !modifiers.matchesPredicate ? null : (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.id}
      onClick={handleClick}
      text={item.id}
      icon={item.id}
    />
  );

const IconSelect = ({ name }: IconSelectProps) => (
  <DropdownInput<IconOption>
    name="icon"
    labelPath="id"
    items={ICON_OPTIONS}
    itemToValue={(item) => item && item.id}
    valueToItem={(value) => ({ id: value })}
    renderItem={renderIconOption}
  />
);

export default IconSelect;
