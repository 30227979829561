import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { gql } from '@apollo/client';
import React from 'react';
import { PersonOrganisationSelectFragment, useOrganisationsQuery } from '../generated/graphql';
import DropdownInput from './DropdownInput';

type Organisation = PersonOrganisationSelectFragment;
type PersonOrganisationSelectProps = {
  name: string;
  resets?: Record<string, any>;
};

const PersonOrganisationSelect = ({ name, resets }: PersonOrganisationSelectProps) => {
  const { data, loading, error } = useOrganisationsQuery();

  if (loading) return <Button loading />;
  if (error || !data || !data.organisations) {
    return <Button disabled text="Fehler" rightIcon={IconNames.DOUBLE_CARET_VERTICAL} />;
  }

  return <DropdownInput<Organisation> name={name} labelPath="name" items={data.organisations} resets={resets} />;
};

PersonOrganisationSelect.fragments = {
  organisations: gql`
    fragment PersonOrganisationSelect on Organisation {
      id
      name
    }
  `,
};

export default PersonOrganisationSelect;
