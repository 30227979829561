import { CategoryListItemFragment } from '../../generated/graphql';

export const dummyCategories: CategoryListItemFragment[] = [
  {
    id: '1',
    name: 'Normal Category Name',
    color: 'ff0000',
    description: 'Dies ist die Beschreibung einer Kategorie',
  },
  {
    id: '2',
    name: 'Smaller',
    color: '2222ff',
  },
  {
    id: '3',
    name: 'Little Bigger',
    color: 'ffff22',
  },
  {
    id: '4',
    name: 'Small',
    color: 'ff8c00',
    description: 'Dies ist die Beschreibung einer Kategorie',
  },
];
