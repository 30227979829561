/** @jsxImportSource @emotion/react */
import { Card, Colors, CardProps } from '@blueprintjs/core';
import { css } from '@emotion/react';
import React from 'react';

type ContentCardHeaderProps = {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  children?: React.ReactNode;
};

export const ContentCardHeader = ({ leftElement, rightElement, children }: ContentCardHeaderProps) => {
  if (!leftElement && !rightElement && !children) {
    return null;
  }

  if (children) {
    return <div className="flex justify-between items-center py-4 px-5 border-b border-light-gray-100">{children}</div>;
  }

  return (
    <div className="flex justify-between items-center py-4 px-5 border-b border-light-gray-100">
      <div className="flex items-center">{leftElement}</div>
      <div className="flex content-end items-center">{rightElement}</div>
    </div>
  );
};

type ContentCardFooterProps = {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  children?: React.ReactNode;
};

export const ContentCardFooter = ({ leftElement, rightElement, children }: ContentCardFooterProps) => {
  if (!leftElement && !rightElement && !children) {
    return null;
  }

  if (children) {
    return <div className="flex justify-between items-center py-4 px-5 border-t border-light-gray-100">{children}</div>;
  }

  return (
    <div className="flex justify-between items-center py-4 px-5 border-t border-light-gray-100">
      <div className="flex">{leftElement}</div>
      <div className="flex justify-end">{rightElement}</div>
    </div>
  );
};

type ContentCardScrollProps = {
  noPadding?: boolean;
  noFooter?: boolean;
  children?: React.ReactNode;
};

export const ContentCardScroll = ({ children, noPadding, noFooter }: ContentCardScrollProps) => (
  <div css={[styles.scrollContent, noPadding && styles.noPadding, noFooter && styles.noFooter]}>{children}</div>
);

type ContentCardIframeProps = {
  noFooter?: boolean;
  title: string;
  src: string;
};

export const ContentCardIframe = ({ noFooter, title, src }: ContentCardIframeProps) => (
  <iframe css={[styles.scrollIframe, noFooter && styles.noFooterIframe]} title={title} src={src}></iframe>
);

export const ContentCard = ({ children, className, ...rest }: CardProps) => (
  <Card {...rest} className={[className, 'p-0'].join(' ')}>
    {children}
  </Card>
);

const styles = {
  scrollContent: css`
    background-color: ${Colors.LIGHT_GRAY5};
    flex-shrink: 1;
    padding: 20px;
    overflow-y: auto;
    max-height: calc(80vh - 126px); // 100vh - (2*10vh + 2*63px)
  `,
  scrollIframe: css`
    width: 100%;
    border: none;
    background-color: ${Colors.LIGHT_GRAY5};
    flex-shrink: 1;
    overflow-y: auto;
    height: calc(80vh - 126px); // 100vh - (2*10vh + 2*63px)
  `,
  noPadding: css`
    padding: 0px;
  `,
  noFooter: css`
    max-height: calc(80vh - 63px); // 100vh - (2*10vh + 1*63px)
  `,
  noFooterIframe: css`
    height: calc(80vh - 63px); // 100vh - (2*10vh + 1*63px)
  `,
};
