import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { EmployeeCreateFormFragment, UserStatus } from '../../../generated/graphql';
import EmployeeForm from './EmployeeForm';

type EmployeeCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
  onClose: () => void;
} & IOverlayProps;

const EmployeeCreateOverlay = ({ onSuccess, ...overlayProps }: EmployeeCreateOverlayProps) => {
  const initialValues: EmployeeCreateFormFragment = {
    meta: {
      position: '',
    },
    status: UserStatus.Active,
    first_name: '',
    last_name: '',
    email: '',
    roles: [],
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <EmployeeForm
        onCancel={closeOverlay}
        onCreated={({ createEmployee }) => {
          onSuccess(createEmployee.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default EmployeeCreateOverlay;
