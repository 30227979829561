import { FetchResult, gql } from '@apollo/client';
import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import SelectInput from '../../../components/SelectInput';
import Text from '../../../components/Text';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import {
  CreateTagMutation,
  TagCreateFormFragment,
  TagEditFormFragment,
  UpdateTagInput,
  UpdateTagMutation,
  useCreateTagMutation,
  useUpdateTagMutation,
} from '../../../generated/graphql';
import { INTENT_OPTIONS } from '../../../helpers/constants';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';
import IconSelect from './IconSelect';

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
  icon: Yup.string().required('Erforderlich'),
  intent: Yup.string().required('Erforderlich'),
});

type TagFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateTagMutation) => void;
  onUpdated?: (data: UpdateTagMutation) => void;
  initialValues: TagCreateFormFragment | TagEditFormFragment;
};

const isEditData = (initialValues: TagCreateFormFragment | TagEditFormFragment): initialValues is TagEditFormFragment =>
  'id' in initialValues;

const isUpdateResult = (data: CreateTagMutation | UpdateTagMutation): data is UpdateTagMutation => 'updateTag' in data;

const TagForm = ({ onCancel, onCreated, onUpdated, initialValues }: TagFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateTag] = useUpdateTagMutation();
  const [createTag] = useCreateTagMutation();
  const handleSubmit = useSubmit<
    TagCreateFormFragment | TagEditFormFragment,
    FetchResult<CreateTagMutation> | FetchResult<UpdateTagMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateTag({
            variables: {
              input: buildDiff(initialValues, values) as UpdateTagInput,
            },
          })
        : createTag({
            variables: {
              input: {
                ...values,
              },
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<Text large>Attribut {isEdit ? 'bearbeiten' : 'hinzufügen'}</Text>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
              <TextInput name="name" placeholder="Name" />
            </FormGroup>
            <FormGroup label="Icon" labelInfo="(erforderlich)" name="icon">
              <IconSelect name="icon" />
            </FormGroup>
            <FormGroup label="Intention" labelInfo="(erforderlich)" name="intent">
              <SelectInput name="intent" options={INTENT_OPTIONS} />
            </FormGroup>

            <FormGroup name="description">
              <TextAreaInput name="description" placeholder="Beschreibung" fill rows={10} />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <>
                <Button text="Abbrechen" onClick={onCancel} className="ml-2" />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  className="ml-2"
                />
              </>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

TagForm.fragments = {
  create: gql`
    fragment TagCreateForm on Tag {
      name
      icon
      intent
      description
    }
  `,
  edit: gql`
    fragment TagEditForm on Tag {
      id
      name
      icon
      intent
      description
    }
  `,
};

export default TagForm;
