import React from 'react';
import { useParams } from 'react-router-dom';
import PaginatedTable from '../../../components/PaginatedTable';
import { useDonorLocationsQuery } from '../../../generated/graphql';
import LocationListItem from '../../locations/components/LocationListItem';
import { dummyLocations } from '../../locations/dummy';

type RouteParams = {
  donorId?: string;
};

const DonorLocationList = () => {
  const { donorId } = useParams<RouteParams>();
  const { data, loading, error } = useDonorLocationsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: donorId || '',
    },
  });

  const locations = data?.donor?.locations || [];

  return (
    <PaginatedTable
      columns={['Name', 'Adresse', 'Treffpunkt']}
      interactive
      loading={loading}
      hasError={!!error}
      empty={!locations.length}
    >
      {(loading ? dummyLocations : locations).map((item) => (
        <LocationListItem key={item.id} item={item} skeleton={loading} />
      ))}
    </PaginatedTable>
  );
};

export default DonorLocationList;
