import { AppEnv, ENV } from './constants';

const envConfig = {
  [AppEnv.Dev]: {
    baseUrl: 'http://api.culture-key-backend.test',
    sentry: {
      environment: 'local',
    },
    algolia: {
      appId: 'YJSPP201CP',
      appSecret: '00ab1eda25ab94fe284c22ab4e58ece5',
    },
  },
  [AppEnv.Stage]: {
    baseUrl: 'https://api.staging.kulturschluessel.org',
    sentry: {
      environment: 'staging',
    },
    algolia: {
      appId: '2PU5TKEU32',
      appSecret: '28efc5c3ad9af17bc045d3de3a19efe8',
    },
  },
  [AppEnv.Prod]: {
    baseUrl: 'https://api.kulturschluessel.org',
    sentry: {
      environment: 'production',
    },
    algolia: {
      appId: 'X0EJQ5FVKC',
      appSecret: '8065ca37843e5261ef6b69e87d35180d',
    },
  },
};

const config = envConfig[ENV];

export default config;
