import { gql } from '@apollo/client';
import { Button, Classes, Collapse, Elevation, Icon, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useLocationDetailQuery } from '../../../generated/graphql';
import LocationDetailMenu from './LocationDetailMenu';
import { useState } from 'react';

type LocationInfoProps = {
  onEdit: () => void;
};

type RouteParams = {
  locationId?: string;
};

const LocationInfo = ({ onEdit }: LocationInfoProps) => {
  const [fullDisabilityInfoPhysicalShown, setFullDisabilityInfoPhysicalShown] = useState<boolean>(false);
  const [fullDisabilityInfoAudioShown, setFullDisabilityInfoAudioShown] = useState<boolean>(false);
  const [fullDisabilityInfoVisualShown, setFullDisabilityInfoVisualShown] = useState<boolean>(false);
  const { locationId } = useParams<RouteParams>();
  const { data, loading, error } = useLocationDetailQuery({
    variables: {
      id: locationId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data?.location) return <NotFoundCard resource="Veranstaltungsort" />;

  const { location } = data;

  const address: string | null =
    location.street && location.postal_code && location.city
      ? `${location.street}, ${location.postal_code} ${location.city}`
      : null;

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <ContentCardHeader
        leftElement={
          <>
            <Text large>{location.name}</Text>
            <Tag minimal className="ml-2">
              Veranstaltungsort
            </Tag>
          </>
        }
        rightElement={<LocationDetailMenu location={location} onEdit={onEdit} />}
      />
      <div className="flex py-4 px-5">
        <PropertyList>
          <PropertyListHeader>Details</PropertyListHeader>
          <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
          <PropertyListItem label="Bild">
            {location.poster?.thumb_url ? (
              <img alt="poster" style={{ maxHeight: 100, maxWidth: 200 }} src={location.poster?.thumb_url} />
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
          <PropertyListItem label="Treffpunkt" value={location.meeting_point} />
          <PropertyListItem
            label="Öff. Nahverkehr"
            value={location.public_transport || '—'}
            showValueDisabled={!location.public_transport}
          />
        </PropertyList>
        <PropertyListDivider />
        <PropertyList>
          <PropertyListHeader>Barrierefreiheit</PropertyListHeader>
          <PropertyListItem label="Barrierefrei">
            <Icon icon={location.accessible ? IconNames.TICK : IconNames.CROSS} />
          </PropertyListItem>
          <PropertyListItem label="Rollstuhl/Rollator Info">
            {location.accessibility_info_physical ? (
              <>
                <Collapse isOpen={fullDisabilityInfoPhysicalShown} className={Classes.RUNNING_TEXT}>
                  {location.accessibility_info_physical}
                  <br />
                  <br />
                </Collapse>
                <Button
                  small
                  onClick={() => setFullDisabilityInfoPhysicalShown(!fullDisabilityInfoPhysicalShown)}
                  text={fullDisabilityInfoPhysicalShown ? 'Ausblenden' : 'Einblenden'}
                  rightIcon={fullDisabilityInfoPhysicalShown ? IconNames.CARET_UP : IconNames.CARET_DOWN}
                />
              </>
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
          <PropertyListItem label="Sehbehinderung Info">
            {location.accessibility_info_visual ? (
              <>
                <Collapse isOpen={fullDisabilityInfoVisualShown} className={Classes.RUNNING_TEXT}>
                  {location.accessibility_info_visual}
                  <br />
                  <br />
                </Collapse>
                <Button
                  small
                  onClick={() => setFullDisabilityInfoVisualShown(!fullDisabilityInfoVisualShown)}
                  text={fullDisabilityInfoVisualShown ? 'Ausblenden' : 'Einblenden'}
                  rightIcon={fullDisabilityInfoVisualShown ? IconNames.CARET_UP : IconNames.CARET_DOWN}
                />
              </>
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
          <PropertyListItem label="Hörbehinderung Info">
            {location.accessibility_info_audio ? (
              <>
                <Collapse isOpen={fullDisabilityInfoAudioShown} className={Classes.RUNNING_TEXT}>
                  {location.accessibility_info_audio}
                  <br />
                  <br />
                </Collapse>
                <Button
                  small
                  onClick={() => setFullDisabilityInfoAudioShown(!fullDisabilityInfoAudioShown)}
                  text={fullDisabilityInfoAudioShown ? 'Ausblenden' : 'Einblenden'}
                  rightIcon={fullDisabilityInfoAudioShown ? IconNames.CARET_UP : IconNames.CARET_DOWN}
                />
              </>
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
        </PropertyList>
      </div>
    </ContentCard>
  );
};

LocationInfo.fragments = {
  location: gql`
    fragment LocationInfo on Location {
      id
      name
      poster {
        name
        thumb_url
      }
      street
      city
      postal_code
      meeting_point
      public_transport
      accessible
      accessibility_info_visual
      accessibility_info_visual_html
      accessibility_info_visual_markdown
      accessibility_info_audio
      accessibility_info_audio_html
      accessibility_info_audio_markdown
      accessibility_info_physical
      accessibility_info_physical_html
      accessibility_info_physical_markdown
    }
  `,
};

export default LocationInfo;
