import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useAllotmentEditLazyQuery } from '../../../generated/graphql';
import AllotmentForm from './AllotmentForm';

interface IAllotmentEditOverlayProps extends IOverlayProps {
  id: string;
  onClose: () => void;
}

const AllotmentEditOverlay = ({ id, ...overlayProps }: IAllotmentEditOverlayProps) => {
  const [loadAllotment, { data, called, loading, error }] = useAllotmentEditLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadAllotment();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data?.allotment) return <ErrorCard />;
    return (
      <AllotmentForm
        onCancel={() => overlayProps.onClose?.()}
        onUpdated={() => {
          loadAllotment();
          overlayProps.onClose?.();
        }}
        initialValues={data.allotment}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default AllotmentEditOverlay;
