import { gql } from '@apollo/client';
import EventForm from './components/EventForm';
import EventListItem from './components/EventListItem';
import EventDetailPage from './pages/EventDetailPage';

export const EVENT_LIST_QUERY = gql`
  query EventList(
    $page: Int
    $status: EventStatus
    $start: DateTimeRange
    $donor: Int
    $withArchived: Boolean
    $onlyArchived: Boolean
    $orderByColumn: QueryEventsOrderByColumn = START
    $orderByOrder: SortOrder = ASC
    $trashed: Trashed
  ) {
    events(
      first: 20
      page: $page
      status: $status
      start: $start
      donor: $donor
      withArchived: $withArchived
      onlyArchived: $onlyArchived
      orderBy: [{ column: $orderByColumn, order: $orderByOrder }, { column: CREATED_AT, order: ASC }]
      trashed: $trashed
    ) {
      data {
        ...EventListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
  ${EventListItem.fragments.event}
`;

export const EVENT_DETAIL_QUERY = gql`
  query EventDetail($id: ID!) {
    event(id: $id) {
      id
      ...EventDetailPage
    }
  }
  ${EventDetailPage.fragments.event}
`;

export const EVENT_EDIT_QUERY = gql`
  query EventEdit($id: ID!) {
    event(id: $id) {
      id
      ...EventEditForm
    }
  }
  ${EventForm.fragments.edit}
`;

export const UPDATE_EVENT_MUTATION = gql`
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      ...EventDetailPage
    }
  }
  ${EventDetailPage.fragments.event}
`;

export const CANCEL_EVENT_MUTATION = gql`
  mutation CancelEvent($input: CancelEventInput!) {
    cancelEvent(input: $input) {
      ...EventDetailPage
    }
  }
  ${EventDetailPage.fragments.event}
`;

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      ...EventDetailPage
    }
  }
  ${EventDetailPage.fragments.event}
`;

export const PUBLISH_EVENT_MUTATION = gql`
  mutation PublishEvent($id: ID!) {
    publishEvent(id: $id) {
      id
      status
    }
  }
`;

export const DELETE_SIGNUP_MUTATION = gql`
  mutation DeleteSignup($id: ID!) {
    deleteSignup(id: $id) {
      id
    }
  }
`;

export const ACCEPT_SIGNUPS_MUTATION = gql`
  mutation AcceptSignups($id: ID!, $token: String!) {
    acceptSignups(id: $id, token: $token) {
      ...EventDetailPage
    }
  }
  ${EventDetailPage.fragments.event}
`;

export const ADD_SIGNUPS_MUTATION = gql`
  mutation AddSignups($input: AddSignupsInput!) {
    addSignups(input: $input) {
      ...EventDetailPage
    }
  }
  ${EventDetailPage.fragments.event}
`;

export const INVITE_CANDIDATES_MUTATION = gql`
  mutation InviteCandidates($id: ID!) {
    inviteCandidates(id: $id) {
      ...EventDetailPage
    }
  }
  ${EventDetailPage.fragments.event}
`;

export const DELETE_EVENT_MUTATION = gql`
  mutation DeleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      id
    }
  }
`;

export const RESTORE_EVENTS_MUTATION = gql`
  mutation RestoreEvents($ids: [ID!]!) {
    restoreEvents(ids: $ids) {
      id
    }
  }
`;

export const FORCE_DELETE_EVENTS_MUTATION = gql`
  mutation ForceDeleteEvents($ids: [ID!]!) {
    forceDeleteEvents(ids: $ids) {
      id
    }
  }
`;

export const EMPTY_EVENT_TRASH_MUTATION = gql`
  mutation EmptyEventTrash {
    emptyEventTrash {
      id
    }
  }
`;
