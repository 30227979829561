import React from 'react';
import { gql } from '@apollo/client';
import { Button, Callout, IOverlayProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ContentCard, ContentCardHeader, ContentCardIframe } from '../../../components/ContentCard';
import ContentOverlay, { OverlaySize } from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { EmailStatus, useEmailDetailsLazyQuery } from '../../../generated/graphql';
import { withoutBubbling } from '../../../helpers/utils';
import Text from '../../../components/Text';
import { emailStatusMap } from '../../../helpers/dataUtils';

type EmailOverlayProps = {
  id: string;
  onClose: () => void;
} & IOverlayProps;

type EmailContentProps = {
  id: string;
  onClose?: () => void;
};

const isNegative = (status: EmailStatus) =>
  [
    EmailStatus.Invalid,
    EmailStatus.Unknown,
    EmailStatus.Rejected,
    EmailStatus.SoftBounced,
    EmailStatus.HardBounced,
    EmailStatus.MarkedSpam,
  ].includes(status);

const EmailContent = ({ id, onClose }: EmailContentProps) => {
  const [loadEmail, { data, called, loading, error }] = useEmailDetailsLazyQuery({
    variables: {
      id,
    },
  });

  if (!loading && !called) {
    loadEmail();
  }

  if (loading) return <LoadingCard />;
  if (error || !data?.email) return <ErrorCard />;

  return (
    <ContentCard>
      <ContentCardHeader
        leftElement={<Text large>{data.email.subject}</Text>}
        rightElement={!!onClose && <Button onClick={withoutBubbling(onClose)} icon={IconNames.CROSS} minimal />}
      />
      {isNegative(data.email.status) && !!data.email.reason && (
        <div className="py-4 px-5 relative shadow-sm">
          <Callout intent="danger" title={emailStatusMap[data.email.status].label}>
            {data.email.reason}
          </Callout>
        </div>
      )}
      <ContentCardIframe
        noFooter
        title="Vorschau"
        src={`data:text/html;base64,${btoa(unescape(encodeURIComponent(data.email.body || '')))}`}
      />
    </ContentCard>
  );
};

const EmailOverlay = ({ id, ...overlayProps }: EmailOverlayProps) => (
  <ContentOverlay size={OverlaySize.Large} {...overlayProps}>
    {overlayProps.isOpen && <EmailContent id={id} onClose={overlayProps.onClose} />}
  </ContentOverlay>
);

EmailOverlay.fragments = {
  email: gql`
    fragment EmailOverlay on Email {
      id
      status
      reason
      subject
      sent_at
      body
    }
  `,
};

export default EmailOverlay;
