import { Callout, Card, Intent } from '@blueprintjs/core';
import React from 'react';

const NotFoundPage = () => (
  <div className="flex max-h-screen items-center justify-center">
    <Card>
      <Callout intent={Intent.DANGER}>Seite nicht gefunden (404)</Callout>
    </Card>
  </div>
);

export default NotFoundPage;
