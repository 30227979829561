import React from 'react';
import { Card, Elevation } from '@blueprintjs/core';
import { gql } from '@apollo/client';
import { AttendeeLoneCompanionFragment } from '../../../generated/graphql';
import AttendeeListItem from './AttendeeListItem';

type AttendeeLoneCompanionProps = {
  item: AttendeeLoneCompanionFragment;
};

const AttendeeLoneCompanion = ({ item: companion }: AttendeeLoneCompanionProps) => (
  <Card className="overflow-hidden p-0 mb-3" elevation={Elevation.TWO}>
    <AttendeeListItem item={companion} />
  </Card>
);

AttendeeLoneCompanion.fragments = {
  loneCompanion: gql`
    fragment AttendeeLoneCompanion on Signup {
      ...AttendeeListItem
    }
    ${AttendeeListItem.fragments.attendees}
  `,
};

export default AttendeeLoneCompanion;
