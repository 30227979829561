import { useCallback, useState } from 'react';
import CategoryStats from '../components/CategoryStats';
import OverviewStats from '../components/OverviewStats';
import StatsDownload from '../components/StatsDownload';
import StatsSettings, { initialState } from '../components/StatsSettings';
import TargetGroupStats from '../components/TargetGroupStats';

const StatsDetailPage = () => {
  const [settings, setSettings] = useState({ range: initialState.range, rangeType: initialState.rangeType });
  const onChange = useCallback<(set: typeof settings) => void>((settings) => setSettings(settings), [setSettings]);

  return (
    <>
      <div className="flex">
        <StatsSettings onChange={onChange} />
        <OverviewStats range={settings.range} rangeType={settings.rangeType} />
        <StatsDownload range={settings.range} rangeType={settings.rangeType} />
      </div>
      <div className="flex items-start">
        <TargetGroupStats range={settings.range} rangeType={settings.rangeType} />
        <CategoryStats range={settings.range} rangeType={settings.rangeType} />
      </div>
    </>
  );
};

export default StatsDetailPage;
