import { NetworkStatus } from '@apollo/client';
import React from 'react';
import InfiniteScrollTable from '../../../components/InfiniteScrollTable';
import { Trashed, useLocationListQuery } from '../../../generated/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import { ListComponent } from '../../../types';
import { dummyLocations } from '../dummy';
import LocationListItem from './LocationListItem';

const LocationList = ({ trash, isSelected, onToggle }: ListComponent) => {
  const queryParams = useQueryParams();
  const filters = queryParams.get('filters');

  const { data, loading, error, fetchMore, networkStatus } = useLocationListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      // infinite flag is a hack to make the cache's "FielPolicy" read function
      // return all items
      // @ts-ignore
      infinite: true,
      trashed: trash ? Trashed.Only : Trashed.Without,
    },
  });

  const { data: locations, paginatorInfo } = data?.locations || {};
  const { currentPage, hasMorePages } = paginatorInfo || {};
  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <InfiniteScrollTable
      interactive
      columns={[
        ...(onToggle ? [{ heading: '' }] : []),
        { heading: 'Name' },
        { heading: 'Adresse' },
        { heading: 'Treffpunkt' },
      ]}
      loading={loading}
      loadingMore={loadingMore}
      hasFilters={!!filters}
      empty={!locations?.length}
      hasMorePages={!!hasMorePages}
      hasError={!!error}
      onLoadMore={() =>
        fetchMore({
          variables: {
            page: (currentPage || 1) + 1,
          },
        })
      }
    >
      {(loading && !loadingMore ? dummyLocations : locations || []).map((item) => (
        <LocationListItem
          key={item.id}
          item={item}
          skeleton={loading && !loadingMore}
          selected={isSelected && isSelected(item.id)}
          onToggle={onToggle}
        />
      ))}
    </InfiniteScrollTable>
  );
};

export default LocationList;
