/** @jsxImportSource @emotion/react */
import { FetchResult, gql } from '@apollo/client';
import { Button, Colors, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css } from '@emotion/react';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment, useState } from 'react';
import { Highlight } from 'react-instantsearch-dom';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FileUploadInput from '../../../components/FileUploadInput';
import FormGroup from '../../../components/FormGroup';
import MultiSelectSearchInput from '../../../components/MultiSelectSearchInput';
import Text from '../../../components/Text';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import {
  CreateDonorInput,
  CreateDonorMutation,
  DonorCreateFormFragment,
  DonorEditFormFragment,
  UpdateDonorInput,
  UpdateDonorMutation,
  useCreateDonorMutation,
  useSetDonorLogoMutation,
  useUpdateDonorMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';
import { LocationHit } from '../../../types';
import ToggleInput from '../../../components/ToggleInput';

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
});

type DonorFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateDonorMutation) => void;
  onUpdated?: (data: UpdateDonorMutation) => void;
  initialValues: DonorCreateFormFragment | DonorEditFormFragment;
};

const isEditData = (
  initialValues: DonorCreateFormFragment | DonorEditFormFragment,
): initialValues is DonorEditFormFragment => 'id' in initialValues;

const isUpdateResult = (data: CreateDonorMutation | UpdateDonorMutation): data is UpdateDonorMutation =>
  'updateDonor' in data;

const buildUpdateInput = (initialValues: DonorEditFormFragment, values: DonorEditFormFragment): UpdateDonorInput => {
  const { locations, logo, ...otherValues }: DonorEditFormFragment = buildDiff(initialValues, values);

  return {
    ...otherValues,
    ...(!!locations && {
      locations: {
        sync: locations.map((location) => location.id),
      },
    }),
  };
};

const buildCreateInput = (values: DonorCreateFormFragment): CreateDonorInput => {
  const { locations, logo, ...otherValues } = values;

  return {
    ...otherValues,
    locations: {
      sync: locations.map((location) => location.id),
    },
  };
};

const DonorForm = ({ onCancel, onCreated, onUpdated, initialValues }: DonorFormProps) => {
  const isEdit = isEditData(initialValues);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [setDonorLogo, { loading: settingLogo }] = useSetDonorLogoMutation();
  const [updateDonor] = useUpdateDonorMutation({ refetchQueries: ['DonorLocations'] });
  const [createDonor] = useCreateDonorMutation();
  const handleSubmit = useSubmit<
    DonorCreateFormFragment | DonorEditFormFragment,
    FetchResult<CreateDonorMutation> | FetchResult<UpdateDonorMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateDonor({
            variables: {
              input: buildUpdateInput(initialValues as DonorEditFormFragment, values),
            },
          })
        : createDonor({
            variables: {
              input: buildCreateInput(values),
            },
          }),
    onCompleted: async (res, values) => {
      if (!res.data) return;

      // execute if updating (changing or removing logo) or creating which a logo chosen
      if (isUpdateResult(res.data) ? (values.logo ? values.logo.url !== initialValues.logo?.url : true) : values.logo) {
        const donorId = isUpdateResult(res.data) ? res.data.updateDonor?.id : res.data.createDonor.id;
        await setDonorLogo({
          variables: {
            input: {
              id: donorId || '',
              image: values.logo
                ? {
                    create: {
                      file_name: values.logo.name,
                      file_path: values.logo.url,
                    },
                  }
                : {
                    delete: true,
                  },
            },
          },
        });
      }
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<Text large>Spender {isEdit ? 'bearbeiten' : 'hinzufügen'}</Text>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
              <TextInput name="name" placeholder="Name" />
            </FormGroup>
            <FormGroup label="Webseite" name="website">
              <TextInput name="website" placeholder="https://" />
            </FormGroup>
            <FormGroup label="Logo" name="logo">
              <FileUploadInput
                name="logo"
                onUpload={() => setUploadingMedia(true)}
                onSuccess={() => setUploadingMedia(false)}
                onError={() => setUploadingMedia(false)}
              />
            </FormGroup>
            <FormGroup label="Adresse" name="street">
              <TextInput name="street" type="text" placeholder="Straße" />
            </FormGroup>
            <FormGroup name="postal_code">
              <TextInput name="postal_code" type="text" placeholder="PLZ" />
            </FormGroup>
            <FormGroup name="city">
              <TextInput name="city" type="text" placeholder="Ort" />
            </FormGroup>

            <Divider className="my-6 -mx-5" />
            <FormGroup label="Interne Notizen" name="notes">
              <TextAreaInput name="notes" placeholder="Interne Notizen" fill rows={10} />
            </FormGroup>

            <Divider className="my-6 -mx-5" />
            <FormGroup label="Veranstaltungsorte" name="locations" labelInfo="(erforderlich)">
              <MultiSelectSearchInput<LocationHit>
                name="locations"
                index="locations"
                labelPath="name"
                renderItem={(hit) => <Highlight hit={hit} attribute="name" css={styles.highlight} />}
              />
            </FormGroup>

            <Divider className="my-6 -mx-5" />
            <FormGroup
              label="Sichtbarkeit"
              name="public"
              helperText="Soll dieser Spender online auf der Ausgabeseite sichtbar sein?"
            >
              <ToggleInput name="public" label="Online sichtbar" />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} className="ml-2" />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting || uploadingMedia || settingLogo}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  className="ml-2"
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

DonorForm.fragments = {
  create: gql`
    fragment DonorCreateForm on Donor {
      name
      street
      city
      postal_code
      notes
      public
      website
      logo {
        name
        url
      }
      locations {
        id
      }
    }
  `,
  edit: gql`
    fragment DonorEditForm on Donor {
      id
      name
      street
      city
      postal_code
      notes
      public
      website
      logo {
        name
        url
      }
      locations {
        id
        name
      }
    }
  `,
};

export default DonorForm;

const styles = {
  highlight: css`
    em {
      font-style: normal;
      font-weight: bold;
      color: ${Colors.BLUE1};
    }
  `,
};
