import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { CompanionCreateFormFragment, UserStatus } from '../../../generated/graphql';
import CompanionForm from './CompanionForm';

type CompanionCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
  onClose: () => void;
} & IOverlayProps;

const CompanionCreateOverlay = ({ onSuccess, ...overlayProps }: CompanionCreateOverlayProps) => {
  const initialValues: CompanionCreateFormFragment = {
    meta: {
      can_blind: false,
      can_drive: false,
      can_wheelchair: false,
      can_wheeled_walker: false,
      can_trainstation_fetch: false,
    },
    status: UserStatus.Active,
    first_name: '',
    last_name: '',
    gdpr_consent: true,
    organisation: null,
    tags: [],
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <CompanionForm
        onCancel={closeOverlay}
        onCreated={({ createCompanion }) => {
          onSuccess(createCompanion.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default CompanionCreateOverlay;
