import { Elevation } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import FutureSignupListItem from '../../../components/FutureSignupListItem';
import PaginatedTable from '../../../components/PaginatedTable';
import PaginationBar from '../../../components/PaginationBar';
import Text from '../../../components/Text';
import { useSoulFutureSignupsQuery } from '../../../generated/graphql';
import { dummyFutureSignups } from '../../matching/dummy';

type RouteParams = {
  soulId?: string;
};

const SoulFutureSignups = () => {
  const [futureSignupPage, setFutureSignupPage] = useState(1);
  const { soulId } = useParams<RouteParams>();

  const { data, loading, error } = useSoulFutureSignupsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: soulId || '',
      first: 10,
      page: futureSignupPage,
    },
  });

  const { data: futureSignups, paginatorInfo } = data?.soul?.future_signups || {};
  const { currentPage, lastPage, hasMorePages } = paginatorInfo || {};

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <ContentCardHeader leftElement={<Text large>Anmeldungen</Text>} />
      <PaginatedTable
        columns={['Veranstaltung', 'Status', 'Datum']}
        loading={loading}
        hasError={!!error}
        empty={!futureSignups?.length}
      >
        {(loading ? dummyFutureSignups : futureSignups || []).map((futureSignup) => (
          <FutureSignupListItem key={futureSignup.id} signup={futureSignup} skeleton={loading} />
        ))}
      </PaginatedTable>
      <PaginationBar
        loading={loading}
        empty={!futureSignups?.length}
        currentPage={currentPage}
        lastPage={lastPage}
        hasMorePages={hasMorePages}
        onPrev={() => setFutureSignupPage(currentPage && currentPage > 0 ? currentPage - 1 : 1)}
        onNext={() => setFutureSignupPage(currentPage ? currentPage + 1 : 1)}
      />
    </ContentCard>
  );
};

export default SoulFutureSignups;
