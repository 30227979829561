import { Icon, InputGroup, Intent, MenuItem, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemPredicate, ItemRenderer, MultiSelect2 } from '@blueprintjs/select';
import { FieldArray, getIn } from 'formik';
import { gql } from '@apollo/client';
import React from 'react';
import { PermissionMultiSelectFragment, usePermissionsQuery } from '../../../generated/graphql';

type Permission = PermissionMultiSelectFragment;

type PermissionMultiSelectProps = {
  name: string;
};

const renderTag = (permission: Permission) => permission.title;

const PermissionMultiSelect = ({ name }: PermissionMultiSelectProps) => {
  const { data, loading, error } = usePermissionsQuery();

  return (
    <FieldArray name={name}>
      {({ form, remove, push }) => {
        const selectedPermissions: Permission[] = getIn(form.values, name) || [];

        const isPermissionSelected = (permission: Permission) => getSelectedPermissionIndex(permission) !== -1;

        const getSelectedPermissionIndex = (permission: Permission) =>
          selectedPermissions.findIndex((selectedPermission) => selectedPermission.id === permission.id);

        const filterPermissions: ItemPredicate<Permission> = (query, permission) =>
          permission.title.toLowerCase().indexOf(query.toLowerCase()) >= 0;

        // NOTE: not using Films.itemRenderer here so we can set icons.
        const renderPermission: ItemRenderer<Permission> = (permission, { modifiers, handleClick }) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }
          return (
            <MenuItem
              active={modifiers.active}
              icon={isPermissionSelected(permission) ? 'tick' : 'blank'}
              key={permission.id}
              onClick={handleClick}
              text={permission.title}
              shouldDismissPopover={false}
            />
          );
        };

        const handlePermissionSelect = (permission: Permission) => {
          if (!isPermissionSelected(permission)) {
            push(permission);
          } else {
            remove(getSelectedPermissionIndex(permission));
          }
        };

        const handleTagRemove = (_tag: React.ReactNode, index: number) => {
          remove(index);
        };

        if (loading) {
          return <InputGroup rightElement={<Spinner size={16} />} value="Lade..." disabled />;
        }

        if (error || !data || !data.permissions) {
          return (
            <InputGroup leftIcon={<Icon icon={IconNames.ERROR} intent={Intent.DANGER} />} value="Fehler" disabled />
          );
        }

        return (
          <MultiSelect2<Permission>
            fill
            itemRenderer={renderPermission}
            itemPredicate={filterPermissions}
            tagRenderer={renderTag}
            resetOnSelect
            itemsEqual="id"
            items={data.permissions}
            selectedItems={selectedPermissions}
            onItemSelect={handlePermissionSelect}
            noResults={<MenuItem disabled={true} text="Keine Resultate." />}
            popoverProps={{ minimal: true, rootBoundary: 'viewport' }}
            tagInputProps={{
              onRemove: handleTagRemove,
              disabled: form.isSubmitting,
            }}
          />
        );
      }}
    </FieldArray>
  );
};

PermissionMultiSelect.fragments = {
  permissions: gql`
    fragment PermissionMultiSelect on Permission {
      id
      title
    }
  `,
};

export default PermissionMultiSelect;
