import { HTMLSelect } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import { PERSON_ORDER_OPTIONS } from '../helpers/constants';
import useQueryParams from '../hooks/useQueryParams';

type PersonListSorterProps = {
  archive?: boolean;
};

const PersonListSorter = ({ archive }: PersonListSorterProps) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const orderBy = queryParams.get('orderBy') || PERSON_ORDER_OPTIONS[0].value;

  const changeOrderBy = (newOrderBy: string) => {
    queryParams.set('orderBy', newOrderBy);
    navigate({ search: `?${queryParams.toString()}` });
  };

  if (archive) {
    return null;
  }

  return (
    <HTMLSelect
      className="ml-2"
      value={orderBy}
      onChange={(event) => changeOrderBy(event.currentTarget.value)}
      options={PERSON_ORDER_OPTIONS}
    />
  );
};

export default PersonListSorter;
