import React from 'react';
import { Card, Elevation } from '@blueprintjs/core';
import { gql } from '@apollo/client';
import { AttendeeTeamFragment } from '../../../generated/graphql';
import AttendeeListItem from './AttendeeListItem';

type AttendeeTeamProps = {
  item: AttendeeTeamFragment;
};

const AttendeeTeam = ({ item: leaderSignup }: AttendeeTeamProps) => (
  <Card className="overflow-hidden p-0 mb-3" elevation={Elevation.TWO}>
    <AttendeeListItem item={leaderSignup} />
    {leaderSignup.memberSignups.map((memberSignup) => (
      <AttendeeListItem key={memberSignup.id} item={memberSignup} />
    ))}
  </Card>
);

AttendeeTeam.fragments = {
  teams: gql`
    fragment AttendeeTeam on Signup {
      ...AttendeeListItem
      memberSignups {
        ...AttendeeListItem
      }
    }
    ${AttendeeListItem.fragments.attendees}
  `,
};

export default AttendeeTeam;
