import { gql } from '@apollo/client';
import { Classes, Elevation, Tag } from '@blueprintjs/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useDonorDetailQuery, Intent } from '../../../generated/graphql';
import DonorDetailMenu from './DonorDetailMenu';
import TooltipTag from '../../../components/TooltipTag';

type DonorInfoProps = {
  onEdit: () => void;
};

type RouteParams = {
  donorId?: string;
};

const DonorInfo = ({ onEdit }: DonorInfoProps) => {
  const { donorId } = useParams<RouteParams>();
  const { data, loading, error } = useDonorDetailQuery({
    variables: {
      id: donorId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.donor) return <NotFoundCard resource="Spender" />;

  const { donor } = data;

  const address: string | null =
    donor.street && donor.postal_code && donor.city ? `${donor.street}, ${donor.postal_code} ${donor.city}` : null;

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <ContentCardHeader
        leftElement={
          <>
            <Text large>{donor.name}</Text>
            <Tag minimal className="ml-2">
              Spender
            </Tag>
          </>
        }
        rightElement={<DonorDetailMenu donor={donor} onEdit={onEdit} />}
      />
      <div className="flex py-4 px-5">
        <PropertyList>
          <PropertyListHeader>Spender</PropertyListHeader>
          <PropertyListItem label="Logo">
            {donor.logo?.thumb_url ? (
              <img alt="logo" style={{ maxHeight: 100, maxWidth: 200 }} src={donor.logo?.thumb_url} />
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
        </PropertyList>
        <PropertyListDivider />
        <PropertyList>
          <PropertyListHeader>Spender Info</PropertyListHeader>
          <PropertyListItem label="Webseite" value={donor.website || '—'} showValueDisabled={!donor.website} />
          <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
          <PropertyListItem label="Interne Notizen">
            {donor.notes ? (
              <div className={[Classes.RUNNING_TEXT, Classes.TEXT_MUTED].join(' ')}>{donor.notes}</div>
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
          <PropertyListItem label="Sichtbarkeit">
            {donor.public ? (
              <TooltipTag
                tooltip="Dieser Spender ist online auf der Ausgabeseite sichtbar"
                label="Online sichtbar"
                minimal
                intent={Intent.Success}
              />
            ) : (
              <TooltipTag
                tooltip="Dieser Spender ist online auf der Ausgabeseite NICHT sichtbar"
                label="Online NICHT sichtbar"
                minimal
                intent={Intent.Danger}
              />
            )}
          </PropertyListItem>
        </PropertyList>
      </div>
    </ContentCard>
  );
};

DonorInfo.fragments = {
  donor: gql`
    fragment DonorInfo on Donor {
      id
      name
      street
      city
      postal_code
      notes
      website
      public
      logo {
        name
        thumb_url
      }
    }
  `,
};

export default DonorInfo;
