import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { gql } from '@apollo/client';
import React from 'react';
import DropdownInput from '../../../components/DropdownInput';
import { AllotmentSelectFragment, useAllotmentsQuery } from '../../../generated/graphql';

type Allotment = AllotmentSelectFragment;
type AllotmentSelectProps = {
  name: string;
};

const AllotmentSelect = ({ name }: AllotmentSelectProps) => {
  const { data, loading, error } = useAllotmentsQuery();

  if (loading) return <Button loading />;
  if (error || !data || !data.allotments) {
    return <Button disabled text="Fehler" rightIcon={IconNames.DOUBLE_CARET_VERTICAL} />;
  }

  return <DropdownInput<Allotment> name={name} labelPath="name" items={data.allotments} />;
};

AllotmentSelect.fragments = {
  allotments: gql`
    fragment AllotmentSelect on Allotment {
      id
      name
    }
  `,
};

export default AllotmentSelect;
