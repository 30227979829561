import { gql } from '@apollo/client';
import OrganisationForm from './components/OrganisationForm';
import OrganisationListItem from './components/OrganisationListItem';
import OrganisationDetailPage from './pages/OrganisationDetailPage';

export const ORGANISATION_LIST_QUERY = gql`
  query OrganisationList($trashed: Trashed) {
    organisations(trashed: $trashed) {
      ...OrganisationListItem
    }
  }
  ${OrganisationListItem.fragments.organisation}
`;

export const ORGANISATION_DETAIL_QUERY = gql`
  query OrganisationDetail($id: ID!) {
    organisation(id: $id) {
      id
      ...OrganisationDetailPage
    }
  }
  ${OrganisationDetailPage.fragments.organisation}
`;

export const ORGANISATION_EDIT_QUERY = gql`
  query OrganisationEdit($id: ID!) {
    organisation(id: $id) {
      id
      ...OrganisationEditForm
    }
  }
  ${OrganisationForm.fragments.edit}
`;

export const CREATE_ORGANISATION_MUTATION = gql`
  mutation CreateOrganisation($input: CreateOrganisationInput!) {
    createOrganisation(input: $input) {
      ...OrganisationDetailPage
    }
  }
  ${OrganisationDetailPage.fragments.organisation}
`;

export const UPDATE_ORGANISATION_MUTATION = gql`
  mutation UpdateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      ...OrganisationDetailPage
    }
  }
  ${OrganisationDetailPage.fragments.organisation}
`;

export const DELETE_ORGANISATION_MUTATION = gql`
  mutation DeleteOrganisation($id: ID!) {
    deleteOrganisation(id: $id) {
      id
    }
  }
`;

export const RESTORE_ORGANISATIONS_MUTATION = gql`
  mutation RestoreOrganisations($ids: [ID!]!) {
    restoreOrganisations(ids: $ids) {
      id
    }
  }
`;

export const FORCE_DELETE_ORGANISATIONS_MUTATION = gql`
  mutation ForceDeleteOrganisations($ids: [ID!]!) {
    forceDeleteOrganisations(ids: $ids) {
      id
    }
  }
`;

export const EMPTY_ORGANISATION_TRASH_MUTATION = gql`
  mutation EmptyOrganisationTrash {
    emptyOrganisationTrash {
      id
    }
  }
`;
