import React from 'react';
import { Classes, Divider, IProps } from '@blueprintjs/core';

interface PropertyListProps {
  children: React.ReactNode;
}

interface PropertyListHeaderProps {
  children: React.ReactNode;
}

interface PropertyListItemValueProps extends IProps {
  label: string;
  value: string;
  showValueDisabled?: boolean;
  runningText?: boolean;
  children?: React.ReactNode;
}

interface PropertyListItemChildrenProps extends IProps {
  label: string;
  value?: string;
  showValueDisabled?: boolean;
  runningText?: boolean;
  children: React.ReactNode;
}

export const PropertyList = ({ children }: PropertyListProps) => <div className="flex-1">{children}</div>;

export const PropertyListDivider = () => <Divider className="-my-1 mx-4" />;

export const PropertyListHeader = ({ children }: PropertyListHeaderProps) => (
  <div className="uppercase font-semibold pb-2">{children}</div>
);

export const PropertyListItem = ({
  label,
  value,
  showValueDisabled,
  runningText,
  className,
  children,
}: PropertyListItemValueProps | PropertyListItemChildrenProps) => {
  const classes = [className, 'ml-2'];

  if (showValueDisabled) {
    classes.push(Classes.TEXT_DISABLED);
  }
  if (runningText) {
    classes.push(Classes.RUNNING_TEXT);
  }

  return (
    <div className="flex py-2">
      <div style={{ flex: '0 0 30%' }} className={Classes.TEXT_MUTED}>
        {label}
      </div>
      <div className={classes.join(' ')}>{children || value}</div>
    </div>
  );
};
