import { gql } from '@apollo/client';
import { Checkbox } from '@blueprintjs/core';
import EmailLink from '../../../components/EmailLink';
import ResourceLink from '../../../components/ResourceLink';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { EmployeeListItemFragment } from '../../../generated/graphql';
import { noop } from '../../../helpers/utils';
import { useNavigateSafely } from '../../../hooks/useNavigateSafely';
import { ListItemComponent } from '../../../types';

export type EmployeeListProps = {
  item: EmployeeListItemFragment;
  skeleton?: boolean;
} & ListItemComponent;

const EmployeeListItem = ({ item, skeleton, selected, onToggle }: EmployeeListProps) => {
  const buildNavigateSafely = useNavigateSafely();
  return (
    <tr onClick={onToggle || skeleton ? noop : buildNavigateSafely(`/employees/${item!.id}`)}>
      {onToggle && (
        <TableCell>
          <Checkbox inline disabled={skeleton} checked={selected} onChange={() => onToggle(item.id)} className="m-0" />
        </TableCell>
      )}
      <TableCell>
        <Text skeleton={skeleton}>{item.display_name}</Text> <br />
        <Text skeleton={skeleton} small muted>
          {item.meta.position}
        </Text>
      </TableCell>
      <TableCell className="space-x-1">
        {item.roles.map((role) => (
          <ResourceLink key={role.id} path={`/roles/${role.id}`} text={role.name} skeleton={skeleton} />
        ))}
      </TableCell>
      <TableCell>
        <EmailLink email={item.email} skeleton={skeleton} />
      </TableCell>
    </tr>
  );
};

EmployeeListItem.fragments = {
  employee: gql`
    fragment EmployeeListItem on Employee {
      id
      display_name
      email
      phone
      mobile
      roles {
        id
        name
      }
      meta {
        position
      }
    }
  `,
};

export default EmployeeListItem;
