import React from 'react';
import { Callout, Divider, Elevation, Intent } from '@blueprintjs/core';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import NotFoundCard from '../../../components/NotFoundCard';
import Text from '../../../components/Text';
import { StatsRangeType, useOverviewStatsQuery } from '../../../generated/graphql';

type OverviewStatsType = {
  rangeType: StatsRangeType;
  range: string;
};

const OverviewStats = ({ range, rangeType }: OverviewStatsType) => {
  const { data, loading, error } = useOverviewStatsQuery({
    variables: {
      input: {
        range,
        range_type: rangeType,
      },
    },
  });

  if (error) {
    return <ErrorCard className="mb-5 mr-5 flex flex-grow flex-col" />;
  }
  if (!loading && !data?.overviewStats) {
    return <NotFoundCard resource="Übergreifend" className="mb-5 mr-5 flex flex-grow flex-col" />;
  }

  const stats = data?.overviewStats;

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5 mr-5 flex flex-grow flex-col">
      <ContentCardHeader leftElement={<Text large>Übergreifend</Text>} />
      <div className="py-4 px-5 flex-grow flex flex-col justify-center">
        <Callout className="flex justify-between">
          <div className="text-center flex-grow">
            <Text large skeleton={loading}>
              {stats?.tickets ?? '000'}
            </Text>
            <br />
            <Text muted small skeleton={loading}>
              Karten angeboten
            </Text>
          </div>
          <Divider className="mx-4" />
          <div className="text-center flex-grow">
            <Text large intent={Intent.PRIMARY} skeleton={loading}>
              {stats?.signups && stats.tickets
                ? `${stats.signups} (${Math.round((stats.signups / stats.tickets) * 100)}%)`
                : '000 (00%)'}
            </Text>
            <br />
            <Text muted small skeleton={loading}>
              Anmeldungen
            </Text>
          </div>
          <Divider className="mx-4" />
          <div className="text-center flex-grow">
            <Text large intent={Intent.SUCCESS} skeleton={loading}>
              {stats?.attends && stats.tickets
                ? `${stats.attends} (${Math.round((stats.attends / stats.tickets) * 100)}%)`
                : '000 (00%)'}
            </Text>
            <br />
            <Text muted small skeleton={loading}>
              Teilnahmen
            </Text>
          </div>
        </Callout>
      </div>
    </ContentCard>
  );
};

export default OverviewStats;
