import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

const getFirstErrorName = (errors: any, prevName?: string): string | undefined => {
  if (!errors || !Object.keys(errors).length) return undefined;
  const [key, value] = Object.entries(errors)[0];
  const nextName = prevName ? [prevName, key].join('.') : key;
  return typeof value === 'object' ? getFirstErrorName(value, nextName) : nextName;
};

export const useFocusOnError = ({ fieldRef, name }: { fieldRef: any; name: string }) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorName = getFirstErrorName(formik.errors);

  useEffect(() => {
    if (!firstErrorName) return; // Needed as formik.isValid and formik.errors not updated during same event loop
    if (
      formik.submitCount !== prevSubmitCountRef.current &&
      !formik.isValid &&
      fieldRef.current &&
      firstErrorName === name
    ) {
      setImmediate(() => fieldRef.current.focus && fieldRef.current.focus()); // Needed so allow focussing in Dialog
    }
    prevSubmitCountRef.current = formik.submitCount;
  }, [formik.submitCount, prevSubmitCountRef, formik.isValid, firstErrorName, fieldRef, name]);
};
