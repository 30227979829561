import { Checkbox, Collapse } from '@blueprintjs/core';
import React from 'react';
import { useFilterContext } from './Filter';

type FilterItemProps<T> = {
  name: string;
  label: string;
  defaultValue?: T;
  children: ({
    value,
    onChange,
  }: {
    value: T | null | undefined;
    onChange: (value: T | null | undefined) => void;
  }) => React.ReactNode;
};

const FilterItem = <T extends any>({ name, label, defaultValue, children }: FilterItemProps<T>) => {
  const { values, setFilterValue } = useFilterContext();
  const isActive = values[name] !== undefined;

  const onChange = (value: T | null | undefined) => setFilterValue(name, value);

  return (
    <>
      <div className="p-3 border-t border-light-gray-100">
        <Checkbox
          className="mb-0"
          checked={isActive}
          onChange={(event) =>
            event.currentTarget.checked ? setFilterValue(name, defaultValue || null) : setFilterValue(name, undefined)
          }
          label={label}
        />
      </div>
      <Collapse isOpen={isActive}>
        <div className="p-3 bg-light-gray-500 border-t border-light-gray-100 border-opacity-50">
          {children({ value: values[name], onChange })}
        </div>
      </Collapse>
    </>
  );
};

export default FilterItem;
