import React from 'react';
import PaginatedTable from '../../../components/PaginatedTable';
import { Trashed, useContactListQuery } from '../../../generated/graphql';
import { ListComponent } from '../../../types';
import { dummyContacts } from '../../donors/dummy';
import ContactListItem from './ContactListItem';

const ContactList = ({ trash, onToggle, isSelected }: ListComponent) => {
  const { data, loading, error } = useContactListQuery({
    fetchPolicy: 'network-only',
    variables: {
      trashed: trash ? Trashed.Only : Trashed.Without,
    },
  });

  const contacts = data?.contacts || [];

  return (
    <PaginatedTable
      columns={[...(!!onToggle ? [''] : []), 'Name', 'Email', 'Telefon']}
      interactive={!onToggle}
      loading={loading}
      hasError={!!error}
      empty={!contacts.length}
    >
      {(loading ? dummyContacts : contacts).map((item) => (
        <ContactListItem
          key={item.id}
          item={item}
          skeleton={loading}
          selected={isSelected && isSelected(item.id)}
          onToggle={onToggle}
        />
      ))}
    </PaginatedTable>
  );
};

export default ContactList;
