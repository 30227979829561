import { ApolloClient, ApolloLink, from, fromPromise, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { SentryLink } from 'apollo-link-sentry';
import cache from './cache';
import config from './helpers/config';
import { getPersisted, setPersisted, unsetPersisted } from './helpers/constants';
import { fetchNewCsrfToken, getCsrfToken, getSubdomain } from './helpers/utils';
import { IS_AUTHENTICATED } from './modules/auth/queries';
import { resolvers, typeDefs } from './resolvers';

let isRefreshing = false;
let pendingRequests: Array<(value?: unknown) => void> = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

// Strip __typename from variables
const withoutTypename = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);

    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation);
});

const withCsrfTokenHeader = setContext(async (_, { headers }) => {
  const csrfToken = await getCsrfToken();

  return {
    headers: {
      ...headers,
      'X-XSRF-TOKEN': csrfToken,
    },
  };
});

const withCultureInstanceHeader = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    'CultureKey-Instance': getSubdomain(),
  },
}));

// @ts-ignore
const sentryErrors: ApolloLink = new SentryLink({
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeError: true,
  },
});

const handleErrors = onError((error) => {
  reportToSentry(error);

  if (hasGraphQlAutenticationError(error) || isNetworkAuthenticationError(error)) {
    return logoutUser();
  }

  if (isNetworkCsrfTokenMismatchError(error)) {
    return maybeRetryWithRefreshedCsrfToken(error);
  }
});

const reportToSentry = (error: ErrorResponse) => {
  if (error.graphQLErrors?.length) {
    error.graphQLErrors.map((graphQLError) =>
      Sentry.captureException(graphQLError.originalError || Error(graphQLError.message)),
    );
  }

  if (error.networkError) {
    Sentry.captureException(error.networkError);
  }
};

const hasGraphQlAutenticationError = (error: ErrorResponse) =>
  !!error.graphQLErrors?.length && error.graphQLErrors[0].extensions?.category === 'authentication';

const isNetworkAuthenticationError = (error: ErrorResponse) =>
  !!error.networkError && 'statusCode' in error.networkError && error.networkError.statusCode === 401;

const isNetworkCsrfTokenMismatchError = (error: ErrorResponse) =>
  !!error.networkError && 'statusCode' in error.networkError && error.networkError.statusCode === 419;

const maybeRetryWithRefreshedCsrfToken = ({ forward, operation }: ErrorResponse) => {
  let forward$;

  if (!isRefreshing) {
    isRefreshing = true;
    forward$ = fromPromise(
      fetchNewCsrfToken()
        .then((token) => {
          // Store the new tokens for your auth link
          resolvePendingRequests();
          return token;
        })
        .catch((error) => {
          pendingRequests = [];
          // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
          return;
        })
        .finally(() => {
          isRefreshing = false;
        }),
    ).filter((value) => Boolean(value));
  } else {
    // Will only emit once the Promise is resolved
    forward$ = fromPromise(
      new Promise((resolve) => {
        pendingRequests.push(resolve);
      }),
    );
  }

  return forward$.flatMap(() => forward(operation));
};

const logoutUser = () => {
  setPersisted('isLoggedIn', false);
  unsetPersisted('permissions');
  cache.writeQuery({
    query: IS_AUTHENTICATED,
    data: {
      isAuthenticated: false,
    },
  });
};

const client = new ApolloClient({
  link: from([
    sentryErrors,
    handleErrors,
    withoutTypename,
    withCsrfTokenHeader,
    withCultureInstanceHeader,
    new HttpLink({
      uri: `${config.baseUrl}/graphql`,
      credentials: 'include',
    }),
  ]),
  cache,
  resolvers,
  typeDefs,
});

// Making it async to satisfy onResetStore signature
const writeInitalData = async () => {
  cache.writeQuery({
    query: IS_AUTHENTICATED,
    data: {
      isAuthenticated: !!getPersisted('isLoggedIn'),
    },
  });
};

writeInitalData();

client.onResetStore(writeInitalData);

export default client;
