/** @jsxImportSource @emotion/react */
import { Button, Colors, Elevation, Intent, Tab, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css } from '@emotion/react';
import { Formik, FormikHelpers } from 'formik';
import { Fragment } from 'react';
import { Highlight } from 'react-instantsearch-dom';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import DropdownSearchInput from '../../../components/DropdownSearchInput';
import FormGroup from '../../../components/FormGroup';
import MultiSelectSearchInput from '../../../components/MultiSelectSearchInput';
import PersonOrganisationSelect from '../../../components/PersonOrganisationSelect';
import TextAreaInput from '../../../components/TextAreaInput';
import { UserHit } from '../../../types';
import Text from '../../../components/Text';

export type AddSignupsFormValues = {
  loneSouls?: UserHit[];
  loneCompanions?: UserHit[];
  group?: {
    organisation: {
      id: number;
      name: string;
    };
    companion: UserHit;
    souls: UserHit[];
  };
};

interface IAddSignupsFormProps {
  onSubmit: (values: AddSignupsFormValues, formikHelpers: FormikHelpers<AddSignupsFormValues>) => void;
  onCancel?: (event?: any) => void;
  initialValues: AddSignupsFormValues;
}

const LoneSoulsPanel = () => (
  <FormGroup label="Genießer" labelInfo="(erforderlich)" name="loneSouls">
    <MultiSelectSearchInput<UserHit>
      name="loneSouls"
      index="souls"
      labelPath="display_name"
      filters="status:active"
      renderItem={(hit) => (
        <Fragment>
          <Highlight hit={hit} attribute="first_name" css={styles.highlight} />{' '}
          <Highlight hit={hit} attribute="last_name" css={styles.highlight} />
        </Fragment>
      )}
    />
  </FormGroup>
);

const LoneCompanionsPanel = () => (
  <FormGroup label="Begleiter" labelInfo="(erforderlich)" name="loneCompanions">
    <MultiSelectSearchInput<UserHit>
      name="loneCompanions"
      index="companions"
      labelPath="display_name"
      filters="status:active"
      renderItem={(hit) => (
        <Fragment>
          <Highlight hit={hit} attribute="first_name" css={styles.highlight} />{' '}
          <Highlight hit={hit} attribute="last_name" css={styles.highlight} />
        </Fragment>
      )}
    />
  </FormGroup>
);

const GroupPanel = ({ organisationId }: { organisationId?: number }) => (
  <Fragment>
    <FormGroup label="Organisation" labelInfo="(erforderlich)" name="group.organisation">
      <PersonOrganisationSelect name="group.organisation" resets={{ 'group.companion': null, 'group.souls': [] }} />
    </FormGroup>
    {!!organisationId && (
      <Fragment>
        <FormGroup label="Begleiter" labelInfo="(erforderlich)" name="group.companion">
          <DropdownSearchInput<UserHit>
            name="group.companion"
            index="companions"
            labelPath="display_name"
            filters={`organisation_id=${organisationId} AND status:active`}
            renderItem={(hit) => (
              <Fragment>
                <Highlight hit={hit} attribute="first_name" css={styles.highlight} />{' '}
                <Highlight hit={hit} attribute="last_name" css={styles.highlight} />
              </Fragment>
            )}
          />
        </FormGroup>
        <FormGroup label="Genießer" labelInfo="(erforderlich)" name="group.souls">
          <MultiSelectSearchInput<UserHit>
            name="group.souls"
            index="souls"
            labelPath="display_name"
            filters={`organisation_id=${organisationId} AND status:active`}
            renderItem={(hit) => (
              <Fragment>
                <Highlight hit={hit} attribute="first_name" css={styles.highlight} />{' '}
                <Highlight hit={hit} attribute="last_name" css={styles.highlight} />
              </Fragment>
            )}
          />
        </FormGroup>
      </Fragment>
    )}
  </Fragment>
);

const AddSignupsForm = ({ onCancel, onSubmit, initialValues }: IAddSignupsFormProps) => (
  <Formik initialValues={initialValues} onSubmit={onSubmit}>
    {({ isSubmitting, submitForm, values }) => (
      <ContentCard elevation={Elevation.FOUR}>
        <ContentCardHeader
          leftElement={<Text large>Interessenten hinzufügen</Text>}
          rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
        />

        <ContentCardScroll>
          <Tabs id="signupType">
            <Tab id="loneSouls" title="Einzelne Genießer" panel={<LoneSoulsPanel />} />
            <Tab id="loneCompanions" title="Einzelne Begleiter" panel={<LoneCompanionsPanel />} />
            <Tab
              id="group"
              title="Gruppe"
              panel={<GroupPanel organisationId={values.group?.organisation && values.group.organisation.id} />}
            />
          </Tabs>
          <FormGroup label="Anmerkungen" name="notes">
            <TextAreaInput name="notes" placeholder="Für Nutzer sichtbar" fill rows={3} />
          </FormGroup>
        </ContentCardScroll>

        <ContentCardFooter
          rightElement={
            <Fragment>
              <Button text="Abbrechen" onClick={onCancel} className="ml-2" />
              <Button
                text="Hinzufügen"
                loading={isSubmitting}
                intent={Intent.PRIMARY}
                onClick={submitForm}
                className="ml-2"
              />
            </Fragment>
          }
        />
      </ContentCard>
    )}
  </Formik>
);

export default AddSignupsForm;

const styles = {
  highlight: css`
    em {
      font-style: normal;
      font-weight: bold;
      color: ${Colors.BLUE1};
    }
  `,
};
