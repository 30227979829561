import { FetchResult, gql } from '@apollo/client';
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import PersonOrganisationSelect from '../../../components/PersonOrganisationSelect';
import PersonTagMultiSelect from '../../../components/PersonTagMultiSelect';
import Text from '../../../components/Text';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import ToggleInput from '../../../components/ToggleInput';
import {
  CompanionCreateFormFragment,
  CompanionEditFormFragment,
  CreateCompanionInput,
  CreateCompanionMutation,
  GdprConsentSource,
  UpdateCompanionInput,
  UpdateCompanionMutation,
  useCreateCompanionMutation,
  useUpdateCompanionMutation,
} from '../../../generated/graphql';
import { convertGermanToIntl } from '../../../helpers/dateTimeUtils';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';

const validationSchema = Yup.object({
  first_name: Yup.string().required('Erforderlich'),
  last_name: Yup.string().required('Erforderlich'),
  birthdate: Yup.string()
    .nullable()
    .matches(/^(3[01]|[12][0-9]|0[1-9])\.(1[012]|0[1-9])\.((?:19|20)\d{2})$/, 'Kein gültiges Datum'),
  email: Yup.string().email('Keine gültige Email').nullable(),
});

type CompanionFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateCompanionMutation) => void;
  onUpdated?: (data: UpdateCompanionMutation) => void;
  initialValues: CompanionCreateFormFragment | CompanionEditFormFragment;
};

const isEditData = (
  initialValues: CompanionCreateFormFragment | CompanionEditFormFragment,
): initialValues is CompanionEditFormFragment => 'id' in initialValues;

const isUpdateResult = (data: CreateCompanionMutation | UpdateCompanionMutation): data is UpdateCompanionMutation =>
  'updateCompanion' in data;

const buildUpdateInput = (
  initialValues: CompanionEditFormFragment,
  values: CompanionEditFormFragment,
): UpdateCompanionInput => {
  const { tags, organisation, ...otherValues }: CompanionEditFormFragment = buildDiff(initialValues, values);

  return {
    ...otherValues,
    ...(otherValues.birthdate && { birthdate: convertGermanToIntl(otherValues.birthdate) }),
    ...(typeof otherValues.gdpr_consent !== 'undefined' && { gdpr_consent_source: GdprConsentSource.Employee }),
    ...(!!tags && {
      tags: {
        sync: tags.map((tag) => tag.id),
      },
    }),
    ...(typeof organisation !== 'undefined' && {
      organisation: {
        connect: organisation?.id,
        disconnect: !organisation,
      },
    }),
  };
};

const buildCreateInput = (values: CompanionCreateFormFragment): CreateCompanionInput => ({
  ...values,
  ...(values.birthdate && { birthdate: convertGermanToIntl(values.birthdate) }),
  gdpr_consent_source: GdprConsentSource.Employee,
  tags: {
    sync: values.tags.map((tag) => tag.id),
  },
  organisation: {
    connect: values.organisation?.id,
  },
});

const CompanionForm = ({ onCancel, onCreated, onUpdated, initialValues }: CompanionFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateCompanion] = useUpdateCompanionMutation();
  const [createCompanion] = useCreateCompanionMutation();
  const handleSubmit = useSubmit<
    CompanionCreateFormFragment | CompanionEditFormFragment,
    FetchResult<CreateCompanionMutation> | FetchResult<UpdateCompanionMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateCompanion({
            variables: {
              input: buildUpdateInput(initialValues as CompanionEditFormFragment, values),
            },
          })
        : createCompanion({
            variables: {
              input: buildCreateInput(values),
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<Text large>Begleiter {isEdit ? 'bearbeiten' : 'hinzufügen'}</Text>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Vorname" labelInfo="(erforderlich)" name="first_name">
              <TextInput name="first_name" placeholder="Vorname" />
            </FormGroup>
            <FormGroup label="Nachname" labelInfo="(erforderlich)" name="last_name">
              <TextInput name="last_name" placeholder="Nachname" />
            </FormGroup>
            <FormGroup label="Email" labelInfo="(erforderlich)" name="email">
              <TextInput name="email" type="email" placeholder="Email" />
            </FormGroup>
            <FormGroup label="Geburtsdatum" name="birthdate">
              <TextInput name="birthdate" placeholder="TT.MM.JJJJ" />
            </FormGroup>
            <FormGroup
              label="Datenschutz"
              name="gdpr_consent"
              helperText="Begleiter erhält keine Newsletter, wenn keine ausdrückliche Einwilligung vorliegt"
            >
              <ToggleInput name="gdpr_consent" label="Newsletter-Einwilligung erteilt" />
            </FormGroup>
            <FormGroup label="Telefon" name="phone">
              <TextInput name="phone" type="text" placeholder="Telefon" />
            </FormGroup>
            <FormGroup label="Mobil" name="mobile">
              <TextInput name="mobile" type="text" placeholder="Mobil" />
            </FormGroup>
            <FormGroup label="Adresse" name="street">
              <TextInput name="street" type="text" placeholder="Straße" />
            </FormGroup>
            <FormGroup name="postal_code">
              <TextInput name="postal_code" type="text" placeholder="PLZ" />
            </FormGroup>
            <FormGroup name="city">
              <TextInput name="city" type="text" placeholder="Ort" />
            </FormGroup>

            <Divider className="my-6 -mx-5" />
            <FormGroup label="Besonderheiten" name="can_wheelchair">
              <ToggleInput name="meta.can_wheelchair" label="Kann Rollstuhl schieben" />
              <ToggleInput name="meta.can_blind" label="Kann Blinde begleiten" />
              <ToggleInput name="meta.can_wheeled_walker" label="Kann Genießer mit Rollator begleiten" />
              <ToggleInput name="meta.can_drive" label="Kann Genießer von Zuhause abholen" />
              <ToggleInput name="meta.can_trainstation_fetch" label="Kann Genießer vom Bahnhof abholen" />
            </FormGroup>
            <FormGroup label="Attribute" name="tags">
              <PersonTagMultiSelect name="tags" />
            </FormGroup>
            <FormGroup label="Organisation" name="organisation">
              <PersonOrganisationSelect name="organisation" />
            </FormGroup>
            <FormGroup label="Interne Notizen" name="notes">
              <TextAreaInput name="notes" placeholder="Interne Notizen" fill rows={10} />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <>
                <Button text="Abbrechen" onClick={onCancel} className="ml-2" />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  className="ml-2"
                />
              </>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

CompanionForm.fragments = {
  // This is only used for Formik initialValues
  create: gql`
    fragment CompanionCreateForm on Companion {
      notes
      first_name
      last_name
      status
      birthdate
      email
      gdpr_consent
      phone
      mobile
      street
      city
      postal_code
      meta {
        can_wheelchair
        can_wheeled_walker
        can_drive
        can_blind
        can_trainstation_fetch
      }
      organisation {
        id
        name
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  `,
  edit: gql`
    fragment CompanionEditForm on Companion {
      id
      first_name
      last_name
      birthdate
      email
      gdpr_consent
      phone
      mobile
      street
      city
      postal_code
      notes
      meta {
        can_wheelchair
        can_wheeled_walker
        can_drive
        can_blind
        can_trainstation_fetch
      }
      organisation {
        id
        name
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  `,
};

export default CompanionForm;
