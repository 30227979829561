import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { EventCreateFormFragment, EventTicketType, EventStatus } from '../../../generated/graphql';
import EventForm from './EventForm';
import { Nullable } from '../../../types';

type EventCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
  onClose: () => void;
} & IOverlayProps;

const EventCreateOverlay = ({ onSuccess, ...overlayProps }: EventCreateOverlayProps) => {
  const initialValues: Nullable<EventCreateFormFragment> = {
    name: '',
    status: EventStatus.Ready,
    start: '',
    ticket_type: EventTicketType.Fixed,
    ticket_time: 30,
    ticket_retrieval_location: 'An der Kasse',
    categories: [],
    donor: null,
    contact: null,
    location: null,
    allotment: null,
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <EventForm
        onCancel={closeOverlay}
        onCreated={({ createEvent }) => {
          onSuccess(createEvent.id);
        }}
        initialValues={initialValues as EventCreateFormFragment}
      />
    </ContentOverlay>
  );
};

export default EventCreateOverlay;
