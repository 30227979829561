import React from 'react';
import PaginatedTable from '../../../components/PaginatedTable';
import { Trashed, useOrganisationListQuery } from '../../../generated/graphql';
import { ListComponent } from '../../../types';
import { dummyOrganisations } from '../dummy';
import OrganisationListItem from './OrganisationListItem';

const OrganisationList = ({ isSelected, onToggle, trash }: ListComponent) => {
  const { data, loading, error } = useOrganisationListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    fetchPolicy: 'network-only',
    variables: {
      trashed: trash ? Trashed.Only : Trashed.Without,
    },
  });

  const organisations = data?.organisations || [];

  return (
    <PaginatedTable
      columns={[...(!!onToggle ? [''] : []), 'Name']}
      interactive={!onToggle}
      loading={loading}
      hasError={!!error}
      empty={!organisations.length}
    >
      {(loading ? dummyOrganisations : organisations).map((item) => (
        <OrganisationListItem
          key={item.id}
          item={item}
          skeleton={loading}
          selected={isSelected && isSelected(item.id)}
          onToggle={onToggle}
        />
      ))}
    </PaginatedTable>
  );
};

export default OrganisationList;
