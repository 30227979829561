import { NonIdealState } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import React from 'react';

export interface ErrorProps {
  icon?: IconName;
  title?: string;
  description?: string;
  action?: JSX.Element;
}

const Error = (props: ErrorProps) => (
  <div className="text-center py-10">
    <NonIdealState {...props} />
  </div>
);

Error.defaultProps = {
  title: 'Fehler',
  icon: IconNames.WARNING_SIGN,
  description: 'Ein unbekannter Fehler ist aufgetreten.',
} as Partial<ErrorProps>;

export default Error;
