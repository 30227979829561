import { LocationListItemFragment } from '../../generated/graphql';

export const dummyLocations: LocationListItemFragment[] = [
  {
    id: '1',
    name: 'Normal Location Name',
    street: 'Humbodtstraße 14',
    postal_code: '392745',
    city: 'Hamburg',
    meeting_point: 'An der Kasse',
    public_transport: 'Kein',
    accessible: false,
  },
  {
    id: '2',
    name: 'Neues deutsches Schauspielhaus',
    street: 'Humbodtstraße 14',
    postal_code: '392745',
    city: 'Hamburg',
    meeting_point: 'Im Eingangsbereich des Haupthauses',
    public_transport: 'Kein',
    accessible: true,
  },
  {
    id: '3',
    name: 'Normal Location Name',
    street: 'Humbodtstraße 14',
    postal_code: '392745',
    city: 'Hamburg',
    meeting_point: 'An der Kasse',
    public_transport: 'Kein',
    accessible: true,
  },
  {
    id: '4',
    name: 'Thalia Theater',
    street: 'Humbodtstraße 14',
    postal_code: '392745',
    city: 'Hamburg',
    meeting_point: 'Unten an der Treppe',
    public_transport: 'Kein',
    accessible: false,
  },
];
