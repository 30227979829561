import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import CompanionAttends from '../components/CompanionAttends';
import CompanionEditOverlay from '../components/CompanionEditOverlay';
import CompanionEmails from '../components/CompanionEmails';
import CompanionFutureSignups from '../components/CompanionFutureSignups';
import CompanionInfo from '../components/CompanionInfo';

type RouteParams = {
  companionId?: string;
};

const CompanionDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { companionId } = useParams<RouteParams>();

  return (
    <>
      <CompanionEditOverlay
        id={companionId || ''}
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
      />
      <CompanionInfo onEdit={() => setIsEditDialogOpen(true)} />
      <CompanionFutureSignups />
      <CompanionAttends />
      <CompanionEmails />
    </>
  );
};

export default CompanionDetailPage;
