import {
  AttendListItemFragment,
  EmailStatus,
  EventStatus,
  FutureSignupListItemFragment,
} from '../../generated/graphql';

export const dummyAttends: AttendListItemFragment[] = [
  {
    id: '1',
    event: {
      id: '1',
      name: 'Normal Event Name',
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Konzert',
          color: '#ff0000',
        },
      ],
    },
    leaderSignup: {
      id: '1',
      companion: {
        id: '1',
        display_name: 'Hans Wurst',
      },
    },
    memberSignups: [
      {
        id: '1',
        soul: {
          id: '1',
          display_name: 'Hello You',
        },
      },
    ],
  },
  {
    id: '2',
    event: {
      id: '1',
      name: 'A bit longer name indeed',
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Museum / Ausstellung',
          color: '#ff0000',
        },
      ],
    },
    leaderSignup: {
      id: '1',
      companion: {
        id: '1',
        display_name: 'Hans Wurst',
      },
    },
    memberSignups: [
      {
        id: '1',
        soul: {
          id: '1',
          display_name: 'Hello You',
        },
      },
    ],
  },
  {
    id: '3',
    event: {
      id: '1',
      name: 'Very short name',
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Oper',
          color: '#ff0000',
        },
      ],
    },
    leaderSignup: {
      id: '1',
      companion: {
        id: '1',
        display_name: 'Hans Wurst',
      },
    },
    memberSignups: [
      {
        id: '1',
        soul: {
          id: '1',
          display_name: 'Hello You',
        },
      },
    ],
  },
  {
    id: '4',
    event: {
      id: '1',
      name: 'Very long and strange EventName',
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Oper / Musical',
          color: '#ff0000',
        },
      ],
    },
    leaderSignup: {
      id: '1',
      companion: {
        id: '1',
        display_name: 'Hans Wurst',
      },
    },
    memberSignups: [
      {
        id: '1',
        soul: {
          id: '1',
          display_name: 'Hello You',
        },
      },
    ],
  },
  {
    id: '5',
    event: {
      id: '1',
      name: 'A bit longer name indeed',
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Museum / Ausstellung',
          color: '#ff0000',
        },
      ],
    },
    leaderSignup: {
      id: '1',
      companion: {
        id: '1',
        display_name: 'Hans Wurst',
      },
    },
    memberSignups: [
      {
        id: '1',
        soul: {
          id: '1',
          display_name: 'Hello You',
        },
      },
    ],
  },
  {
    id: '6',
    event: {
      id: '1',
      name: 'Very short name',
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Oper',
          color: '#ff0000',
        },
      ],
    },
    leaderSignup: {
      id: '1',
      companion: {
        id: '1',
        display_name: 'Hans Wurst',
      },
    },
    memberSignups: [
      {
        id: '1',
        soul: {
          id: '1',
          display_name: 'Hello You',
        },
      },
    ],
  },
  {
    id: '7',
    event: {
      id: '1',
      name: 'Normal Event Name',
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Konzert',
          color: '#ff0000',
        },
      ],
    },
    leaderSignup: {
      id: '1',
      companion: {
        id: '1',
        display_name: 'Hans Wurst',
      },
    },
    memberSignups: [
      {
        id: '1',
        soul: {
          id: '1',
          display_name: 'Hello You',
        },
      },
    ],
  },
  {
    id: '8',
    event: {
      id: '1',
      name: 'Very short name',
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Oper',
          color: '#ff0000',
        },
      ],
    },
    leaderSignup: {
      id: '1',
      companion: {
        id: '1',
        display_name: 'Hans Wurst',
      },
    },
    memberSignups: [
      {
        id: '1',
        soul: {
          id: '1',
          display_name: 'Hello You',
        },
      },
    ],
  },
  {
    id: '9',
    event: {
      id: '1',
      name: 'Very long and strange EventName',
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Oper / Musical',
          color: '#ff0000',
        },
      ],
    },
    leaderSignup: {
      id: '1',
      companion: {
        id: '1',
        display_name: 'Hans Wurst',
      },
    },
    memberSignups: [
      {
        id: '1',
        soul: {
          id: '1',
          display_name: 'Hello You',
        },
      },
    ],
  },
  {
    id: '10',
    event: {
      id: '1',
      name: 'Normal Event Name',
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Konzert',
          color: '#ff0000',
        },
      ],
    },
    leaderSignup: {
      id: '1',
      companion: {
        id: '1',
        display_name: 'Hans Wurst',
      },
    },
    memberSignups: [
      {
        id: '1',
        soul: {
          id: '1',
          display_name: 'Hello You',
        },
      },
    ],
  },
];

export const dummyFutureSignups: FutureSignupListItemFragment[] = [
  {
    id: '1',
    is_candidate: true,
    is_invited: true,
    has_accepted: false,
    has_rejected: false,
    event: {
      id: '1',
      name: 'Normal Event Name',
      status: EventStatus.Closed,
      candidates_chosen: true,
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Konzert',
          color: '#ff0000',
        },
      ],
    },
    emails: [
      {
        id: '1',
        status: EmailStatus.Opened,
      },
    ],
  },
  {
    id: '2',
    is_candidate: true,
    is_invited: true,
    has_accepted: false,
    has_rejected: false,
    event: {
      id: '1',
      name: 'A bit longer name indeed',
      status: EventStatus.Closed,
      candidates_chosen: true,
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Museum / Ausstellung',
          color: '#ff0000',
        },
      ],
    },
    emails: [
      {
        id: '1',
        status: EmailStatus.Opened,
      },
    ],
  },
  {
    id: '3',
    is_candidate: true,
    is_invited: true,
    has_accepted: false,
    has_rejected: false,
    event: {
      id: '1',
      name: 'Very short name',
      status: EventStatus.Closed,
      candidates_chosen: true,
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Oper',
          color: '#ff0000',
        },
      ],
    },
    emails: [
      {
        id: '1',
        status: EmailStatus.Opened,
      },
    ],
  },
  {
    id: '4',
    is_candidate: true,
    is_invited: true,
    has_accepted: false,
    has_rejected: false,
    event: {
      id: '1',
      name: 'Very long and strange EventName',
      status: EventStatus.Closed,
      candidates_chosen: true,
      start: '2019-04-01 12:00:00',
      categories: [
        {
          id: '1',
          name: 'Oper / Musical',
          color: '#ff0000',
        },
      ],
    },
    emails: [
      {
        id: '1',
        status: EmailStatus.Opened,
      },
    ],
  },
];
