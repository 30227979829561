import { Checkbox } from '@blueprintjs/core';
import { gql } from '@apollo/client';
import React from 'react';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { NewsletterEventListItemFragment } from '../../../generated/graphql';
import { formatDay, formatTime } from '../../../helpers/dateTimeUtils';

export type NewsletterEventListItemProps = {
  item: NewsletterEventListItemFragment;
  selected: boolean;
  onToggle: (eventId: string) => void;
  skeleton?: boolean;
};

const NewsletterEventListItem = ({ item, selected, onToggle, skeleton }: NewsletterEventListItemProps) => (
  <tr>
    <TableCell>
      <Checkbox disabled={skeleton} checked={selected} onChange={() => onToggle(item.id)} />
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{item.name}</Text> <br />
      <Text skeleton={skeleton} small muted>
        {item.location.name}
      </Text>
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{formatDay(item.start)}</Text> <br />
      <Text skeleton={skeleton} muted>
        {formatTime(item.start)}
        {!!item.end && ` - ${formatTime(item.end)}`}
        {' Uhr'}
      </Text>
    </TableCell>
  </tr>
);

NewsletterEventListItem.fragments = {
  event: gql`
    fragment NewsletterEventListItem on Event {
      id
      name
      start
      end
      location {
        id
        name
      }
    }
  `,
};

export default NewsletterEventListItem;
