import { NetworkStatus } from '@apollo/client';
import React from 'react';
import InfiniteScrollTable from '../../../components/InfiniteScrollTable';
import { Trashed, useEmployeeListQuery, UserStatus } from '../../../generated/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import { ListComponent } from '../../../types';
import { dummyEmployees } from '../dummy';
import EmployeeListItem from './EmployeeListItem';

const EmployeeList = ({ trash, isSelected, onToggle }: ListComponent) => {
  const queryParams = useQueryParams();
  const status = (queryParams.get('status') as UserStatus) || UserStatus.Active;
  const filters = queryParams.get('filters');

  // infinite flag is a hack to make the cache's "FielPolicy" read function
  // return all items
  // @ts-ignore
  const variables = trash
    ? {
        trashed: Trashed.Only,
        infinite: true,
      }
    : {
        status,
        trashed: Trashed.Without,
        infinite: true,
      };

  const { data, loading, error, fetchMore, networkStatus } = useEmployeeListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables,
  });

  const { data: employees, paginatorInfo } = data?.employees || {};
  const { currentPage, hasMorePages } = paginatorInfo || {};
  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <InfiniteScrollTable
      interactive={!onToggle}
      columns={[
        ...(onToggle ? [{ heading: '' }] : []),
        { heading: 'Name' },
        { heading: 'Rolle(n)' },
        { heading: 'Email' },
      ]}
      loading={loading}
      loadingMore={loadingMore}
      hasFilters={!!filters}
      empty={!employees?.length}
      hasMorePages={!!hasMorePages}
      hasError={!!error}
      onLoadMore={() =>
        fetchMore({
          variables: {
            page: (currentPage || 1) + 1,
          },
        })
      }
    >
      {(loading && !loadingMore ? dummyEmployees : employees || []).map((item) => (
        <EmployeeListItem
          key={item.id}
          item={item}
          skeleton={loading && !loadingMore}
          selected={isSelected && isSelected(item.id)}
          onToggle={onToggle}
        />
      ))}
    </InfiniteScrollTable>
  );
};

export default EmployeeList;
