import React from 'react';
import { Elevation } from '@blueprintjs/core';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EventList from '../components/EventList';
import EventListFilter from '../components/EventListFilter';
import Text from '../../../components/Text';
import EventListSorter from '../components/EventListSorter';

const EventArchiveListPage = () => (
  <ContentCard elevation={Elevation.TWO} className="mb-5">
    <ContentCardHeader
      leftElement={
        <>
          <Text large className="mr-2">
            Veranstaltungsarchiv
          </Text>
          <EventListFilter archive />
          <EventListSorter />
        </>
      }
    />
    <EventList archive />
  </ContentCard>
);

export default EventArchiveListPage;
