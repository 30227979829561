import React from 'react';
import { Elevation, Button, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import { StatsRangeType } from '../../../generated/graphql';
import config from '../../../helpers/config';
import { openTab, getSubdomain } from '../../../helpers/utils';
import Text from '../../../components/Text';

type StatsDownloadType = {
  rangeType: StatsRangeType;
  range: string;
};

const StatsDownload = ({ range, rangeType }: StatsDownloadType) => (
  <ContentCard elevation={Elevation.TWO} className="mb-5 flex flex-col">
    <ContentCardHeader leftElement={<Text large>Daten-Export</Text>} />
    <div className="py-4 px-5 flex-grow flex flex-col justify-center items-center">
      <Button
        intent={Intent.PRIMARY}
        text="Excel-Export herunterladen"
        icon={IconNames.DOWNLOAD}
        onClick={() =>
          openTab(`${config.baseUrl}/${getSubdomain()}/downloads/stats?range=${range}&rangeType=${rangeType}`)
        }
      />
    </div>
  </ContentCard>
);

export default StatsDownload;
