import { TagListItemFragment, Intent } from '../../generated/graphql';
import { IconNames } from '@blueprintjs/icons';

export const dummyTags: TagListItemFragment[] = [
  {
    id: '1',
    name: 'Normal Tag Name',
    icon: IconNames.ADD,
    intent: Intent.Danger,
    description: 'Dies ist die Beschreibung eines Tags',
  },
  {
    id: '2',
    name: 'Smaller',
    icon: IconNames.ADD,
    intent: Intent.Danger,
  },
  {
    id: '3',
    name: 'Little Bigger',
    icon: IconNames.ADD,
    intent: Intent.Danger,
  },
  {
    id: '4',
    name: 'Small',
    icon: IconNames.ADD,
    intent: Intent.Danger,
    description: 'Dies ist die Beschreibung eines Tags',
  },
];
