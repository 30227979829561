import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import EmailLink from '../../../components/EmailLink';
import Text from '../../../components/Text';
import { EventDetailPageFragment } from '../../../generated/graphql';

type EventContactInfoProps = {
  event: EventDetailPageFragment;
};

const EventContactInfo = ({ event }: EventContactInfoProps) => {
  if (!event.contact) {
    return <Text disabled>—</Text>;
  }

  return (
    <>
      <Link to={`/contacts/${event.contact.id}`}>{event.contact.display_name}</Link>
      <br />
      <Text muted>
        <Icon icon={IconNames.PHONE} iconSize={12} />
        {` ${event.contact.phone || '—'}`}
      </Text>
      {' | '}
      <Text muted>
        <Icon icon={IconNames.MOBILE_PHONE} iconSize={12} />
        {` ${event.contact.mobile || '—'}`}
      </Text>{' '}
      <br />
      <EmailLink email={event.contact.email} />
    </>
  );
};

export default EventContactInfo;
