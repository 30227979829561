/** @jsxImportSource @emotion/react */
import { FetchResult, gql } from '@apollo/client';
import { Button, Colors, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css } from '@emotion/react';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import { Fragment } from 'react';
import { Highlight } from 'react-instantsearch-dom';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import MultiSelectSearchInput from '../../../components/MultiSelectSearchInput';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import {
  CreateOrganisationInput,
  CreateOrganisationMutation,
  OrganisationCreateFormFragment,
  OrganisationEditFormFragment,
  UpdateOrganisationInput,
  UpdateOrganisationMutation,
  useCreateOrganisationMutation,
  useUpdateOrganisationMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';
import { UserHit } from '../../../types';
import Text from '../../../components/Text';

export type OrganisationFormValues = {
  id?: string;
  name: string;
  description?: string | null;
  souls: { id: string; display_name: string }[];
  companions: { id: string; display_name: string }[];
};

const validationSchema = Yup.object({
  name: Yup.string().required('Erforderlich'),
});

type OrganisationFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateOrganisationMutation) => void;
  onUpdated?: (data: UpdateOrganisationMutation) => void;
  initialValues: OrganisationCreateFormFragment | OrganisationEditFormFragment;
};

const isEditData = (
  initialValues: OrganisationCreateFormFragment | OrganisationEditFormFragment,
): initialValues is OrganisationEditFormFragment => 'id' in initialValues;

const isUpdateResult = (
  data: CreateOrganisationMutation | UpdateOrganisationMutation,
): data is UpdateOrganisationMutation => 'updateOrganisation' in data;

const buildUpdateInput = (
  initialValues: OrganisationEditFormFragment,
  values: OrganisationEditFormFragment,
): UpdateOrganisationInput => {
  const { souls, companions, ...otherValues }: OrganisationEditFormFragment = buildDiff(initialValues, values);

  return {
    ...otherValues,
    ...(!!souls && {
      souls: {
        sync: values.souls.map((soul) => soul.id.toString()),
      },
    }),
    ...(!!companions && {
      companions: {
        sync: values.companions.map((companion) => companion.id.toString()),
      },
    }),
  };
};

const buildCreateInput = (values: OrganisationCreateFormFragment): CreateOrganisationInput => ({
  ...values,
  souls: {
    sync: values.souls.map((soul) => soul.id.toString()),
  },
  companions: {
    sync: values.companions.map((companion) => companion.id.toString()),
  },
});

const OrganisationForm = ({ onCancel, onUpdated, onCreated, initialValues }: OrganisationFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateOrganisation] = useUpdateOrganisationMutation();
  const [createOrganisation] = useCreateOrganisationMutation();
  const handleSubmit = useSubmit<
    OrganisationCreateFormFragment | OrganisationEditFormFragment,
    FetchResult<CreateOrganisationMutation> | FetchResult<UpdateOrganisationMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateOrganisation({
            variables: {
              input: buildUpdateInput(initialValues as OrganisationEditFormFragment, values),
            },
          })
        : createOrganisation({
            variables: {
              input: buildCreateInput(values),
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, values, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<Text large>Organisation {isEdit ? 'bearbeiten' : 'hinzufügen'}</Text>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Name" labelInfo="(erforderlich)" name="name">
              <TextInput name="name" placeholder="Name" />
            </FormGroup>
            <FormGroup name="description">
              <TextAreaInput name="description" placeholder="Beschreibung" fill rows={10} />
            </FormGroup>
            <FormGroup label="Interne Notizen" name="notes">
              <TextAreaInput name="notes" placeholder="Interne Notizen" fill rows={10} />
            </FormGroup>

            <Divider className="my-6 -mx-5" />

            <FormGroup label="Begleiter" labelInfo="(erforderlich)" name="companions">
              <MultiSelectSearchInput<UserHit>
                name="companions"
                index="companions"
                labelPath="display_name"
                filters={
                  isEditData(values) ? `organisation_id=${values.id} OR organisation_id=-1` : 'organisation_id=-1'
                }
                renderItem={(hit) => (
                  <Fragment>
                    <Highlight hit={hit} attribute="first_name" css={styles.highlight} />{' '}
                    <Highlight hit={hit} attribute="last_name" css={styles.highlight} />
                  </Fragment>
                )}
              />
            </FormGroup>
            <FormGroup label="Genießer" labelInfo="(erforderlich)" name="souls">
              <MultiSelectSearchInput<UserHit>
                name="souls"
                index="souls"
                labelPath="display_name"
                filters={
                  isEditData(values) ? `organisation_id=${values.id} OR organisation_id=-1` : 'organisation_id=-1'
                }
                renderItem={(hit) => (
                  <Fragment>
                    <Highlight hit={hit} attribute="first_name" css={styles.highlight} />{' '}
                    <Highlight hit={hit} attribute="last_name" css={styles.highlight} />
                  </Fragment>
                )}
              />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <Fragment>
                <Button text="Abbrechen" onClick={onCancel} className="ml-2" />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  className="ml-2"
                />
              </Fragment>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

OrganisationForm.fragments = {
  create: gql`
    fragment OrganisationCreateForm on Organisation {
      name
      description
      notes
      souls {
        id
      }
      companions {
        id
      }
    }
  `,
  edit: gql`
    fragment OrganisationEditForm on Organisation {
      id
      name
      description
      notes
      souls {
        id
        display_name
      }
      companions {
        id
        display_name
      }
    }
  `,
};

export default OrganisationForm;

const styles = {
  highlight: css`
    em {
      font-style: normal;
      font-weight: bold;
      color: ${Colors.BLUE1};
    }
  `,
};
