import { AllotmentListItemFragment, AllotmentStatus } from '../../generated/graphql';

export const dummyAllotments: AllotmentListItemFragment[] = [
  {
    id: '1',
    name: 'Normal Allotment Name',
    status: AllotmentStatus.Active,
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 12:00:00',
    amount: 40,
    amount_left: 20,
  },
  {
    id: '2',
    name: 'Small Allotment',
    status: AllotmentStatus.Active,
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 12:00:00',
    amount: 40,
    amount_left: 20,
  },
  {
    id: '3',
    name: 'Allotment with a longer Name',
    status: AllotmentStatus.Active,
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 12:00:00',
    amount: 40,
    amount_left: 20,
  },
  {
    id: '4',
    name: 'Cool Allotment',
    status: AllotmentStatus.Active,
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 12:00:00',
    amount: 40,
    amount_left: 20,
  },
];
