import { gql } from '@apollo/client';
import { Intent } from '@blueprintjs/core';
import React, { useState } from 'react';
import PersonIconList from '../../../components/PersonIconList';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import TooltipTag from '../../../components/TooltipTag';
import { useProspectSignupQuery } from '../../../generated/graphql';
import { getAgeInYears } from '../../../helpers/dateTimeUtils';
import ConflictingSignups from './ConflictingSignups';
import InfoDrawer from './InfoDrawer';

export type ProspectListItemProps = {
  id: string;
  addLoneSoul: (soulSignupId: string, buysTicket: boolean) => void;
  addLoneCompanion: (companionSignupId: string) => void;
  addTeam: (companionSignupId: string, soulSignupId: string, soulBuysTicket: boolean) => void;
};

const ProspectListItem = ({ id, addLoneSoul, addLoneCompanion, addTeam }: ProspectListItemProps) => {
  const [isInfoDrawerOpen, setIsInfoDrawerOpen] = useState(false);
  const { data, loading, error } = useProspectSignupQuery({
    variables: {
      id,
    },
  });

  if (loading || error || !data || !data.signup) {
    return null;
  }

  const { signup } = data;
  const prospect = signup.soul ? signup.soul : signup.companion!;
  const { organisation } = prospect;

  return (
    <>
      <InfoDrawer
        signup={signup}
        addLoneSoul={addLoneSoul}
        addLoneCompanion={addLoneCompanion}
        addTeam={addTeam}
        isOpen={isInfoDrawerOpen}
        onClose={() => setIsInfoDrawerOpen(false)}
      />
      <tr onClick={() => setIsInfoDrawerOpen(true)}>
        <TableCell>
          {prospect!.display_name}
          {!!prospect.birthdate && <Text muted> ({getAgeInYears(prospect.birthdate).toString()})</Text>}
          <br />
          {!!organisation && (
            <Text muted small>
              {organisation.name}
            </Text>
          )}
        </TableCell>
        <TableCell>
          {signup.is_first_attend && (
            <TooltipTag minimal intent={Intent.SUCCESS} label="Neu" tooltip="Erste Teilnahme" marginRight />
          )}
          <ConflictingSignups signups={signup.conflicting_signups} userName={prospect.display_name} />
          <PersonIconList person={prospect} />
          <br />
          <Text small muted>
            {signup.notes}
          </Text>
        </TableCell>
        <TableCell>{signup.priority}</TableCell>
      </tr>
    </>
  );
};

ProspectListItem.fragments = {
  prospect: gql`
    fragment ProspectListItem on Signup {
      id
      event_id
      token
      is_candidate
      is_invited
      has_accepted
      has_rejected
      notes
      priority
      is_first_attend
      conflicting_signups {
        id
        event {
          id
          name
          start
        }
      }
      emails {
        id
        status
      }
      companion {
        id
        display_name
        birthdate
        email
        phone
        mobile
        lat
        lng
        notes
        meta {
          can_blind
          can_wheelchair
          can_wheeled_walker
          can_drive
        }
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
      }
      soul {
        id
        display_name
        birthdate
        email
        phone
        mobile
        lat
        lng
        notes
        meta {
          buys_ticket
          needs_blind
          needs_wheelchair
          needs_wheeled_walker
          needs_drive
          needs_trainstation_fetch
        }
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
      }
    }
  `,
};

export default ProspectListItem;
