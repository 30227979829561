import { Marker as GMarker } from '@react-google-maps/api';
import React from 'react';
import { MarkerType } from '../types';

type MarkerProps = {
  marker: MarkerType;
  isActive?: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
};

const markerIconUrls = {
  location: '/maps/location_pin@2x.png',
  soul: '/maps/soul_pin@2x.png',
  companion: '/maps/companion_pin@2x.png',
};

const Marker = ({ marker, isActive, onMouseOver, onMouseOut }: MarkerProps) => (
  <GMarker
    icon={{
      url: markerIconUrls[marker.type],
      anchor: new google.maps.Point(17, 51),
      scaledSize: new google.maps.Size(34, 51),
      size: new google.maps.Size(68, 102),
    }}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    animation={isActive ? google.maps.Animation.BOUNCE : undefined}
    position={{
      lat: marker.lat,
      lng: marker.lng,
    }}
  />
);

export default Marker;
