import React from 'react';
import { Card, Elevation } from '@blueprintjs/core';
import { gql } from '@apollo/client';
import { AttendeeLoneSoulFragment } from '../../../generated/graphql';
import AttendeeListItem from './AttendeeListItem';

type AttendeeLoneSoulProps = {
  item: AttendeeLoneSoulFragment;
};

const AttendeeLoneSoul = ({ item: soul }: AttendeeLoneSoulProps) => (
  <Card className="overflow-hidden p-0 mb-3" elevation={Elevation.TWO}>
    <AttendeeListItem item={soul} />
  </Card>
);

AttendeeLoneSoul.fragments = {
  loneSoul: gql`
    fragment AttendeeLoneSoul on Signup {
      ...AttendeeListItem
    }
    ${AttendeeListItem.fragments.attendees}
  `,
};

export default AttendeeLoneSoul;
