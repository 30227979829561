import { Icon, InputGroup, Intent, MenuItem, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemPredicate, ItemRenderer, MultiSelect2 } from '@blueprintjs/select';
import { FieldArray, getIn } from 'formik';
import { gql } from '@apollo/client';
import React from 'react';
import { CategoryMultiSelectFragment, useCategoriesQuery } from '../../../generated/graphql';

type Category = CategoryMultiSelectFragment;

type CategoryMultiSelectProps = {
  name: string;
};

const renderTag = (category: Category) => category.name;

const CategoryMultiSelect = ({ name }: CategoryMultiSelectProps) => {
  const { data, loading, error } = useCategoriesQuery();

  return (
    <FieldArray name={name}>
      {({ form, remove, push }) => {
        const selectedCategories: Category[] = getIn(form.values, name) || [];

        const isCategorySelected = (category: Category) => getSelectedCategoryIndex(category) !== -1;

        const getSelectedCategoryIndex = (category: Category) =>
          selectedCategories.findIndex((selectedCategory) => selectedCategory.id === category.id);

        const filterCategories: ItemPredicate<Category> = (query, category) =>
          category.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;

        // NOTE: not using Films.itemRenderer here so we can set icons.
        const renderCategory: ItemRenderer<Category> = (category, { modifiers, handleClick }) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }
          return (
            <MenuItem
              active={modifiers.active}
              icon={isCategorySelected(category) ? 'tick' : 'blank'}
              key={category.id}
              onClick={handleClick}
              text={category.name}
              shouldDismissPopover={false}
            />
          );
        };

        const handleCategorySelect = (category: Category) => {
          if (!isCategorySelected(category)) {
            push(category);
          } else {
            remove(getSelectedCategoryIndex(category));
          }
        };

        const handleTagRemove = (_tag: React.ReactNode, index: number) => {
          remove(index);
        };

        if (loading) return <InputGroup rightElement={<Spinner size={16} />} value="Lade..." disabled />;
        if (error || !data || !data.categories) {
          return (
            <InputGroup leftIcon={<Icon icon={IconNames.ERROR} intent={Intent.DANGER} />} value="Fehler" disabled />
          );
        }

        return (
          <MultiSelect2<Category>
            fill
            itemRenderer={renderCategory}
            itemPredicate={filterCategories}
            tagRenderer={renderTag}
            resetOnSelect
            itemsEqual="id"
            items={data.categories}
            selectedItems={selectedCategories}
            onItemSelect={handleCategorySelect}
            noResults={<MenuItem disabled={true} text="Keine Resultate." />}
            popoverProps={{ minimal: true, rootBoundary: 'viewport' }}
            tagInputProps={{
              onRemove: handleTagRemove,
              disabled: form.isSubmitting,
            }}
          />
        );
      }}
    </FieldArray>
  );
};

CategoryMultiSelect.fragments = {
  categories: gql`
    fragment CategoryMultiSelect on Category {
      id
      name
    }
  `,
};

export default CategoryMultiSelect;
