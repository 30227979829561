import { RoleListItemFragment } from '../../generated/graphql';

export const dummyRoles: RoleListItemFragment[] = [
  {
    id: '1',
    name: 'Normal Role Name',
    permissions: [
      {
        id: '1',
        title: 'Kann eine Sache tun',
      },
    ],
  },
  {
    id: '2',
    name: 'Small',
    permissions: [
      {
        id: '1',
        title: 'Kann eine Sache besonders gut tun',
      },
    ],
  },
  {
    id: '3',
    name: 'Quite a long Role Name',
    permissions: [
      {
        id: '1',
        title: 'Kann eine Sache mehr tun',
      },
    ],
  },
  {
    id: '4',
    name: 'Normal Role Name again',
    permissions: [
      {
        id: '1',
        title: 'Kann eine Sache tun',
      },
    ],
  },
];
