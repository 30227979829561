import React from 'react';
import { Button, Callout, Classes, Drawer, Intent, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ProspectGroupListItemFragment } from '../../../generated/graphql';
import GroupMemberList from './GroupMemberList';
import Text from '../../../components/Text';

type GroupInfoDrawerProps = {
  leaderSignup: ProspectGroupListItemFragment;
  isOpen: boolean;
  onClose: () => void;
  addGroup: (companionSignupId: string, soulSignupIds: string[]) => void;
};

const GroupInfoDrawer = ({ leaderSignup, isOpen, onClose, addGroup }: GroupInfoDrawerProps) => {
  const { companion, memberSignups } = leaderSignup;
  const { organisation } = companion!;

  return (
    <Drawer
      title={
        <Text large className="mr-4">
          {organisation!.name}
        </Text>
      }
      position={Position.LEFT}
      size="600px"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={[Classes.DRAWER_BODY, 'flex flex-col min-h-0 overflow-visible pt-4'].join(' ')}>
        {!!leaderSignup.notes && (
          <Callout className="mx-5 mb-4" intent={Intent.PRIMARY} icon={null}>
            {leaderSignup.notes}
          </Callout>
        )}
        <GroupMemberList leaderSignup={leaderSignup} />
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        <Button
          icon={IconNames.SMALL_TICK}
          intent={Intent.SUCCESS}
          text="Gruppe auf die Teilnehmerliste"
          className="float-right"
          onClick={() =>
            addGroup(
              leaderSignup.id,
              memberSignups.map((memberSignup) => memberSignup.id),
            )
          }
        />
      </div>
    </Drawer>
  );
};

export default GroupInfoDrawer;
