import { gql } from '@apollo/client';
import LocationForm from './components/LocationForm';
import LocationListItem from './components/LocationListItem';
import LocationInfo from './components/LocationInfo';
import DonorListItem from '../donors/components/DonorListItem';

export const LOCATION_LIST_QUERY = gql`
  query LocationList($page: Int, $trashed: Trashed) {
    locations(first: 20, page: $page, trashed: $trashed) {
      data {
        ...LocationListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
  ${LocationListItem.fragments.location}
`;

export const LOCATION_DETAIL_QUERY = gql`
  query LocationDetail($id: ID!) {
    location(id: $id) {
      id
      ...LocationInfo
    }
  }
  ${LocationInfo.fragments.location}
`;

export const LOCATION_DONORS_QUERY = gql`
  query LocationDonors($id: ID!) {
    location(id: $id) {
      id
      donors {
        ...DonorListItem
      }
    }
  }
  ${DonorListItem.fragments.donor}
`;

export const CREATE_LOCATION_MUTATION = gql`
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      ...LocationInfo
    }
  }
  ${LocationInfo.fragments.location}
`;

export const LOCATION_EDIT_QUERY = gql`
  query LocationEdit($id: ID!) {
    location(id: $id) {
      id
      ...LocationEditForm
    }
  }
  ${LocationForm.fragments.edit}
`;

export const UPDATE_LOCATION_MUTATION = gql`
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      ...LocationInfo
    }
  }
  ${LocationInfo.fragments.location}
`;

export const SET_LOCATION_POSTER_MUTATION = gql`
  mutation SetLocationPoster($input: SetMediaInput!) {
    setLocationPoster(input: $input) {
      id
      poster {
        name
        thumb_url
      }
    }
  }
`;

export const DELETE_LOCATION_MUTATION = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(id: $id) {
      id
    }
  }
`;

export const RESTORE_LOCATIONS_MUTATION = gql`
  mutation RestoreLocations($ids: [ID!]!) {
    restoreLocations(ids: $ids) {
      id
    }
  }
`;

export const FORCE_DELETE_LOCATIONS_MUTATION = gql`
  mutation ForceDeleteLocations($ids: [ID!]!) {
    forceDeleteLocations(ids: $ids) {
      id
    }
  }
`;

export const EMPTY_LOCATION_TRASH_MUTATION = gql`
  mutation EmptyLocationTrash {
    emptyLocationTrash {
      id
    }
  }
`;
