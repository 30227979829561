import React from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';

export interface EmptyProps {
  icon?: IconName;
  title?: string;
  description?: string;
  action?: JSX.Element;
}

const Empty = (props: EmptyProps) => (
  <div className="text-center py-10">
    <NonIdealState {...props} />
  </div>
);

Empty.defaultProps = {
  title: 'Keine Daten',
  icon: IconNames.INBOX,
  description: 'Derzeit keine Daten vorhanden.',
} as Partial<EmptyProps>;

export default Empty;
