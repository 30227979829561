import { gql } from '@apollo/client';
import { IconName, IconNames } from '@blueprintjs/icons';
import React from 'react';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import { AllotmentListItemFragment, AllotmentStatus } from '../../../generated/graphql';
import { formatDay } from '../../../helpers/dateTimeUtils';

export type AllotmentListProps = {
  item: AllotmentListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

type StatusMap = Record<AllotmentStatus, { label: string; icon: IconName }>;

const statusMap: StatusMap = {
  [AllotmentStatus.Planned]: {
    label: 'Geplant',
    icon: IconNames.CALENDAR,
  },
  [AllotmentStatus.Active]: {
    label: 'Aktiv',
    icon: IconNames.TICK,
  },
  [AllotmentStatus.Expired]: {
    label: 'Abgelaufen',
    icon: IconNames.OUTDATED,
  },
  [AllotmentStatus.Depleted]: {
    label: 'Ausgeschöpft',
    icon: IconNames.DISABLE,
  },
};

const renderValidity = (status: AllotmentStatus, start: string, end?: string | null, skeleton?: boolean) => {
  if (status === AllotmentStatus.Planned) {
    return <Text skeleton={skeleton} muted>{`Beginnt am ${formatDay(start)}`}</Text>;
  }

  if (status === AllotmentStatus.Expired) {
    return <Text skeleton={skeleton} muted>{`Abgelaufen am ${end ? formatDay(end) : '-'}`}</Text>;
  }

  if (end) {
    return <Text skeleton={skeleton}>{`Läuft ab am ${formatDay(end)}`}</Text>;
  }

  return <Text skeleton={skeleton}>Unbegrenzt gültig!</Text>;
};

const AllotmentListItem = ({ item, skeleton, onClick }: AllotmentListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <TooltipIcon
        label={statusMap[item.status].label}
        icon={statusMap[item.status].icon}
        skeleton={skeleton}
        marginRight
      />
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{item.name}</Text>
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{item.amount_left}</Text>
      <Text skeleton={skeleton} muted>
        {' / '}
      </Text>
      <Text skeleton={skeleton} muted>
        {item.amount}
      </Text>
    </TableCell>
    <TableCell>{renderValidity(item.status, item.start, item.end, skeleton)}</TableCell>
  </tr>
);

AllotmentListItem.fragments = {
  allotment: gql`
    fragment AllotmentListItem on Allotment {
      id
      name
      status
      amount
      amount_left
      start
      end
    }
  `,
};

export default AllotmentListItem;
