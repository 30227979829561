import { Classes, Icon, Intent, ProgressBar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import Text from '../../../components/Text';
import { EventListItemFragment, EventStatus } from '../../../generated/graphql';

type AttendeeListStatusProps = {
  event: EventListItemFragment;
  skeleton?: boolean;
};

const onlySouls = (signup: EventListItemFragment['signups'][0]) => signup.is_soul;
const onlyCompanions = (signup: EventListItemFragment['signups'][0]) => !signup.is_soul;

const AttendeeListStatus = ({ event, skeleton }: AttendeeListStatusProps) => {
  if (event.status === EventStatus.Draft || event.status === EventStatus.Ready) {
    return (
      <Text skeleton={skeleton} small muted>
        {event.available_tickets ? `${event.available_tickets} Karten` : 'Freier Eintritt'}
        {' - noch nicht veröffentlicht'}
      </Text>
    );
  }

  if (event.status === EventStatus.Published) {
    return (
      <>
        <ProgressBar
          stripes={false}
          animate={false}
          value={event.available_tickets ? event.signups.length / event.available_tickets : 0}
        />
        <div className="flex justify-between">
          <div>
            <Text skeleton={skeleton} small muted>
              Angemeldet:
            </Text>
            <Text skeleton={skeleton} small>{` ${event.signups.filter(onlySouls).length} `}</Text>
            <Icon
              icon={IconNames.HEART}
              iconSize={12}
              className={[skeleton && Classes.SKELETON, Classes.TEXT_MUTED, 'align-middle'].join(' ')}
            />
            <Text skeleton={skeleton} small muted>
              {` + `}
            </Text>
            <Text skeleton={skeleton} small>{`${event.signups.filter(onlyCompanions).length} `}</Text>
            <Icon
              icon={IconNames.HAND}
              iconSize={12}
              className={[skeleton && Classes.SKELETON, Classes.TEXT_MUTED, 'align-middle'].join(' ')}
            />
          </div>
          <div>
            <Text skeleton={skeleton} small muted>
              {event.available_tickets ? `${event.available_tickets} Karten` : 'Freier Eintritt'}
            </Text>
          </div>
        </div>
      </>
    );
  }

  if (event.status === EventStatus.Closed) {
    return (
      <>
        <ProgressBar
          stripes={false}
          animate={false}
          intent={event.attendeeCount === event.candidateCount ? Intent.SUCCESS : Intent.PRIMARY}
          value={event.attendeeCount / event.candidateCount}
        />
        <div className="flex justify-between">
          <div>
            <Text skeleton={skeleton} small muted>
              Zugesagt:
            </Text>
            <Text skeleton={skeleton} small>{` ${event.attendees.filter(onlySouls).length} `}</Text>
            <Icon
              icon={IconNames.HEART}
              iconSize={12}
              className={[skeleton && Classes.SKELETON, Classes.TEXT_MUTED, 'align-middle'].join(' ')}
            />
            <Text skeleton={skeleton} small muted>
              {` + `}
            </Text>
            <Text skeleton={skeleton} small>{`${event.attendees.filter(onlyCompanions).length} `}</Text>
            <Icon
              icon={IconNames.HAND}
              iconSize={12}
              className={[skeleton && Classes.SKELETON, Classes.TEXT_MUTED, 'align-middle'].join(' ')}
            />
          </div>
          <div>
            <Text skeleton={skeleton} small muted>
              {event.available_tickets ? `${event.available_tickets} Karten` : 'Freier Eintritt'}
            </Text>
          </div>
        </div>
      </>
    );
  }

  if (event.status === EventStatus.Cancelled) {
    return (
      <Text skeleton={skeleton} small muted>
        Veranstaltung abgesagt!
      </Text>
    );
  }

  return (
    <Text skeleton={skeleton} small muted>
      Fehler
    </Text>
  );
};

export default AttendeeListStatus;
