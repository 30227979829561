/** @jsxImportSource @emotion/react */
import { OverlayProps, Overlay } from '@blueprintjs/core';
import { css } from '@emotion/react';

export enum OverlaySize {
  Small = 'small',
  Large = 'large',
}

type ContentOverlayProps = {
  size?: OverlaySize;
  children: React.ReactNode;
} & OverlayProps;

const ContentOverlay = (props: ContentOverlayProps) => {
  const { children, size = OverlaySize.Small, transitionDuration = 0, ...overlayProps } = props;

  return (
    // enforceFocus={false} is needed to allow Selects inside
    // See: https://github.com/palantir/blueprint/issues/3854
    <Overlay enforceFocus={false} transitionDuration={transitionDuration} {...overlayProps}>
      <div css={[styles.container, styles[size]]}>{children}</div>
    </Overlay>
  );
};

export default ContentOverlay;

const styles = {
  container: css`
    margin: 10vh 0;
  `,
  small: css`
    left: calc(50vw - 225px);
    width: 450px;
  `,
  large: css`
    left: calc(50vw - 425px);
    width: 850px;
  `,
};
