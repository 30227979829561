import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AllotmentDetailPage from '../modules/allotments/pages/AllotmentDetailPage';
import AllotmentListPage from '../modules/allotments/pages/AllotmentListPage';
import CategoryDetailPage from '../modules/categories/pages/CategoryDetailPage';
import CategoryListPage from '../modules/categories/pages/CategoryListPage';
import CompanionDetailPage from '../modules/companions/pages/CompanionDetailPage';
import CompanionListPage from '../modules/companions/pages/CompanionListPage';
import ContactDetailPage from '../modules/contacts/pages/ContactDetailPage';
import DonorDetailPage from '../modules/donors/pages/DonorDetailPage';
import DonorListPage from '../modules/donors/pages/DonorListPage';
import EmployeeDetailPage from '../modules/employees/pages/EmployeeDetailPage';
import EmployeeListPage from '../modules/employees/pages/EmployeeListPage';
import EventDetailPage from '../modules/events/pages/EventDetailPage';
import EventListPage from '../modules/events/pages/EventListPage';
import LocationDetailPage from '../modules/locations/pages/LocationDetailPage';
import LocationListPage from '../modules/locations/pages/LocationListPage';
import MatchingPage from '../modules/matching/pages/MatchingPage';
import NewsletterDetailPage from '../modules/newsletters/pages/NewsletterDetailPage';
import NewsletterListPage from '../modules/newsletters/pages/NewsletterListPage';
import OrganisationDetailPage from '../modules/organisations/pages/OrganisationDetailPage';
import OrganisationListPage from '../modules/organisations/pages/OrganisationListPage';
import RoleDetailPage from '../modules/roles/pages/RoleDetailPage';
import RoleListPage from '../modules/roles/pages/RoleListPage';
import SoulDetailPage from '../modules/souls/pages/SoulDetailPage';
import SoulListPage from '../modules/souls/pages/SoulListPage';
import SettingsDetailPage from '../modules/settings/pages/SettingsDetailPage';
import StatsDetailPage from '../modules/stats/pages/StatsDetailPage';
import NewsletterArchiveListPage from '../modules/newsletters/pages/NewsletterArchiveListPage';
import EventArchiveListPage from '../modules/events/pages/EventArchiveListPage';
import TagListPage from '../modules/tags/pages/TagListPage';
import TagDetailPage from '../modules/tags/pages/TagDetailPage';
import TrashPage from '../modules/trash/pages/TrashPage';

const MainContent = () => (
  <div
    style={{
      marginTop: 55,
    }}
  >
    <Routes>
      <Route path="/events" element={<EventListPage />} />
      <Route path="/events/archive" element={<EventArchiveListPage />} />
      <Route path="/events/:eventId" element={<EventDetailPage />} />
      <Route path="/events/:eventId/matching" element={<MatchingPage />} />
      <Route path="/categories" element={<CategoryListPage />} />
      <Route path="/categories/:categoryId" element={<CategoryDetailPage />} />
      <Route path="/allotments" element={<AllotmentListPage />} />
      <Route path="/allotments/:allotmentId" element={<AllotmentDetailPage />} />
      <Route path="/souls" element={<SoulListPage />} />
      <Route path="/souls/:soulId" element={<SoulDetailPage />} />
      <Route path="/companions" element={<CompanionListPage />} />
      <Route path="/companions/:companionId" element={<CompanionDetailPage />} />
      <Route path="/donors" element={<DonorListPage />} />
      <Route path="/donors/:donorId" element={<DonorDetailPage />} />
      <Route path="/contacts/:contactId" element={<ContactDetailPage />} />
      <Route path="/locations" element={<LocationListPage />} />
      <Route path="/locations/:locationId" element={<LocationDetailPage />} />
      <Route path="/organisations" element={<OrganisationListPage />} />
      <Route path="/organisations/:organisationId" element={<OrganisationDetailPage />} />
      <Route path="/tags" element={<TagListPage />} />
      <Route path="/tags/:tagId" element={<TagDetailPage />} />
      <Route path="/employees" element={<EmployeeListPage />} />
      <Route path="/employees/:employeeId" element={<EmployeeDetailPage />} />
      <Route path="/roles" element={<RoleListPage />} />
      <Route path="/roles/:roleId" element={<RoleDetailPage />} />
      <Route path="/newsletters" element={<NewsletterListPage />} />
      <Route path="/newsletters/archive" element={<NewsletterArchiveListPage />} />
      <Route path="/newsletters/:newsletterId" element={<NewsletterDetailPage />} />
      <Route path="/settings" element={<SettingsDetailPage />} />
      <Route path="/stats" element={<StatsDetailPage />} />
      <Route path="/trash" element={<TrashPage />} />
      <Route path="*" element={<Navigate to="/not-found" replace />} />
    </Routes>
  </div>
);

export default MainContent;
