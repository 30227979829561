import { gql } from '@apollo/client';
import { Classes, Elevation, Tag } from '@blueprintjs/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EmailLink from '../../../components/EmailLink';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useContactDetailQuery } from '../../../generated/graphql';
import ContactDetailMenu from './ContactDetailMenu';

type ContactInfoProps = {
  onEdit: () => void;
};

type RouteParams = {
  contactId?: string;
};

const ContactInfo = ({ onEdit }: ContactInfoProps) => {
  const { contactId } = useParams<RouteParams>();
  const { data, loading, error } = useContactDetailQuery({
    variables: {
      id: contactId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.contact) return <NotFoundCard resource="Ansprechpartner" />;

  const { contact } = data;

  const address: string | null =
    contact.street && contact.postal_code && contact.city
      ? `${contact.street}, ${contact.postal_code} ${contact.city}`
      : null;

  return (
    <>
      <ContentCard elevation={Elevation.TWO} className="mb-5">
        <ContentCardHeader
          leftElement={
            <>
              <Text large>{contact.display_name}</Text>
              <Tag minimal className="ml-2">
                Ansprechpartner
              </Tag>
            </>
          }
          rightElement={<ContactDetailMenu contact={contact} onEdit={onEdit} />}
        />
        <div className="flex py-4 px-5">
          <PropertyList>
            <PropertyListHeader>Person</PropertyListHeader>
            <PropertyListItem label="Email">
              <EmailLink email={contact.email} />
            </PropertyListItem>

            <PropertyListItem label="Telefon" value={contact.phone || '—'} showValueDisabled={!contact.phone} />
            <PropertyListItem label="Mobil" value={contact.mobile || '—'} showValueDisabled={!contact.mobile} />
            <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
          </PropertyList>
          <PropertyListDivider />
          <PropertyList>
            <PropertyListHeader>Ansprechpartner Info</PropertyListHeader>
            <PropertyListItem label="Interne Notizen">
              {contact.notes ? (
                <div className={[Classes.RUNNING_TEXT, Classes.TEXT_MUTED].join(' ')}>{contact.notes}</div>
              ) : (
                <Text disabled>—</Text>
              )}
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>
    </>
  );
};

ContactInfo.fragments = {
  contact: gql`
    fragment ContactInfo on Contact {
      id
      display_name
      first_name
      last_name
      email
      phone
      mobile
      street
      city
      postal_code
      notes
    }
  `,
};

export default ContactInfo;
