import React from 'react';
import { Classes, Colors, Icon, IconName, Intent, Position, Props } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

type TooltipIconProps = {
  icon: IconName;
  intent?: Intent;
  color?: string;
  label: string;
  position?: Position;
  skeleton?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
} & Props;

const getColor = (intent?: Intent, color?: string) => {
  if (intent) return undefined;
  if (color) return color;
  return Colors.GRAY1;
};

const TooltipIcon = ({
  icon,
  intent,
  marginRight,
  marginLeft,
  color,
  label,
  position,
  skeleton,
  className,
}: TooltipIconProps) => (
  <Tooltip2 content={label} position={position} disabled={skeleton}>
    <Icon
      icon={icon}
      intent={intent}
      color={getColor(intent, color)}
      className={[className, skeleton && Classes.SKELETON, marginRight && 'mr-1', marginLeft && 'ml-1'].join(' ')}
    />
  </Tooltip2>
);

export default TooltipIcon;
