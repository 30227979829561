import { MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, MultiSelect2 } from '@blueprintjs/select';
import { FieldArray, getIn } from 'formik';
import React from 'react';

export type GeneralMultiSelectItem = {
  id: string;
  label: string;
};

type Item = GeneralMultiSelectItem;

type GeneralMultiSelectProps = {
  name: string;
  items: Item[];
};

const renderTag = (item: Item) => item.label;

const GeneralMultiSelect = ({ name, items }: GeneralMultiSelectProps) => (
  <FieldArray name={name}>
    {({ form, push, remove }) => {
      const selectedItems: Item[] = getIn(form.values, name) || [];

      const isItemSelected = (item: Item) => getSelectedItemIndex(item) !== -1;

      const getSelectedItemIndex = (item: Item) =>
        selectedItems.findIndex((selectedItem) => selectedItem.id === item.id);

      const filterItems: ItemPredicate<Item> = (query, item) =>
        item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;

      const renderItemItem: ItemRenderer<Item> = (item, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
          return null;
        }
        return (
          <MenuItem
            active={modifiers.active}
            icon={isItemSelected(item) ? 'tick' : 'blank'}
            key={item.id}
            onClick={handleClick}
            text={item.label}
            shouldDismissPopover={false}
          />
        );
      };

      const handleItemSelect = (item: Item) => {
        if (!isItemSelected(item)) {
          push(item);
        } else {
          remove(getSelectedItemIndex(item));
        }
      };

      const handleItemRemove = (_item: React.ReactNode, index: number) => {
        remove(index);
      };

      return (
        <MultiSelect2<Item>
          fill
          itemRenderer={renderItemItem}
          itemPredicate={filterItems}
          tagRenderer={renderTag}
          resetOnSelect
          itemsEqual="id"
          items={items}
          selectedItems={selectedItems}
          onItemSelect={handleItemSelect}
          noResults={<MenuItem disabled={true} text="Keine Resultate." />}
          popoverProps={{ minimal: true, rootBoundary: 'viewport' }}
          tagInputProps={{
            onRemove: handleItemRemove,
            disabled: form.isSubmitting,
          }}
        />
      );
    }}
  </FieldArray>
);

export default GeneralMultiSelect;
