import { ResponsivePie } from '@nivo/pie';
import React from 'react';
import { needsDarkText } from '../../../helpers/utils';
import { Colors } from '@blueprintjs/core';

type PieChartProps = {
  data: { id: string | number; value: number; label: string; color: string }[];
};

const PieChart = ({ data }: PieChartProps) => (
  <ResponsivePie
    isInteractive={false}
    data={data}
    margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={2}
    colors={(d) => `${d.data.color}`}
    arcLinkLabel="label"
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextOffset={5}
    arcLinkLabelsTextColor={Colors.DARK_GRAY1}
    arcLinkLabelsOffset={0}
    arcLinkLabelsDiagonalLength={5}
    arcLinkLabelsStraightLength={10}
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    arcLabel={(d) => `${Math.round(d.value * 100)}%`}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor={(d) => (needsDarkText(d.color) ? Colors.DARK_GRAY1 : Colors.WHITE)}
  />
);

export default PieChart;
