import { gql } from '@apollo/client';
import React from 'react';
import PersonIconList from '../../../components/PersonIconList';
import PersonTagIcon from '../../../components/PersonTagIcon';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { OrganisationDetailPageFragment } from '../../../generated/graphql';

export type OrganisationSoulListItemProps = {
  item: OrganisationDetailPageFragment['souls'][0];
  onClick?: () => void;
};

const OrganisationSoulListItem = ({ item, onClick }: OrganisationSoulListItemProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <Text>{item.display_name}</Text>
    </TableCell>
    <TableCell>
      <PersonIconList person={item} />
    </TableCell>
  </tr>
);

OrganisationSoulListItem.fragments = {
  soul: gql`
    fragment OrganisationSoulListItem on Soul {
      id
      display_name
      meta {
        buys_ticket
        needs_wheelchair
        needs_wheeled_walker
        needs_drive
        needs_trainstation_fetch
        needs_blind
      }
      tags {
        ...PersonTagIcon
      }
    }
    ${PersonTagIcon.fragments.tag}
  `,
};

export default OrganisationSoulListItem;
