import { gql } from '@apollo/client';
import { Checkbox } from '@blueprintjs/core';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { OrganisationListItemFragment } from '../../../generated/graphql';
import { noop } from '../../../helpers/utils';
import { useNavigateSafely } from '../../../hooks/useNavigateSafely';
import { ListItemComponent } from '../../../types';

export type OrganisationListProps = {
  item: OrganisationListItemFragment;
  skeleton?: boolean;
} & ListItemComponent;

const OrganisationListItem = ({ item, skeleton, selected, onToggle }: OrganisationListProps) => {
  const buildNavigateSafely = useNavigateSafely();
  return (
    <tr onClick={onToggle || skeleton ? noop : buildNavigateSafely(`/organisations/${item!.id}`)}>
      {onToggle && (
        <TableCell>
          <Checkbox inline disabled={skeleton} checked={selected} onChange={() => onToggle(item.id)} className="m-0" />
        </TableCell>
      )}
      <TableCell>
        <Text skeleton={skeleton}>{item.name}</Text>
      </TableCell>
    </tr>
  );
};

OrganisationListItem.fragments = {
  organisation: gql`
    fragment OrganisationListItem on Organisation {
      id
      name
    }
  `,
};

export default OrganisationListItem;
