import React from 'react';
import { Card, Spinner } from '@blueprintjs/core';

const LoadingCard = () => (
  <Card className="text-center p-10 mb-3">
    <Spinner />
  </Card>
);

export default LoadingCard;
