import { gql } from '@apollo/client';
import CategoryForm from './components/CategoryForm';
import CategoryListItem from './components/CategoryListItem';
import CategoryDetailPage from './pages/CategoryDetailPage';

export const CATEGORY_LIST_QUERY = gql`
  query CategoryList($trashed: Trashed) {
    categories(trashed: $trashed) {
      ...CategoryListItem
    }
  }
  ${CategoryListItem.fragments.category}
`;

export const CATEGORY_DETAIL_QUERY = gql`
  query CategoryDetail($id: ID!) {
    category(id: $id) {
      id
      ...CategoryDetailPage
    }
  }
  ${CategoryDetailPage.fragments.category}
`;

export const CATEGORY_EDIT_QUERY = gql`
  query CategoryEdit($id: ID!) {
    category(id: $id) {
      id
      ...CategoryEditForm
    }
  }
  ${CategoryForm.fragments.edit}
`;

export const CREATE_CATEGORY_MUTATION = gql`
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      ...CategoryDetailPage
    }
  }
  ${CategoryDetailPage.fragments.category}
`;

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      ...CategoryDetailPage
    }
  }
  ${CategoryDetailPage.fragments.category}
`;

export const DELETE_CATEGORY_MUTATION = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(id: $id) {
      id
    }
  }
`;

export const RESTORE_CATEGORIES_MUTATION = gql`
  mutation RestoreCategories($ids: [ID!]!) {
    restoreCategories(ids: $ids) {
      id
    }
  }
`;

export const FORCE_DELETE_CATEGORIES_MUTATION = gql`
  mutation ForceDeleteCategories($ids: [ID!]!) {
    forceDeleteCategories(ids: $ids) {
      id
    }
  }
`;

export const EMPTY_CATEGORY_TRASH_MUTATION = gql`
  mutation EmptyCategoryTrash {
    emptyCategoryTrash {
      id
    }
  }
`;
