import { Intent, ProgressBar } from '@blueprintjs/core';
import React from 'react';
import Text from '../../../components/Text';

type CandidateListStatusProps = {
  availableTickets?: number | null;
  takenTickets: number;
  purchasedTickets: number;
};

const CandidateListStatus = ({ availableTickets, takenTickets, purchasedTickets }: CandidateListStatusProps) => {
  if (typeof availableTickets === 'undefined' || availableTickets === null) {
    return (
      <Text small muted>
        {takenTickets ? `${takenTickets} Karten - ` : ''}
        Freier Eintritt
      </Text>
    );
  }
  return (
    <>
      <ProgressBar
        stripes={false}
        animate={false}
        value={takenTickets / availableTickets}
        intent={takenTickets === availableTickets ? Intent.SUCCESS : Intent.PRIMARY}
      />
      <Text small muted>
        Vergeben:
      </Text>
      <Text small>{` ${takenTickets} / ${availableTickets} Karten`}</Text>
      {purchasedTickets > 0 && <Text small muted>{` + ${purchasedTickets} Selbstzahler`}</Text>}
    </>
  );
};

export default CandidateListStatus;
