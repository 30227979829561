/** @jsxImportSource @emotion/react */
import { useApolloClient } from '@apollo/client';
import { Button, Colors, Navbar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css } from '@emotion/react';
import AppToaster from '../AppToaster';
import { useLogoutMutation } from '../generated/graphql';
import { setPersisted, unsetPersisted } from '../helpers/constants';
import { showErrorMessage } from '../helpers/graphql';
import { IS_AUTHENTICATED } from '../modules/auth/queries';
import SiteSearch from '../modules/search/components/SiteSearch';

const TopNavBar = () => {
  const client = useApolloClient();
  const [logout, { loading }] = useLogoutMutation({
    onCompleted: () => {
      setPersisted('isLoggedIn', false);
      unsetPersisted('permissions');
      AppToaster.success('Du wurdest abgemeldet');

      // The store reset works in three stages:
      // Stage 1: reset of persisted data to "logged out" state
      // Stage 2: update apollo clients authentication status (will trigger redirect to login page)
      // Stage 3: reset the store with a timeout (otherwise the currently loaded query will reload
      // before the redirect happens, which will fail as user is no longer authenticated)
      client.writeQuery({
        query: IS_AUTHENTICATED,
        data: {
          isAuthenticated: false,
        },
      });
      setImmediate(() => client.resetStore());
    },
    onError: showErrorMessage,
  });

  return (
    <Navbar fixedToTop css={styles.navBar}>
      <SiteSearch />
      <div css={styles.navBarSide}>
        <Button text="Abmelden" minimal icon={IconNames.LOG_OUT} loading={loading} onClick={() => logout()} />
      </div>
    </Navbar>
  );
};

export default TopNavBar;

const styles = {
  navBar: css`
    box-shadow: none;
    background-color: ${Colors.LIGHT_GRAY5};
    max-width: 1070px;
    left: auto !important;
    right: auto !important;
    width: calc(100vw - 210px);
    height: 50px;
    padding: 5px 10px 0;
    margin-left: -10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  navBarSide: css`
    display: flex;
  `,
};
