import { HTMLTable } from '@blueprintjs/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Empty from '../../../components/Empty';
import Error from '../../../components/Error';
import NoResults from '../../../components/NoResults';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { useRoleListQuery } from '../../../generated/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import { dummyRoles } from '../dummy';
import RoleListItem from './RoleListItem';

const TableWrapper = ({ children }: { children: React.ReactNode }) => (
  <HTMLTable condensed interactive className="w-full">
    <thead>
      <tr>
        <TableHeaderCell>Name</TableHeaderCell>
        <TableHeaderCell>Berechtigungen</TableHeaderCell>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </HTMLTable>
);

const RoleList = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const filters = queryParams.get('filters');

  const { data, loading, error } = useRoleListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    fetchPolicy: 'network-only',
  });

  if (loading)
    return (
      <TableWrapper>
        {dummyRoles.map((role) => (
          <RoleListItem key={role.id} skeleton item={role} />
        ))}
      </TableWrapper>
    );
  if (error) return <Error />;
  if (!data || !data.roles || !data.roles.length) {
    return filters ? <NoResults /> : <Empty />;
  }

  return (
    <TableWrapper>
      {data.roles.map((role) => (
        <RoleListItem key={role.id} item={role} onClick={() => navigate(`/roles/${role!.id}`)} />
      ))}
    </TableWrapper>
  );
};

export default RoleList;
