import { ITextAreaProps, TextArea } from '@blueprintjs/core';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { useFocusOnError } from '../helpers/forms';

type TextAreaInputProps = {
  name: string;
} & ITextAreaProps;

const TextAreaInput = (props: TextAreaInputProps) => {
  const [{ value, ...otherFieldProps }] = useField(props.name);
  const fieldRef = useRef<HTMLTextAreaElement>();
  const formik = useFormikContext<any>();
  useFocusOnError({ fieldRef, name: props.name });

  return (
    <TextArea
      {...props}
      {...otherFieldProps}
      inputRef={(ref) => (fieldRef.current = ref || undefined)}
      value={value || ''}
      disabled={props.disabled || formik.isSubmitting}
    />
  );
};

export default TextAreaInput;
