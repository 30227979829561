import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { DonorCreateFormFragment } from '../../../generated/graphql';
import DonorForm from './DonorForm';

type DonorCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
  onClose: () => void;
} & IOverlayProps;

const DonorCreateOverlay = ({ onSuccess, ...overlayProps }: DonorCreateOverlayProps) => {
  const initialValues: DonorCreateFormFragment = {
    name: '',
    locations: [],
    logo: null,
    public: true,
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <DonorForm
        onCancel={closeOverlay}
        onCreated={({ createDonor }) => {
          onSuccess(createDonor.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default DonorCreateOverlay;
