import { FetchResult } from '@apollo/client';
import { useSubmit } from 'formik-apollo';
import { useNavigate } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import {
  NewPasswordWithCodeInput,
  UpdateForgottenPasswordMutation,
  useUpdateForgottenPasswordMutation,
} from '../../../generated/graphql';
import { ResetPasswordStatus } from '../../../helpers/constants';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import ResetForm from '../components/ResetForm';

const ResetPage = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [reset] = useUpdateForgottenPasswordMutation();

  const handleSubmit = useSubmit<NewPasswordWithCodeInput, FetchResult<UpdateForgottenPasswordMutation>>({
    mutate: (values) =>
      reset({
        variables: {
          input: values,
        },
      }),
    onCompleted: ({ data }) => {
      if (data?.updateForgottenPassword?.status === ResetPasswordStatus.Success) {
        AppToaster.success('Passwort wurde festgelegt!');
        navigate('/auth/login');
      }
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <div className="flex min-h-screen items-center justify-center flex-col">
      <ResetForm onSubmit={handleSubmit} email={queryParams.get('email')} token={queryParams.get('token')} />
    </div>
  );
};

export default ResetPage;
