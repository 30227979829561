/** @jsxImportSource @emotion/react */
import { gql } from '@apollo/client';
import { Elevation, HTMLTable } from '@blueprintjs/core';
import { css } from '@emotion/react';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import NotFoundCard from '../../../components/NotFoundCard';
import TableCell from '../../../components/TableCell';
import TableHeaderCell from '../../../components/TableHeaderCell';
import Text from '../../../components/Text';
import { StatsRangeType, TargetGroupStatsFragment, useTargetGroupStatsQuery } from '../../../generated/graphql';
import { getTargetGroupText } from '../../../helpers/dataUtils';
import { dummyTargetGroupStats } from '../dummy';
import ChangeTag from './ChangeTag';
import PieChart from './PieChart';

type TargetGroupStatsType = {
  rangeType: StatsRangeType;
  range: string;
};

type TargetGroupStatsItemProps = {
  item: TargetGroupStatsFragment;
  skeleton?: boolean;
};

const dataColors = [
  '#2965CC',
  '#29A634',
  '#D99E0B',
  '#D13913',
  '#8F398F',
  '#00B3A4',
  '#DB2C6F',
  '#9BBF30',
  '#96622D',
  '#7157D9',
];

const getSafeColor = (index: number) => dataColors[index % dataColors.length];

const TableWrapper = ({ children }: { children: React.ReactNode }) => (
  <HTMLTable condensed className="w-full">
    <thead>
      <tr>
        <TableHeaderCell>Zielgruppe</TableHeaderCell>
        <TableHeaderCell colSpan={2}>Anmeldungen</TableHeaderCell>
        <TableHeaderCell colSpan={2}>Teilnahmen</TableHeaderCell>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </HTMLTable>
);

const TargetGroupStatsItem = ({ item: { target_group, data }, skeleton }: TargetGroupStatsItemProps) => (
  <tr>
    <TableCell>
      <Text skeleton={skeleton}>{getTargetGroupText(target_group)}</Text>
    </TableCell>
    <TableCell right>
      <Text skeleton={skeleton}>{data.signups}</Text>
    </TableCell>
    <TableCell>
      <ChangeTag now={data.signups} prev={data.previous_signups} skeleton={skeleton} />
    </TableCell>
    <TableCell right>
      <Text skeleton={skeleton}>{data.attends}</Text>
    </TableCell>
    <TableCell>
      <ChangeTag now={data.attends} prev={data.previous_attends} skeleton={skeleton} />
    </TableCell>
  </tr>
);

const TargetGroupStats = ({ range, rangeType }: TargetGroupStatsType) => {
  const { data, loading, error } = useTargetGroupStatsQuery({
    variables: {
      input: {
        range,
        range_type: rangeType,
      },
    },
  });

  if (error) return <ErrorCard css={styles.targetGroupCard} />;
  if (!loading && !data?.targetGroupStats)
    return <NotFoundCard resource="Kulturgenießer nach Zielgruppen" css={styles.targetGroupCard} />;

  const stats = data?.targetGroupStats;
  const totalAttends = stats?.reduce((acc, item) => acc + item.data.attends, 0) ?? 1;
  const pieData = stats
    ? stats.map((item, index) => ({
        id: item.target_group,
        label: getTargetGroupText(item.target_group),
        value: item.data.attends / totalAttends,
        color: getSafeColor(index),
      }))
    : [];

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.targetGroupCard}>
      <ContentCardHeader leftElement={<Text large>Kulturgenießer nach Zielgruppen</Text>} />
      <div css={styles.chartContainer}>{!loading && !!stats && <PieChart data={pieData} />}</div>
      <TableWrapper>
        {(stats && !loading ? stats : dummyTargetGroupStats).map((item) => (
          <TargetGroupStatsItem key={item.target_group} item={item} skeleton={loading || !stats} />
        ))}
      </TableWrapper>
    </ContentCard>
  );
};

TargetGroupStats.fragments = {
  stats: gql`
    fragment TargetGroupStats on TargetGroupStatsPayload {
      target_group
      data {
        signups
        attends
        previous_signups
        previous_attends
      }
    }
  `,
};

export default TargetGroupStats;

const styles = {
  targetGroupCard: css`
    margin-bottom: 20px;
    margin-right: 20px;
    flex-basis: calc(50% - 10px);
  `,
  chartContainer: css`
    height: 400px;
  `,
};
