import { gql } from '@apollo/client';
import { Classes, Elevation, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EmailLink from '../../../components/EmailLink';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import UserStatusTag from '../../../components/PersonStatusTag';
import PersonTagIcon from '../../../components/PersonTagIcon';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import TooltipTag from '../../../components/TooltipTag';
import { GdprConsentSource, Intent, SoulTargetGroup, useSoulDetailQuery } from '../../../generated/graphql';
import { getPersisted } from '../../../helpers/constants';
import { getTargetGroupText } from '../../../helpers/dataUtils';
import { getAgeInYears } from '../../../helpers/dateTimeUtils';
import SoulDetailMenu from './SoulDetailMenu';

type SoulInfoType = {
  onEdit: () => void;
};

type RouteParams = {
  soulId?: string;
};

const SoulInfo = ({ onEdit }: SoulInfoType) => {
  const { soulId } = useParams<RouteParams>();
  const {
    data: soulData,
    loading,
    error,
  } = useSoulDetailQuery({
    variables: {
      id: soulId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!soulData?.soul) return <NotFoundCard resource="Genießer" />;

  const { soul } = soulData;

  const address: string | null =
    soul.street && soul.postal_code && soul.city ? `${soul.street}, ${soul.postal_code} ${soul.city}` : null;

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <ContentCardHeader
        leftElement={
          <>
            <Text large>{soul.display_name}</Text>
            <Tag minimal className="ml-2">
              Genießer
            </Tag>
            <UserStatusTag status={soul.status} />
          </>
        }
        rightElement={<SoulDetailMenu soul={soul} onEdit={onEdit} />}
      />
      <div className="flex py-4 px-5">
        <PropertyList>
          <PropertyListHeader>Person</PropertyListHeader>
          <PropertyListItem
            label="Alter"
            value={soul.birthdate ? getAgeInYears(soul.birthdate).toString() : '—'}
            showValueDisabled={!soul.birthdate}
          />
          <PropertyListItem label="Email">
            <EmailLink email={soul.email} />
          </PropertyListItem>
          {!getPersisted('useLegacyNewsletter') && !!soul.email && (
            <PropertyListItem label="Newsletter">
              {soul.gdpr_consent ? (
                <TooltipTag
                  tooltip="Für diesen Genießer liegt die Zustimmung zum Newsletter-Versand vor"
                  label="angemeldet"
                  minimal
                  intent={Intent.Success}
                />
              ) : (
                <TooltipTag
                  tooltip="Für diesen Genießer liegt die Zustimmung zum Newsletter-Versand NICHT vor"
                  label="abgemeldet"
                  minimal
                  intent={Intent.Danger}
                />
              )}
              {soul.gdpr_consent_source && (
                <TooltipTag
                  tooltip={`Erteilte/Widerrufene Zustimmung wurde ${
                    soul.gdpr_consent_source === GdprConsentSource.Employee
                      ? 'durch einen Mitarbeiter'
                      : 'vom Genießer selbstständig'
                  } registriert`}
                  label={
                    soul.gdpr_consent_source === GdprConsentSource.Employee ? 'durch Mitarbeiter' : 'selbstständig'
                  }
                  minimal
                  intent={Intent.None}
                  marginLeft
                />
              )}
            </PropertyListItem>
          )}
          <PropertyListItem label="Telefon" value={soul.phone || '—'} showValueDisabled={!soul.phone} />
          <PropertyListItem label="Mobil" value={soul.mobile || '—'} showValueDisabled={!soul.mobile} />
          <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
        </PropertyList>
        <PropertyListDivider />
        <PropertyList>
          <PropertyListHeader>Genießer Info</PropertyListHeader>
          <PropertyListItem
            label="Zielgruppe"
            value={getTargetGroupText(soul.meta.target_group)}
            showValueDisabled={soul.meta.target_group === SoulTargetGroup.None}
          />
          <PropertyListItem label="Besonderheiten">
            {soul.meta.buys_ticket && <TooltipIcon label="Selbstzahler" icon={IconNames.EURO} marginRight />}
            {soul.meta.needs_wheelchair && <TooltipIcon label="Rollstuhl" icon={IconNames.SHOPPING_CART} marginRight />}
            {soul.meta.needs_wheeled_walker && <TooltipIcon label="Rollator" icon={IconNames.WALK} marginRight />}
            {soul.meta.needs_blind && <TooltipIcon label="Blind" icon={IconNames.EYE_OFF} marginRight />}
            {soul.meta.needs_drive && (
              <TooltipIcon label="Muss von Zuhause abgeholt werden" icon={IconNames.DRIVE_TIME} marginRight />
            )}
            {soul.meta.needs_trainstation_fetch && (
              <TooltipIcon label="Muss vom Bahnhof abgeholt werden" icon={IconNames.TRAIN} marginRight />
            )}
          </PropertyListItem>
          <PropertyListItem label="Attribute">
            {!!soul.tags.length ? (
              soul.tags.map((tag) => <PersonTagIcon key={tag.id} tag={tag} />)
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
          <PropertyListItem
            label="Organisation"
            value={soul.organisation ? soul.organisation.name : '—'}
            showValueDisabled={!soul.organisation}
          />
          <PropertyListItem label="Interne Notizen">
            {soul.notes ? (
              <div className={[Classes.RUNNING_TEXT, Classes.TEXT_MUTED].join(' ')}>{soul.notes}</div>
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
        </PropertyList>
      </div>
    </ContentCard>
  );
};

SoulInfo.fragments = {
  soul: gql`
    fragment SoulInfo on Soul {
      id
      status
      display_name
      first_name
      last_name
      birthdate
      email
      gdpr_consent
      gdpr_consent_source
      phone
      mobile
      street
      city
      postal_code
      notes
      meta {
        target_group
        buys_ticket
        needs_wheelchair
        needs_wheeled_walker
        needs_drive
        needs_trainstation_fetch
        needs_blind
      }
      organisation {
        id
        name
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  `,
};

export default SoulInfo;
