import React from 'react';
import { HTMLSelect } from '@blueprintjs/core';
import { UserStatus } from '../generated/graphql';

type PersonStatusSelectProps = {
  value: UserStatus;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
};

const statusOptions = [
  {
    value: UserStatus.Active,
    label: 'Aktive',
  },
  {
    value: UserStatus.Passive,
    label: 'Passive',
  },
  {
    value: UserStatus.New,
    label: 'Neue',
  },
];

const PersonStatusSelect = ({ value, onChange }: PersonStatusSelectProps) => (
  <HTMLSelect className="mr-2" options={statusOptions} value={value} onChange={onChange} />
);

export default PersonStatusSelect;
