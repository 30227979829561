import { Button, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { useNavigate } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { OrganisationDetailPageFragment, useDeleteOrganisationMutation } from '../../../generated/graphql';
import { showFormErrorMessage } from '../../../helpers/graphql';

type OrganisationDetailMenuProps = {
  organisation: OrganisationDetailPageFragment;
  onEdit: () => void;
};

const OrganisationDetailMenu = ({ organisation, onEdit }: OrganisationDetailMenuProps) => {
  const navigate = useNavigate();
  const [deleteOrganisation, { loading: deleting }] = useDeleteOrganisationMutation({
    variables: {
      id: organisation.id,
    },
    onCompleted: () => {
      AppToaster.success(`${organisation.name}  wurde in den Papierkorb gelegt`);
      navigate(-1);
    },
    onError: showFormErrorMessage,
  });

  return (
    <>
      <Button text="Bearbeiten" icon={IconNames.EDIT} className="ml-2" onClick={onEdit} />
      <Popover2
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteOrganisation as any} />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} className="ml-2" loading={deleting} />
      </Popover2>
    </>
  );
};

export default OrganisationDetailMenu;
