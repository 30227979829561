import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import ErrorCard from './ErrorCard';
import { IProps } from '@blueprintjs/core';

type NotFoundCardProps = {
  resource: string;
} & IProps;

const NotFoundCard = ({ resource, ...otherProps }: NotFoundCardProps) => (
  <ErrorCard
    {...otherProps}
    icon={IconNames.INBOX_SEARCH}
    title="404"
    description={`${resource} konnte nicht gefunden werden!`}
  />
);

export default NotFoundCard;
