import { gql } from '@apollo/client';
import { Elevation, Intent, Tag } from '@blueprintjs/core';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import Text from '../../../components/Text';
import { EventStatus, useEventDetailQuery } from '../../../generated/graphql';
import AttendeeGroup from '../../attendees/components/AttendeeGroup';
import AttendeeListContainer from '../../attendees/components/AttendeeListContainer';
import AttendeeListItem from '../../attendees/components/AttendeeListItem';
import AttendeeLoneCompanion from '../../attendees/components/AttendeeLoneCompanion';
import AttendeeLoneSoul from '../../attendees/components/AttendeeLoneSoul';
import AttendeeTeam from '../../attendees/components/AttendeeTeam';
import EventCancelOverlay from '../components/EventCancelOverlay';
import EventCloneOverlay from '../components/EventCloneOverlay';
import EventDetailMenu from '../components/EventDetailMenu';
import EventEditOverlay from '../components/EventEditOverlay';
import EventInfo from '../components/EventInfo';
import EventStatusIcon from '../components/EventStatusIcon';
import SignupList from '../components/SignupList';
import SignupListItem from '../components/SignupListItem';
import SignupMenu from '../components/SignupMenu';

type RouteParams = {
  eventId?: string;
};

const EventDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState<boolean>(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);
  const { eventId } = useParams<RouteParams>();
  const navigate = useNavigate();
  const { data, loading, error } = useEventDetailQuery({
    variables: {
      id: eventId || '',
    },
  });
  const onCloned = (modelId: string) => {
    AppToaster.success('Veranstaltung hinzugefügt!');
    setIsCloneDialogOpen(false);
    navigate(`/events/${modelId}`);
  };
  const onCancelled = () => {
    AppToaster.success('Veranstaltung wurde abgesagt, alle Teilnehmer wurden informiert!');
    setIsCancelDialogOpen(false);
  };

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data?.event) return <NotFoundCard resource="Veranstaltung" />;

  const { event } = data;

  return (
    <>
      <EventEditOverlay id={event.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <EventCloneOverlay
        isOpen={isCloneDialogOpen}
        id={data.event.id}
        onSuccess={onCloned}
        onClose={() => setIsCloneDialogOpen(false)}
      />
      <EventCancelOverlay
        isOpen={isCancelDialogOpen}
        id={data.event.id}
        onSuccess={onCancelled}
        onClose={() => setIsCancelDialogOpen(false)}
      />
      <ContentCard elevation={Elevation.TWO} className="mb-5">
        <ContentCardHeader
          leftElement={
            <>
              <EventStatusIcon event={event} className="mr-2" />
              <Text large>{event.name}</Text>
              {event.status === EventStatus.Cancelled && (
                <Tag minimal intent={Intent.DANGER} className="ml-2">
                  Abgesagt
                </Tag>
              )}
              {event.is_archived && (
                <Tag minimal className="ml-2">
                  Archiviert
                </Tag>
              )}
            </>
          }
          rightElement={
            <EventDetailMenu
              event={event}
              onEdit={() => setIsEditDialogOpen(true)}
              onClone={() => setIsCloneDialogOpen(true)}
              onCancel={() => setIsCancelDialogOpen(true)}
            />
          }
        />
        <div className="flex py-4 px-5">
          <EventInfo event={event} />
        </div>
      </ContentCard>
      {event.status !== EventStatus.Draft &&
        event.status !== EventStatus.Ready &&
        event.status !== EventStatus.Cancelled && (
          <div className="flex">
            <div className="w-1/2 pr-2">
              <ContentCard elevation={Elevation.TWO} className="mb-5">
                <ContentCardHeader
                  leftElement={
                    <>
                      <Text large>Interessenten</Text>
                    </>
                  }
                  rightElement={<SignupMenu event={event} />}
                />
                <SignupList event={event} />
              </ContentCard>
            </div>
            <div className="w-1/2 pl-2">
              <AttendeeListContainer event={event} />
            </div>
          </div>
        )}
    </>
  );
};

EventDetailPage.fragments = {
  event: gql`
    fragment EventDetailPage on Event {
      id
      name
      status
      start
      end
      notes
      description
      description_html
      description_markdown
      taken_tickets
      available_tickets
      candidates_chosen
      ticket_type #Needed for update
      ticket_time
      ticket_retrieval_type
      ticket_retrieval_location
      reservation_number
      is_archived
      needsInvite @client
      candidateCount @client
      attendeeCount @client
      prospects @client {
        ...AttendeeListItem
      }
      candidates @client {
        ...AttendeeListItem
      }
      attendees @client {
        ...AttendeeListItem
      }
      groups @client {
        ...AttendeeGroup
      }
      teams @client {
        ...AttendeeTeam
      }
      loneSouls @client {
        ...AttendeeLoneSoul
      }
      loneCompanions @client {
        ...AttendeeLoneCompanion
      }
      cardholder {
        id
        display_name
        mobile
        phone
        email
      }
      signups {
        ...AttendeeListItem
        memberSignups {
          ...AttendeeListItem
        }
        leaderSignup {
          ...AttendeeListItem
        }
      }
      contact {
        id
        display_name
        mobile
        phone
        email
      }
      location {
        id
        name
        accessible
        meeting_point
        public_transport
        street
        postal_code
        city
      }
      categories {
        id
        name
        color
      }
    }
    ${SignupListItem.fragments.prospects}
    ${AttendeeListItem.fragments.attendees}
    ${AttendeeGroup.fragments.groups}
    ${AttendeeTeam.fragments.teams}
    ${AttendeeLoneSoul.fragments.loneSoul}
    ${AttendeeLoneCompanion.fragments.loneCompanion}
  `,
};

export default EventDetailPage;
