import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useLocationEditLazyQuery } from '../../../generated/graphql';
import LocationForm from './LocationForm';

interface ILocationEditOverlayProps extends IOverlayProps {
  id: string;
  onClose: () => void;
}

const LocationEditOverlay = ({ id, ...overlayProps }: ILocationEditOverlayProps) => {
  const [loadLocation, { data, called, loading, error }] = useLocationEditLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadLocation();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data?.location) return <ErrorCard />;
    return (
      <LocationForm
        onCancel={() => overlayProps.onClose?.()}
        onUpdated={() => {
          loadLocation();
          overlayProps.onClose?.();
        }}
        initialValues={data.location}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default LocationEditOverlay;
