import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { ProspectGroupListItemFragment } from '../../../generated/graphql';
import GroupMemberListItem from './GroupMemberListItem';

type GroupMemberListProps = {
  leaderSignup: ProspectGroupListItemFragment;
};

const GroupMemberList = ({ leaderSignup }: GroupMemberListProps) => (
  <HTMLTable condensed className="w-full">
    <colgroup>
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: 60 }} />
    </colgroup>
    <thead>
      <tr>
        <TableHeaderCell>Name</TableHeaderCell>
        <TableHeaderCell>Info</TableHeaderCell>
        <TableHeaderCell>Priorität</TableHeaderCell>
      </tr>
    </thead>
    <tbody>
      <GroupMemberListItem signup={leaderSignup} />
      {leaderSignup.memberSignups.map((memberSignup) => (
        <GroupMemberListItem key={memberSignup.id} signup={memberSignup} />
      ))}
    </tbody>
  </HTMLTable>
);

export default GroupMemberList;
