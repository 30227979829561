import { gql } from '@apollo/client';
import EmployeeEditForm from './components/EmployeeForm';
import EmployeeListItem from './components/EmployeeListItem';
import EmployeeInfo from './components/EmployeeInfo';
import EmailListItem from '../emails/components/EmailListItem';

export const EMPLOYEE_LIST_QUERY = gql`
  query EmployeeList($status: UserStatus, $page: Int, $trashed: Trashed) {
    employees(status: $status, first: 20, page: $page, trashed: $trashed) {
      data {
        ...EmployeeListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
  ${EmployeeListItem.fragments.employee}
`;

export const EMPLOYEE_DETAIL_QUERY = gql`
  query EmployeeDetail($id: ID!) {
    employee(id: $id) {
      id
      ...EmployeeInfo
    }
  }
  ${EmployeeInfo.fragments.employee}
`;

export const EMPLOYEE_EMAILS_QUERY = gql`
  query EmployeeEmails($id: ID!, $page: Int) {
    employee(id: $id) {
      id
      emails(first: 10, page: $page) {
        data {
          ...EmailListItem
        }
        paginatorInfo {
          currentPage
          hasMorePages
          lastPage
        }
      }
    }
  }
  ${EmailListItem.fragments.emails}
`;

export const EMPLOYEE_EDIT_QUERY = gql`
  query EmployeeEdit($id: ID!) {
    employee(id: $id) {
      id
      ...EmployeeEditForm
    }
  }
  ${EmployeeEditForm.fragments.edit}
`;

export const CREATE_EMPLOYEE_MUTATION = gql`
  mutation CreateEmployee($input: CreateEmployeeInput!) {
    createEmployee(input: $input) {
      ...EmployeeInfo
    }
  }
  ${EmployeeInfo.fragments.employee}
`;

export const UPDATE_EMPLOYEE_MUTATION = gql`
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      ...EmployeeInfo
    }
  }
  ${EmployeeInfo.fragments.employee}
`;

export const DELETE_EMPLOYEE_MUTATION = gql`
  mutation DeleteEmployee($id: ID!) {
    deleteEmployee(id: $id) {
      id
    }
  }
`;

export const RESTORE_EMPLOYEES_MUTATION = gql`
  mutation RestoreEmployees($ids: [ID!]!) {
    restoreEmployees(ids: $ids) {
      id
    }
  }
`;

export const FORCE_DELETE_EMPLOYEES_MUTATION = gql`
  mutation ForceDeleteEmployees($ids: [ID!]!) {
    forceDeleteEmployees(ids: $ids) {
      id
    }
  }
`;

export const EMPTY_EMPLOYEE_TRASH_MUTATION = gql`
  mutation EmptyEmployeeTrash {
    emptyEmployeeTrash {
      id
    }
  }
`;
