import { FetchResult, gql } from '@apollo/client';
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import RichTextInput, { decorator, entityToHTML, htmlToEntity } from '../../../components/RichTextInput';
import SelectInput from '../../../components/SelectInput';
import Text from '../../../components/Text';
import TextInput from '../../../components/TextInput';
import {
  CreateNewsletterInput,
  CreateNewsletterMutation,
  MailcoachSegment,
  NewsletterCreateFormFragment,
  useCreateNewsletterMutation,
} from '../../../generated/graphql';
import { NEWSLETTER_SEGMENT_OPTIONS } from '../../../helpers/constants';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import NewsletterEventList from './NewsletterEventList';

const validationSchema = Yup.object({
  subject: Yup.string().required('Erforderlich'),
});

type NewsletterCreateFormValues = Omit<NewsletterCreateFormFragment, 'body' | 'events'> & {
  body: EditorState;
  events: string[];
};

type NewsletterFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateNewsletterMutation) => void;
  initialValues: NewsletterCreateFormFragment;
};

const buildCreateInput = ({
  events,
  body,
  segment,
  ...otherValues
}: NewsletterCreateFormValues): CreateNewsletterInput => ({
  ...otherValues,
  segment: segment || MailcoachSegment.Active,
  body: convertToHTML({ entityToHTML })(body.getCurrentContent()),
  ...(!!events.length && {
    events: {
      sync: events,
    },
  }),
});

const NewsletterForm = ({ onCancel, onCreated, initialValues }: NewsletterFormProps) => {
  const [createNewsletter] = useCreateNewsletterMutation();
  const handleSubmit = useSubmit<NewsletterCreateFormValues, FetchResult<CreateNewsletterMutation>>({
    mutate: (values) =>
      createNewsletter({
        variables: {
          input: buildCreateInput(values),
        },
      }),
    onCompleted: (res) => {
      if (!res.data) return;
      return onCreated?.(res.data);
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik
      initialValues={{
        ...initialValues,
        events: initialValues.events.map((event) => event.id),
        body: EditorState.createWithContent(convertFromHTML({ htmlToEntity })(initialValues.body), decorator),
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, setFieldValue, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<Text large>Newsletter versenden</Text>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup
              label="Titel"
              labelInfo="(erforderlich)"
              name="subject"
              helperText="Ist gleichzeitig Email Betreff"
            >
              <TextInput name="subject" placeholder="Betreff" />
            </FormGroup>
            <FormGroup label="Empfänger" name="segment">
              <SelectInput name="segment" options={NEWSLETTER_SEGMENT_OPTIONS} />
            </FormGroup>
            <FormGroup
              label="Nachricht"
              labelInfo="(erforderlich)"
              name="body"
              helperText="Ohne Anrede oder Grußformeln"
            >
              <RichTextInput name="body" />
            </FormGroup>

            <Divider className="my-6 -mx-5" />
            <FormGroup
              label="Veranstaltungen"
              name="events"
              helperText="Veranstaltungen, die mit dem Newsletter veröffentlicht werden sollen"
            >
              <ContentCard elevation={Elevation.ONE}>
                <NewsletterEventList onChange={(selectedEvents) => setFieldValue('events', selectedEvents)} />
              </ContentCard>
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <>
                <Button text="Abbrechen" onClick={onCancel} className="ml-2" />
                <Button
                  text="Senden"
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  className="ml-2"
                />
              </>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

NewsletterForm.fragments = {
  // This is only used for Formik initialValues
  create: gql`
    fragment NewsletterCreateForm on Newsletter {
      subject
      body
      segment
      events {
        id
      }
    }
  `,
};

export default NewsletterForm;
