import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { ContactCreateFormFragment } from '../../../generated/graphql';
import ContactForm from './ContactForm';

type ContactCreateOverlayProps = {
  donorId: string;
  onSuccess: (modelId: string) => void;
  onClose: () => void;
} & IOverlayProps;

const ContactCreateOverlay = ({ donorId, onSuccess, ...overlayProps }: ContactCreateOverlayProps) => {
  const initialValues: ContactCreateFormFragment = {
    first_name: '',
    last_name: '',
    email: '',
    donor_id: donorId,
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <ContactForm
        onCancel={closeOverlay}
        onCreated={({ createContact }) => {
          onSuccess(createContact.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default ContactCreateOverlay;
