import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import Text from '../../../components/Text';
import LocationCreateOverlay from '../components/LocationCreateOverlay';
import LocationList from '../components/LocationList';

const LocationListPage = () => {
  const navigate = useNavigate();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const onCreated = (modelId: string) => {
    AppToaster.success('Veranstaltungsort hinzugefügt!');
    navigate(`/locations/${modelId}`);
  };

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <LocationCreateOverlay
        isOpen={isCreateDialogOpen}
        onSuccess={onCreated}
        onClose={() => setIsCreateDialogOpen(false)}
      />
      <ContentCardHeader
        leftElement={
          <Text large className="mr-2">
            Veranstaltungsorte
          </Text>
        }
        rightElement={
          <>
            <Button
              text="Hinzufügen"
              icon={IconNames.ADD}
              intent={Intent.PRIMARY}
              className="ml-2"
              onClick={() => setIsCreateDialogOpen(true)}
            />
          </>
        }
      />
      <LocationList />
    </ContentCard>
  );
};

export default LocationListPage;
