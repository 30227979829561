import React from 'react';
import { NonIdealState } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';

export interface NoResultsProps {
  icon?: IconName;
  title?: string;
  description?: string;
  action?: JSX.Element;
}

const NoResults = (props: NoResultsProps) => (
  <div className="text-center py-10">
    <NonIdealState {...props} />
  </div>
);

NoResults.defaultProps = {
  title: 'Keine Resultate',
  icon: IconNames.INBOX_SEARCH,
  description: 'Für die gesetzten Filter.',
} as Partial<NoResultsProps>;

export default NoResults;
