import { Intent, Tag } from '@blueprintjs/core';
import React from 'react';
import { UserStatus } from '../generated/graphql';

type PersonStatusTagProps = {
  status: UserStatus;
};

const PersonStatusTag = ({ status }: PersonStatusTagProps) => {
  switch (status) {
    case UserStatus.New:
      return (
        <Tag className="ml-1" intent={Intent.PRIMARY}>
          Neu
        </Tag>
      );
    case UserStatus.Passive:
      return (
        <Tag className="ml-1" intent={Intent.WARNING}>
          Passiv
        </Tag>
      );
    default:
      return null;
  }
};

export default PersonStatusTag;
