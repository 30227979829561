import { gql } from '@apollo/client';
import React from 'react';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { RoleListItemFragment } from '../../../generated/graphql';

export type RoleListProps = {
  item: RoleListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

const RoleListItem = ({ item, skeleton, onClick }: RoleListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <Text skeleton={skeleton}>{item.name}</Text>
    </TableCell>
    <TableCell>
      {item.permissions.map((permission) => (
        <Text key={permission.id} skeleton={skeleton}>{`${permission.title}, `}</Text>
      ))}
    </TableCell>
  </tr>
);

RoleListItem.fragments = {
  role: gql`
    fragment RoleListItem on Role {
      id
      name
      permissions {
        id
        title
      }
    }
  `,
};

export default RoleListItem;
