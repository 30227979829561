import { CategoryStatsFragment, SoulTargetGroup, TargetGroupStatsFragment } from '../../generated/graphql';

export const dummyTargetGroupStats: TargetGroupStatsFragment[] = [
  {
    target_group: SoulTargetGroup.Disabled,
    data: {
      signups: 100,
      attends: 90,
      previous_signups: 120,
      previous_attends: 100,
    },
  },
  {
    target_group: SoulTargetGroup.Child,
    data: {
      signups: 100,
      attends: 90,
      previous_signups: 120,
      previous_attends: 100,
    },
  },
  {
    target_group: SoulTargetGroup.Immigrant,
    data: {
      signups: 100,
      attends: 90,
      previous_signups: 120,
      previous_attends: 100,
    },
  },
  {
    target_group: SoulTargetGroup.Senior,
    data: {
      signups: 100,
      attends: 90,
      previous_signups: 120,
      previous_attends: 100,
    },
  },
  {
    target_group: SoulTargetGroup.SociallyInNeed,
    data: {
      signups: 100,
      attends: 90,
      previous_signups: 120,
      previous_attends: 100,
    },
  },
  {
    target_group: SoulTargetGroup.None,
    data: {
      signups: 100,
      attends: 90,
      previous_signups: 120,
      previous_attends: 100,
    },
  },
];

export const dummyCategoryStats: CategoryStatsFragment[] = [
  {
    category: {
      id: '1',
      name: 'Test Kategorie',
      color: '#ff0000',
    },
    data: {
      tickets: 90,
      signups: 100,
      previous_tickets: 100,
      previous_signups: 120,
    },
  },
  {
    category: {
      id: '2',
      name: 'Test Kategorie',
      color: '#ff0000',
    },
    data: {
      tickets: 90,
      signups: 100,
      previous_tickets: 100,
      previous_signups: 120,
    },
  },
  {
    category: {
      id: '3',
      name: 'Test Kategorie Long',
      color: '#ff0000',
    },
    data: {
      tickets: 90,
      signups: 100,
      previous_tickets: 100,
      previous_signups: 120,
    },
  },
  {
    category: {
      id: '4',
      name: 'Test',
      color: '#ff0000',
    },
    data: {
      tickets: 90,
      signups: 100,
      previous_tickets: 100,
      previous_signups: 120,
    },
  },
  {
    category: {
      id: '5',
      name: 'Test asdf',
      color: '#ff0000',
    },
    data: {
      tickets: 90,
      signups: 100,
      previous_tickets: 100,
      previous_signups: 120,
    },
  },
  {
    category: {
      id: '6',
      name: 'Testd fdf asdff',
      color: '#ff0000',
    },
    data: {
      tickets: 90,
      signups: 100,
      previous_tickets: 100,
      previous_signups: 120,
    },
  },
];
