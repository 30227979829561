import { Intent } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { EmailStatus, SoulTargetGroup, MailcoachSegment, NewsletterStatus } from '../generated/graphql';

export const getTargetGroupText = (targetGroup: SoulTargetGroup) => {
  switch (targetGroup) {
    case SoulTargetGroup.Disabled:
      return 'Behinderung';
    case SoulTargetGroup.Child:
      return 'Kind';
    case SoulTargetGroup.Immigrant:
      return 'Migrationshintergrund';
    case SoulTargetGroup.Senior:
      return 'Senior';
    case SoulTargetGroup.SociallyInNeed:
      return 'Sozial bedürftig';
    case SoulTargetGroup.None:
      return '—';
  }
};

export enum SignupStatus {
  PROSPECT = 'prospect',
  CANDIDATE = 'candidate',
  NO_EMAIL = 'no_email',
  EMAIL_SENT = 'email_sent',
  EMAIL_OPENED = 'email_opened',
  EMAIL_REJECTED = 'email_rejected',
  EMAIL_ADDRESS_INVALID = 'email_address_invalid',
  EMAIL_UNKNOWN_ERROR = 'email_unknown_error',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

type EmailSkeleton = {
  status: EmailStatus;
};

type PersonSkeleton = {
  email?: string | null;
};

type SignupSkeleton = {
  is_invited: boolean;
  is_candidate: boolean;
  has_accepted: boolean;
  has_rejected: boolean;
  emails: EmailSkeleton[];
  soul?: PersonSkeleton | null;
  companion?: PersonSkeleton | null;
};

type EmailStatusMap = Record<EmailStatus, { label: string; icon: IconName; intent?: Intent }>;

export const emailStatusMap: EmailStatusMap = {
  [EmailStatus.Scheduled]: {
    label: 'Geplant',
    icon: IconNames.CALENDAR,
  },
  [EmailStatus.Queued]: {
    label: 'In Warteschlange',
    icon: IconNames.TIME,
  },
  [EmailStatus.Delayed]: {
    label: 'In Zustellung...',
    icon: IconNames.TIME,
  },
  [EmailStatus.Sent]: {
    label: 'Versandt',
    icon: IconNames.ENVELOPE,
  },
  [EmailStatus.Delivered]: {
    label: 'Zugestellt',
    icon: IconNames.INBOX,
    intent: Intent.SUCCESS,
  },
  [EmailStatus.Opened]: {
    label: 'Geöffnet',
    icon: IconNames.EYE_ON,
    intent: Intent.SUCCESS,
  },
  [EmailStatus.Rejected]: {
    label: 'Abgewiesen: ',
    icon: IconNames.HAND,
    intent: Intent.DANGER,
  },
  [EmailStatus.MarkedSpam]: {
    label: 'Als SPAM markiert!',
    icon: IconNames.THUMBS_DOWN,
    intent: Intent.DANGER,
  },
  [EmailStatus.HardBounced]: {
    label: 'Zustellung gescheitert!',
    icon: IconNames.ERROR,
    intent: Intent.DANGER,
  },
  [EmailStatus.SoftBounced]: {
    label: 'Zustellung gescheitert!',
    icon: IconNames.ERROR,
    intent: Intent.DANGER,
  },
  [EmailStatus.Invalid]: {
    label: 'Unbekannter Fehler',
    icon: IconNames.WARNING_SIGN,
    intent: Intent.WARNING,
  },
  [EmailStatus.Unknown]: {
    label: 'Unbekannter Fehler',
    icon: IconNames.WARNING_SIGN,
    intent: Intent.WARNING,
  },
};

export const getMailcoachSegmentText = (segment: MailcoachSegment | null | undefined) => {
  switch (segment) {
    case MailcoachSegment.Active:
      return 'Aktive Genießer & Begleiter';
    case MailcoachSegment.Souls:
      return 'Aktive Genießer';
    case MailcoachSegment.Companions:
      return 'Aktive Begleiter';
    case MailcoachSegment.All:
    default:
      return 'Alle (auch inaktive) Genießer & Begleiter';
  }
};

export const getNewsletterStatusText = (segment: NewsletterStatus) => {
  switch (segment) {
    case NewsletterStatus.Draft:
      return 'Entwurf';
    case NewsletterStatus.Sending:
      return 'Wird versendet...';
    case NewsletterStatus.Sent:
      return 'Versandt';
    default:
      return 'Unbekannt';
  }
};

export const getEmailStatus = (emails: EmailSkeleton[]): EmailStatus =>
  emails.reduce<EmailStatus>((acc, email) => {
    if (acc === EmailStatus.Opened) {
      return acc;
    }
    return email.status;
  }, EmailStatus.Queued);

export const getSignupStatus = ({
  is_candidate,
  is_invited,
  has_accepted,
  has_rejected,
  emails,
  soul,
  companion,
}: SignupSkeleton) => {
  if (!is_candidate) {
    return SignupStatus.PROSPECT;
  }

  if (!is_invited) {
    return SignupStatus.CANDIDATE;
  }

  if (has_accepted) {
    return SignupStatus.ACCEPTED;
  }

  if (has_rejected) {
    return SignupStatus.REJECTED;
  }

  if (!soul?.email && !companion?.email) {
    return SignupStatus.NO_EMAIL;
  }

  switch (getEmailStatus(emails)) {
    case EmailStatus.Opened:
      return SignupStatus.EMAIL_OPENED;
    case EmailStatus.Queued:
    case EmailStatus.Scheduled:
    case EmailStatus.Sent:
    case EmailStatus.Delivered:
    case EmailStatus.Delayed:
      return SignupStatus.EMAIL_SENT;
    case EmailStatus.MarkedSpam:
    case EmailStatus.SoftBounced:
    case EmailStatus.HardBounced:
    case EmailStatus.Rejected:
      return SignupStatus.EMAIL_REJECTED;
    case EmailStatus.Invalid:
      return SignupStatus.EMAIL_ADDRESS_INVALID;
    case EmailStatus.Unknown:
      return SignupStatus.EMAIL_UNKNOWN_ERROR;
  }
};
