import { Button, Classes, Intent } from '@blueprintjs/core';
import React from 'react';
import { useFilterContext } from './Filter';
import Text from './Text';

const FilterHeader = () => {
  const { resetFilters, commitFilters } = useFilterContext();

  return (
    <div className="flex justify-between items-center p-2 bg-light-gray-500">
      <Button text="Reset" small onClick={resetFilters} />
      <Text className="px-12">Filter</Text>
      <Button text="Fertig" small intent={Intent.PRIMARY} onClick={commitFilters} className={Classes.POPOVER_DISMISS} />
    </div>
  );
};

export default FilterHeader;
