import { DateRangeShortcut } from '@blueprintjs/datetime';
import {
  addDays,
  addMonths,
  addYears,
  differenceInYears,
  endOfDay,
  endOfMonth,
  format,
  formatDistanceStrict,
  formatDistanceToNow,
  isFuture,
  isPast,
  isValid,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { de } from 'date-fns/locale';

export const defaultMinDate = new Date('2014-01-01'); // Allows us to edit the very first of all dates in the system
export const defaultMaxDate = addYears(new Date(), 5);

export const formatToNowStrict = (str: string) => {
  const date = parseISO(str);
  if (!isValid(date)) {
    console.error('Invalid date: ', str);
    return '';
  }
  return formatDistanceStrict(date, new Date(), { locale: de, addSuffix: true });
};

export const formatToNow = (str: string) => {
  const date = parseISO(str);
  if (!isValid(date)) {
    console.error('Invalid date: ', str);
    return '';
  }
  return formatDistanceToNow(date, { locale: de, addSuffix: true });
};

export const formatDateTime = (str: string, pattern: string) => {
  const date = parseISO(str);
  if (!isValid(date)) {
    console.error('Invalid date: ', str);
    return '';
  }
  return format(date, pattern);
};

export const convertGermanToIntl = (german: string) => {
  const date = parse(german, 'dd.MM.yyyy', new Date());
  if (!isValid(date)) {
    console.error('Invalid date: ', german);
    return '';
  }
  return format(date, 'yyyy-MM-dd');
};

export const isInPast = (str: string) => {
  const date = parseISO(str);
  if (!isValid(date)) {
    console.error('Invalid date: ', str);
    return false;
  }
  return isPast(date);
};

export const isInFuture = (str: string) => {
  const date = parseISO(str);
  if (!isValid(date)) {
    console.error('Invalid date: ', str);
    return false;
  }
  return isFuture(date);
};

export const getAgeInYears = (str: string) => {
  const date = parseISO(str);
  if (!isValid(date)) {
    console.error('Invalid date: ', str);
    return 0;
  }
  return differenceInYears(new Date(), date);
};

export const formatDay = (str: string) => formatDateTime(str, 'dd.MM.yyyy');

export const formatTime = (str: string) => formatDateTime(str, 'HH:mm');

export const formatDayAndTime = (str: string) => formatDateTime(str, 'dd.MM.yyyy HH:mm');

const now = new Date();
const todayBOD = startOfDay(now);
const todayEOD = endOfDay(now);

export const PastDateTimeShortcuts: DateRangeShortcut[] = [
  {
    dateRange: [subDays(todayBOD, 1), subDays(todayEOD, 1)],
    label: 'Gestern',
  },
  {
    dateRange: [subDays(todayBOD, 2), subDays(todayEOD, 2)],
    label: 'Vorgestern',
  },
  {
    dateRange: [subDays(todayBOD, 7), subDays(todayEOD, 1)],
    label: 'Letzte 7 Tage',
  },
  {
    dateRange: [subDays(todayBOD, 14), subDays(todayEOD, 1)],
    label: 'Letzte 14 Tage',
  },
  {
    dateRange: [subDays(todayBOD, 30), subDays(todayEOD, 1)],
    label: 'Letzte 30 Tage',
  },
  {
    dateRange: [startOfMonth(subMonths(todayBOD, 1)), endOfMonth(subMonths(todayEOD, 1))],
    label: `Ganzer ${format(subMonths(todayBOD, 1), 'MMMM', { locale: de })}`,
  },
  {
    dateRange: [startOfMonth(subMonths(todayBOD, 2)), endOfMonth(subMonths(todayEOD, 2))],
    label: `Ganzer ${format(subMonths(todayBOD, 2), 'MMMM', { locale: de })}`,
  },
];

export const FutureDateTimeShortcuts: DateRangeShortcut[] = [
  {
    dateRange: [todayBOD, todayEOD],
    label: 'Heute',
  },
  {
    dateRange: [addDays(todayBOD, 1), addDays(todayEOD, 1)],
    label: 'Morgen',
  },
  {
    dateRange: [todayBOD, addDays(todayEOD, 7)],
    label: 'Nächste 7 Tage',
  },
  {
    dateRange: [todayBOD, addDays(todayEOD, 14)],
    label: 'Nächste 14 Tage',
  },
  {
    dateRange: [todayBOD, addDays(todayEOD, 30)],
    label: 'Nächste 30 Tage',
  },
  {
    dateRange: [startOfMonth(todayBOD), endOfMonth(todayEOD)],
    label: `Ganzer ${format(todayBOD, 'MMMM', { locale: de })}`,
  },
  {
    dateRange: [startOfMonth(addMonths(todayBOD, 1)), endOfMonth(addMonths(todayEOD, 1))],
    label: `Ganzer ${format(addMonths(todayBOD, 1), 'MMMM', { locale: de })}`,
  },
];
