import { Button, Callout, Card, Intent } from '@blueprintjs/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import ErrorCard from '../../../components/ErrorCard';
import FormGroup from '../../../components/FormGroup';
import LoadingCard from '../../../components/LoadingCard';
import ResourceLink from '../../../components/ResourceLink';
import Text from '../../../components/Text';
import TextInput from '../../../components/TextInput';
import { ForgotPasswordInput, useCurrentTenantQuery } from '../../../generated/graphql';
import { setPersisted } from '../../../helpers/constants';

type EmailFormProps = {
  onSubmit: (values: ForgotPasswordInput, formikHelpers: FormikHelpers<ForgotPasswordInput>) => void;
};

const ResetSchema = Yup.object({
  email: Yup.string().email('Keine gültige Email').required('Erforderlich'),
});

const EmailForm = ({ onSubmit }: EmailFormProps) => {
  const { data, loading, error } = useCurrentTenantQuery({
    onCompleted: ({ currentTenant }) => {
      setPersisted('useLegacyNewsletter', currentTenant.use_legacy_newsletter);
    },
  });

  if (loading) return <LoadingCard />;
  if (error || !data?.currentTenant) return <ErrorCard />;

  const initialValues: ForgotPasswordInput = {
    email: '',
  };

  return (
    <Formik initialValues={initialValues} validationSchema={ResetSchema} onSubmit={onSubmit}>
      {({ isSubmitting, status }) => (
        <>
          <div className="mb-5">
            <Text large>{data.currentTenant?.name}</Text>
          </div>
          <Card>
            {status && status.error && (
              <Callout intent={Intent.DANGER} className="mb-4">
                {status.error}
              </Callout>
            )}
            <Form>
              <FormGroup label="Email" labelFor="email" name="email">
                <TextInput name="email" type="email" placeholder="Email" />
              </FormGroup>
              <Button type="submit" intent={Intent.SUCCESS} text="Passwort zurücksetzen" loading={isSubmitting} fill />
              <div className="pt-4 float-right">
                <ResourceLink path="/auth/login" text="Zurück zum Login" muted />
              </div>
            </Form>
          </Card>
        </>
      )}
    </Formik>
  );
};

export default EmailForm;
