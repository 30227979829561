import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';

type SettingsDetailMenuProps = {
  onEdit: () => void;
};

const SettingsDetailMenu = ({ onEdit }: SettingsDetailMenuProps) => (
  <Button text="Bearbeiten" icon={IconNames.EDIT} className="ml-2" onClick={onEdit} />
);

export default SettingsDetailMenu;
