import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useRoleEditLazyQuery } from '../../../generated/graphql';
import RoleForm from './RoleForm';

interface IRoleEditOverlayProps extends IOverlayProps {
  id: string;
  onClose: () => void;
}

const RoleEditOverlay = ({ id, ...overlayProps }: IRoleEditOverlayProps) => {
  const [loadRole, { data, called, loading, error }] = useRoleEditLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadRole();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data?.role) return <ErrorCard />;
    return (
      <RoleForm
        onCancel={() => overlayProps.onClose?.()}
        onUpdated={() => {
          loadRole();
          overlayProps.onClose?.();
        }}
        initialValues={data.role}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default RoleEditOverlay;
