import { IconName } from '@blueprintjs/core';

/**
 * Allow all values to be `null`.
 */
export type Nullable<T> = { [K in keyof T]: T[K] | null };

export enum PersonType {
  Soul = 'Soul',
  Companion = 'Companion',
  Employee = 'Employee',
  Contact = 'Contact',
}

export enum ItemClass {
  Categories = 'Categories',
  Companions = 'Companions',
  Contacts = 'Contacts',
  Donors = 'Donors',
  Employees = 'Employees',
  Events = 'Events',
  Locations = 'Locations',
  // Newsletters = 'Newsletters',
  Organisations = 'Organisations',
  Souls = 'Souls',
}

export interface ListComponent {
  isSelected?: (id: string) => boolean;
  onToggle?: (id: string) => void;
  trash?: boolean;
}

export interface ListItemComponent {
  selected?: boolean;
  onToggle?: (id: string) => void;
}

export type UserHit = {
  id: number;
  first_name: string;
  last_name: string;
  display_name: string;
};

export type DonorHit = {
  id: number;
  name: string;
};

export type LocationHit = {
  id: number;
  name: string;
};

export type IconOption = {
  id: IconName;
};
