import { Button, ButtonGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { ContentCardFooter } from './ContentCard';
import Text from './Text';

type PaginationBarProps = {
  loading: boolean;
  empty: boolean;
  currentPage?: number;
  lastPage?: number;
  hasMorePages?: boolean;
  onPrev: () => void;
  onNext: () => void;
};

const PaginationBar = ({ loading, empty, currentPage, lastPage, hasMorePages, onPrev, onNext }: PaginationBarProps) => (
  <ContentCardFooter
    leftElement={
      !!currentPage &&
      !!lastPage && (
        <Text muted>
          Seite {currentPage} von {lastPage}
        </Text>
      )
    }
    rightElement={
      <ButtonGroup>
        <Button icon={IconNames.ARROW_LEFT} disabled={empty || currentPage === 1} loading={loading} onClick={onPrev} />
        <Button icon={IconNames.ARROW_RIGHT} disabled={empty || !hasMorePages} loading={loading} onClick={onNext} />
      </ButtonGroup>
    }
  />
);

export default PaginationBar;
