import { OrganisationListItemFragment } from '../../generated/graphql';

export const dummyOrganisations: OrganisationListItemFragment[] = [
  {
    id: '1',
    name: 'Normal Organisation Name',
  },
  {
    id: '2',
    name: 'Normal Orga',
  },
  {
    id: '3',
    name: 'Normal Organisation Name dfd',
  },
  {
    id: '4',
    name: 'Normal Organisation',
  },
];
