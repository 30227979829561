import { HTMLTable } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { OrganisationDetailPageFragment } from '../../../generated/graphql';
import OrganisationSoulListItem from './OrganisationSoulListItem';

type OrganisationSoulListProps = {
  souls: OrganisationDetailPageFragment['souls'];
};

const OrganisationSoulList = ({ souls }: OrganisationSoulListProps) => {
  const navigate = useNavigate();

  return (
    <HTMLTable interactive condensed className="w-full">
      <thead>
        <tr>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Besonderheiten</TableHeaderCell>
        </tr>
      </thead>
      <tbody>
        {souls.map((soul) => (
          <OrganisationSoulListItem key={soul.id} item={soul} onClick={() => navigate(`/souls/${soul.id}`)} />
        ))}
      </tbody>
    </HTMLTable>
  );
};

export default OrganisationSoulList;
