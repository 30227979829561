import { gql } from '@apollo/client';
import React from 'react';
import PersonTagIcon from '../../../components/PersonTagIcon';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { TagListItemFragment } from '../../../generated/graphql';

export type TagListProps = {
  item: TagListItemFragment;
  onClick?: () => void;
  skeleton?: boolean;
};

const TagListItem = ({ item, skeleton, onClick }: TagListProps) => (
  <tr onClick={onClick}>
    <TableCell>
      <PersonTagIcon tag={item} skeleton={skeleton} />
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{item.name}</Text>
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{item.description}</Text>
    </TableCell>
  </tr>
);

TagListItem.fragments = {
  tag: gql`
    fragment TagListItem on Tag {
      id
      name
      icon
      intent
      description
    }
  `,
};

export default TagListItem;
