import { Alert, Button, Intent, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { RoleDetailPageFragment, useDeleteRoleMutation } from '../../../generated/graphql';
import { showErrorMessage } from '../../../helpers/graphql';

type RoleDetailMenuProps = {
  role: RoleDetailPageFragment;
  onEdit: () => void;
};

const RoleDetailMenu = ({ role, onEdit }: RoleDetailMenuProps) => {
  const navigate = useNavigate();
  const [isForceDeleteAlertOpen, setIsForceDeleteAlertOpen] = useState(false);
  const [deleteRole, { loading: deleting }] = useDeleteRoleMutation({
    variables: {
      id: role.id,
    },
    onCompleted: () => {
      AppToaster.success(`${role.name} wurde entfernt`);
      navigate(-1);
    },
    onError: showErrorMessage,
  });

  return (
    <>
      <Alert
        isOpen={isForceDeleteAlertOpen}
        cancelButtonText="Abbrechen"
        confirmButtonText="Entfernen"
        icon={IconNames.DELETE}
        intent={Intent.DANGER}
        onClose={() => setIsForceDeleteAlertOpen(false)}
        onConfirm={deleteRole as any}
      >
        <p>Soll diese Rolle wirklich undwiderruflich entfernt werden?</p>
      </Alert>
      <Button text="Bearbeiten" icon={IconNames.EDIT} className="ml-2" onClick={onEdit} />
      <Popover2
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem
              text="Entfernen"
              icon={IconNames.DELETE}
              intent={Intent.DANGER}
              onClick={() => setIsForceDeleteAlertOpen(true)}
            />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} className="ml-2" loading={deleting} />
      </Popover2>
    </>
  );
};

export default RoleDetailMenu;
