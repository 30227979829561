import { Intent, Tag, Classes } from '@blueprintjs/core';
import React from 'react';

type ChangeTagProps = {
  now: number;
  prev: number;
  skeleton?: boolean;
};

const ChangeTag = ({ now, prev, skeleton }: ChangeTagProps) => {
  const skeletonClassName = skeleton ? Classes.SKELETON : '';

  if (now === prev) {
    return (
      <Tag minimal round className={skeletonClassName}>
        —
      </Tag>
    );
  }

  if (now > prev) {
    return (
      <Tag minimal round intent={Intent.SUCCESS} className={skeletonClassName}>
        + {prev === 0 ? '∞' : Math.round(((now - prev) / prev) * 100)}%
      </Tag>
    );
  }

  return (
    <Tag minimal round intent={Intent.DANGER} className={skeletonClassName}>
      - {prev === 0 ? '∞' : Math.round(((prev - now) / prev) * 100)}%
    </Tag>
  );
};

export default ChangeTag;
