import { Button, ButtonProps } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { ChromePicker } from 'react-color';
import { useFocusOnError } from '../helpers/forms';

interface ColorInputProps extends ButtonProps {
  name: string;
}

const ColorInput = ({ name, ...props }: ColorInputProps) => {
  const [field] = useField(name);
  const fieldRef = useRef<ChromePicker>(null);
  const formik = useFormikContext<any>();
  useFocusOnError({ fieldRef, name });

  return (
    <Popover2
      disabled={props.disabled || formik.isSubmitting}
      content={
        <ChromePicker
          ref={fieldRef}
          color={field.value}
          onChangeComplete={(color) => formik.setFieldValue(name, color.hex)}
          disableAlpha
          styles={{
            default: {
              picker: {
                boxShadow: 'none !important',
              },
            },
          }}
        />
      }
    >
      <Button
        {...props}
        minimal
        disabled={props.disabled || formik.isSubmitting}
        style={{ backgroundColor: field.value }}
      />
    </Popover2>
  );
};

export default ColorInput;
