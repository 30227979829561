import { gql } from '@apollo/client';
import { Colors, Elevation, HTMLTable, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { GroupType } from '../types';
import ProspectGroupListItem from './ProspectGroupListItem';
import ProspectListItem from './ProspectListItem';

type ProspectListProps = {
  soulSignupIds: string[];
  companionSignupIds: string[];
  groupSignupData: GroupType[];
  addLoneSoul: (soulSignupId: string, buysTicket: boolean) => void;
  addLoneCompanion: (companionSignupId: string) => void;
  addTeam: (companionSignupId: string, soulSignupId: string, soulBuysTicket: boolean) => void;
  addGroup: (companionSignupId: string, soulSignupIds: string[]) => void;
};

const ListWrapper = ({ children, isGroup }: { children: React.ReactNode; isGroup?: boolean }) => (
  <HTMLTable condensed className="w-full" interactive>
    <colgroup>
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: '50%' }} />
      <col span={1} style={{ width: 60 }} />
    </colgroup>
    <thead>
      <tr>
        <TableHeaderCell>Name</TableHeaderCell>
        <TableHeaderCell>Info</TableHeaderCell>
        <TableHeaderCell>{isGroup ? 'Personen' : 'Priorität'}</TableHeaderCell>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </HTMLTable>
);

const ProspectList = ({
  groupSignupData,
  soulSignupIds,
  companionSignupIds,
  addLoneSoul,
  addLoneCompanion,
  addTeam,
  addGroup,
}: ProspectListProps) => (
  <>
    <ContentCard elevation={Elevation.TWO} className="overflow-hidden p-0 mb-3">
      <ContentCardHeader
        leftElement={
          <>
            <Icon icon={IconNames.HOME} color={Colors.GRAY1} className="mr-1" />
            Angemeldete Gruppen
          </>
        }
      />
      <ListWrapper isGroup>
        {groupSignupData.map((group) => (
          <ProspectGroupListItem key={group.companion} id={group.companion} addGroup={addGroup} />
        ))}
      </ListWrapper>
    </ContentCard>
    <ContentCard elevation={Elevation.TWO} className="overflow-hidden p-0 mb-3">
      <ContentCardHeader
        leftElement={
          <>
            <Icon icon={IconNames.HEART} color={Colors.GRAY1} className="mr-1" />
            Angemeldete Genießer
          </>
        }
      />
      <ListWrapper>
        {soulSignupIds.map((soulSignupId) => (
          <ProspectListItem
            key={soulSignupId}
            id={soulSignupId}
            addLoneSoul={addLoneSoul}
            addLoneCompanion={addLoneCompanion}
            addTeam={addTeam}
          />
        ))}
      </ListWrapper>
    </ContentCard>
    <ContentCard elevation={Elevation.TWO} className="overflow-hidden p-0 mb-3">
      <ContentCardHeader
        leftElement={
          <>
            <Icon icon={IconNames.HAND} color={Colors.GRAY1} className="mr-1" />
            Angemeldete Begleiter
          </>
        }
      />
      <ListWrapper>
        {companionSignupIds.map((companionSignupId) => (
          <ProspectListItem
            key={companionSignupId}
            id={companionSignupId}
            addLoneSoul={addLoneSoul}
            addLoneCompanion={addLoneCompanion}
            addTeam={addTeam}
          />
        ))}
      </ListWrapper>
    </ContentCard>
  </>
);

ProspectList.fragments = {
  signups: gql`
    fragment ProspectList on Signup {
      ...ProspectListItem
      memberSignups {
        ...ProspectListItem
      }
      leaderSignup {
        ...ProspectListItem
      }
    }
    ${ProspectListItem.fragments.prospect}
  `,
};

export default ProspectList;
