import { FetchResult, gql } from '@apollo/client';
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import Text from '../../../components/Text';
import TextInput from '../../../components/TextInput';
import {
  CreateEmployeeInput,
  CreateEmployeeMutation,
  EmployeeCreateFormFragment,
  EmployeeEditFormFragment,
  UpdateEmployeeInput,
  UpdateEmployeeMutation,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';
import RoleMultiSelect from './RoleMultiSelect';

const validationSchema = Yup.object({
  meta: Yup.object({
    position: Yup.string().required('Erforderlich'),
  }),
  first_name: Yup.string().required('Erforderlich'),
  last_name: Yup.string().required('Erforderlich'),
  email: Yup.string().required('Erforderlich').email('Keine gültige Email'),
});

type EmployeeFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateEmployeeMutation) => void;
  onUpdated?: (data: UpdateEmployeeMutation) => void;
  initialValues: EmployeeCreateFormFragment | EmployeeEditFormFragment;
};

const isEditData = (
  initialValues: EmployeeCreateFormFragment | EmployeeEditFormFragment,
): initialValues is EmployeeEditFormFragment => 'id' in initialValues;

const isUpdateResult = (data: CreateEmployeeMutation | UpdateEmployeeMutation): data is UpdateEmployeeMutation =>
  'updateEmployee' in data;

const buildUpdateInput = (
  initialValues: EmployeeEditFormFragment,
  values: EmployeeEditFormFragment,
): UpdateEmployeeInput => {
  const updateValues: EmployeeEditFormFragment = buildDiff(initialValues, values);

  return {
    ...updateValues,
    ...(!!updateValues.roles && {
      roles: {
        sync: updateValues.roles.map((role) => role.id),
      },
    }),
  };
};

const buildCreateInput = (values: EmployeeCreateFormFragment): CreateEmployeeInput => ({
  ...values,
  roles: {
    sync: values.roles.map((role) => role.id),
  },
});

const EmployeeForm = ({ onCancel, onCreated, onUpdated, initialValues }: EmployeeFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [createEmployee] = useCreateEmployeeMutation();
  const handleSubmit = useSubmit<
    EmployeeCreateFormFragment | EmployeeEditFormFragment,
    FetchResult<CreateEmployeeMutation> | FetchResult<UpdateEmployeeMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateEmployee({
            variables: {
              input: buildUpdateInput(initialValues as EmployeeEditFormFragment, values),
            },
          })
        : createEmployee({
            variables: {
              input: buildCreateInput(values),
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<Text large>Mitarbeiter {isEdit ? 'bearbeiten' : 'hinzufügen'}</Text>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Vorname" labelInfo="(erforderlich)" name="first_name">
              <TextInput name="first_name" placeholder="Vorname" />
            </FormGroup>
            <FormGroup label="Nachname" labelInfo="(erforderlich)" name="last_name">
              <TextInput name="last_name" placeholder="Nachname" />
            </FormGroup>
            <FormGroup label="Email" name="email" labelInfo="(erforderlich)">
              <TextInput name="email" type="email" placeholder="Email" />
            </FormGroup>
            <FormGroup label="Telefon" name="phone">
              <TextInput name="phone" type="text" placeholder="Telefon" />
            </FormGroup>
            <FormGroup label="Mobil" name="mobile">
              <TextInput name="mobile" type="text" placeholder="Mobil" />
            </FormGroup>
            <FormGroup label="Adresse" name="street">
              <TextInput name="street" type="text" placeholder="Straße" />
            </FormGroup>
            <FormGroup name="postal_code">
              <TextInput name="postal_code" type="text" placeholder="PLZ" />
            </FormGroup>
            <FormGroup name="city">
              <TextInput name="city" type="text" placeholder="Ort" />
            </FormGroup>

            <Divider className="my-6 -mx-5" />
            <FormGroup label="Position" labelInfo="(erforderlich)" name="meta.position">
              <TextInput name="meta.position" placeholder="Position" />
            </FormGroup>
            <FormGroup label="Rollen" name="roles">
              <RoleMultiSelect name="roles" />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <>
                <Button text="Abbrechen" onClick={onCancel} className="ml-2" />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  className="ml-2"
                />
              </>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

EmployeeForm.fragments = {
  create: gql`
    fragment EmployeeCreateForm on Employee {
      status
      first_name
      last_name
      email
      phone
      mobile
      street
      city
      postal_code
      roles {
        id
      }
      meta {
        position
      }
    }
  `,
  edit: gql`
    fragment EmployeeEditForm on Employee {
      id
      status
      first_name
      last_name
      email
      phone
      mobile
      street
      city
      postal_code
      roles {
        id
        name
      }
      meta {
        position
      }
    }
  `,
};

export default EmployeeForm;
