import { FetchResult, gql } from '@apollo/client';
import { Button, Divider, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Formik } from 'formik';
import { useSubmit } from 'formik-apollo';
import React from 'react';
import * as Yup from 'yup';
import { ContentCard, ContentCardFooter, ContentCardHeader, ContentCardScroll } from '../../../components/ContentCard';
import FormGroup from '../../../components/FormGroup';
import Text from '../../../components/Text';
import TextAreaInput from '../../../components/TextAreaInput';
import TextInput from '../../../components/TextInput';
import {
  ContactCreateFormFragment,
  ContactEditFormFragment,
  CreateContactMutation,
  UpdateContactInput,
  UpdateContactMutation,
  useCreateContactMutation,
  useUpdateContactMutation,
} from '../../../generated/graphql';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import { buildDiff } from '../../../helpers/utils';

const validationSchema = Yup.object({
  first_name: Yup.string().required('Erforderlich'),
  last_name: Yup.string().required('Erforderlich'),
  email: Yup.string().email('Keine gültige Email').required('Erforderlich'),
});

type ContactFormProps = {
  onCancel?: () => void;
  onCreated?: (data: CreateContactMutation) => void;
  onUpdated?: (data: UpdateContactMutation) => void;
  initialValues: ContactCreateFormFragment | ContactEditFormFragment;
};

const isEditData = (
  initialValues: ContactCreateFormFragment | ContactEditFormFragment,
): initialValues is ContactEditFormFragment => 'id' in initialValues;

const isUpdateResult = (data: CreateContactMutation | UpdateContactMutation): data is UpdateContactMutation =>
  'updateContact' in data;

const buildUpdateInput = (
  initialValues: ContactEditFormFragment,
  values: ContactEditFormFragment,
): UpdateContactInput => buildDiff(initialValues, values);

const ContactForm = ({ onCancel, onCreated, onUpdated, initialValues }: ContactFormProps) => {
  const isEdit = isEditData(initialValues);
  const [updateContact] = useUpdateContactMutation();
  const [createContact] = useCreateContactMutation();
  const handleSubmit = useSubmit<
    ContactCreateFormFragment | ContactEditFormFragment,
    FetchResult<CreateContactMutation> | FetchResult<UpdateContactMutation>
  >({
    mutate: (values) =>
      isEditData(values)
        ? updateContact({
            variables: {
              input: buildUpdateInput(initialValues as ContactEditFormFragment, values),
            },
          })
        : createContact({
            variables: {
              input: values,
            },
          }),
    onCompleted: (res) => {
      if (!res.data) return;
      return isUpdateResult(res.data) ? onUpdated?.(res.data) : onCreated?.(res.data);
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, submitForm, dirty }) => (
        <ContentCard elevation={Elevation.FOUR}>
          <ContentCardHeader
            leftElement={<Text large>Ansprechpartner {isEdit ? 'bearbeiten' : 'hinzufügen'}</Text>}
            rightElement={<Button onClick={onCancel} icon={IconNames.CROSS} minimal />}
          />

          <ContentCardScroll>
            <FormGroup label="Vorname" labelInfo="(erforderlich)" name={'first_name'}>
              <TextInput name={'first_name'} placeholder="Vorname" />
            </FormGroup>
            <FormGroup label="Nachname" labelInfo="(erforderlich)" name={'last_name'}>
              <TextInput name={'last_name'} placeholder="Nachname" />
            </FormGroup>
            <FormGroup label="Email" labelInfo="(erforderlich)" name={'email'}>
              <TextInput name={'email'} type="email" placeholder="Email" />
            </FormGroup>
            <FormGroup label="Telefon" name={'phone'}>
              <TextInput name={'phone'} type="text" placeholder="Telefon" />
            </FormGroup>
            <FormGroup label="Mobil" name={'mobile'}>
              <TextInput name={'mobile'} type="text" placeholder="Mobil" />
            </FormGroup>
            <FormGroup label="Adresse" name={'street'}>
              <TextInput name={'street'} type="text" placeholder="Straße" />
            </FormGroup>
            <FormGroup name={'postal_code'}>
              <TextInput name={'postal_code'} type="text" placeholder="PLZ" />
            </FormGroup>
            <FormGroup name={'city'}>
              <TextInput name={'city'} type="text" placeholder="Ort" />
            </FormGroup>

            <Divider className="my-6 -mx-5" />
            <FormGroup name="notes">
              <TextAreaInput name="notes" placeholder="Interne Notizen" fill rows={10} />
            </FormGroup>
          </ContentCardScroll>

          <ContentCardFooter
            rightElement={
              <>
                <Button text="Abbrechen" onClick={onCancel} className="ml-2" />
                <Button
                  text={isEdit ? 'Änderungen Sichern' : 'Hinzufügen'}
                  loading={isSubmitting}
                  disabled={!dirty}
                  intent={Intent.PRIMARY}
                  onClick={submitForm}
                  className="ml-2"
                />
              </>
            }
          />
        </ContentCard>
      )}
    </Formik>
  );
};

ContactForm.fragments = {
  create: gql`
    fragment ContactCreateForm on Contact {
      first_name
      last_name
      email
      phone
      mobile
      street
      city
      postal_code
      notes
      donor_id
    }
  `,
  edit: gql`
    fragment ContactEditForm on Contact {
      id
      first_name
      last_name
      email
      phone
      mobile
      street
      city
      postal_code
      notes
    }
  `,
};

export default ContactForm;
