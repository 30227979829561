import React from 'react';
import { FetchResult } from '@apollo/client';
import { useSubmit } from 'formik-apollo';
import AppToaster from '../../../AppToaster';
import { ForgotPasswordInput, ForgotPasswordMutation, useForgotPasswordMutation } from '../../../generated/graphql';
import { SendResetLinkStatus } from '../../../helpers/constants';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import EmailForm from '../components/EmailForm';

const EmailPage = () => {
  const [sendEmail] = useForgotPasswordMutation();

  const handleSubmit = useSubmit<ForgotPasswordInput, FetchResult<ForgotPasswordMutation>>({
    mutate: (values) =>
      sendEmail({
        variables: {
          input: values,
        },
      }),
    onCompleted: ({ data }) => {
      if (data?.forgotPassword?.status === SendResetLinkStatus.Success) {
        AppToaster.success(
          'Eine Email mit Anweisungen zum Zurücksetzen ihres Passwortes ist auf dem Weg zu ihnen. Prüfen Sie ihr Postfach.',
        );
      }
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  return (
    <div className="flex min-h-screen items-center justify-center flex-col">
      <EmailForm onSubmit={handleSubmit} />
    </div>
  );
};

export default EmailPage;
