import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import { useOrganisationEditLazyQuery } from '../../../generated/graphql';
import OrganisationForm from './OrganisationForm';

interface IOrganisationEditOverlayProps extends IOverlayProps {
  id: string;
  onClose: () => void;
}

const OrganisationEditOverlay = ({ id, ...overlayProps }: IOrganisationEditOverlayProps) => {
  const [loadOrganisation, { data, called, loading, error }] = useOrganisationEditLazyQuery({
    variables: {
      id,
    },
  });

  if (overlayProps.isOpen && !loading && !called) {
    loadOrganisation();
  }

  const renderContent = () => {
    if (loading) return <LoadingCard />;
    if (error || !data?.organisation) return <ErrorCard />;

    const initialValues = {
      ...data.organisation,
      souls: data.organisation.souls.map((soul) => ({
        id: soul.id,
        display_name: soul.display_name,
      })),
      companions: data.organisation.companions.map((companion) => ({
        id: companion.id,
        display_name: companion.display_name,
      })),
    };

    return (
      <OrganisationForm
        onCancel={() => overlayProps.onClose?.()}
        onUpdated={() => {
          loadOrganisation();
          overlayProps.onClose?.();
        }}
        initialValues={initialValues}
      />
    );
  };

  return <ContentOverlay {...overlayProps}>{renderContent()}</ContentOverlay>;
};

export default OrganisationEditOverlay;
