import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useInterval from '../hooks/useInterval';

const updateConfig = {
  checkInterval: 60 * 5, // 5 minutes
  urlToCheck: window.location.origin,
};

let oldContent: string | null = null;
let updateAvailable = false;

const checkForUpdate = (onUpdateFound: () => void) => {
  fetch(updateConfig.urlToCheck)
    .then((res) => res.text())
    .then((newContent) => {
      // First update check
      if (!oldContent) {
        oldContent = newContent;
        return;
      }

      // No update available
      if (oldContent === newContent) return;

      updateAvailable = true;
      onUpdateFound();
    });
};

const AutoUpdate = () => {
  const location = useLocation();
  const [checkInterval, setCheckInterval] = useState(updateConfig.checkInterval * 1000);
  const updateFn = () => checkForUpdate(() => setCheckInterval(0));

  // Build reference by checking for update once on mount
  useEffect(() => updateFn(), []);

  // Periodically check for update
  useInterval(updateFn, checkInterval);

  // Reload on page navigation if update is available
  useEffect(() => {
    if (updateAvailable) window.location.reload();
  }, [location]);

  return null;
};

export default AutoUpdate;
