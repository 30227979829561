import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { gql } from '@apollo/client';
import React from 'react';
import DropdownInput from '../../../components/DropdownInput';
import { ContactSelectFragment, useDonorContactSelectQuery } from '../../../generated/graphql';

type ContactSelectProps = {
  donorId: string;
  name: string;
};

const ContactSelect = ({ donorId, name }: ContactSelectProps) => {
  const { data, loading, error } = useDonorContactSelectQuery({
    variables: {
      id: donorId,
    },
  });

  if (loading) return <Button loading />;
  if (error || !data || !data.donor) {
    return <Button disabled text="Fehler" rightIcon={IconNames.DOUBLE_CARET_VERTICAL} />;
  }

  return (
    <DropdownInput<ContactSelectFragment>
      name={name}
      canClear={false}
      labelPath="display_name"
      items={data.donor.contacts}
      filterable={false}
    />
  );
};

ContactSelect.fragments = {
  contacts: gql`
    fragment ContactSelect on Contact {
      id
      display_name
    }
  `,
};

export default ContactSelect;
