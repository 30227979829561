import { IOptionProps } from '@blueprintjs/core';
import {
  EventTicketRetrievalType,
  EventTicketType,
  SoulTargetGroup,
  EventStatus,
  Intent,
  MailcoachSegment,
  SortOrder,
} from '../generated/graphql';
import { IconOption } from '../types';
import { IconNames } from '@blueprintjs/icons';

const persisted = new Map<string, any>();

export enum AppEnv {
  Dev = 'DEV',
  Stage = 'STAGE',
  Prod = 'PROD',
}

export enum SendResetLinkStatus {
  Success = 'EMAIL_SENT',
  Failure = 'EMAIL_NOT_SENT',
}

export enum ResetPasswordStatus {
  Success = 'PASSWORD_UPDATED',
  Failure = 'PASSWORD_NOT_UPDATED',
}

export enum UserPermission {
  ManageEmployees = 'manage employees',
  ManageSouls = 'manage souls',
  ManageCompanions = 'manage companions',
  ManageDonors = 'manage donors',
  ManageLocations = 'manage locations',
  ManageEvents = 'manage events',
  ManageNewsletters = 'manage newsletters',
  MangeStatistics = 'manage statistics',
  CreateListOfAttendees = 'create lists of attendees',
  ManageRoles = 'manage roles',
  ManageCategories = 'manage categories',
  ManageOrganisations = 'manage organisations',
  ManageSignups = 'manage signups',
  ManageSettings = 'manage settings',
  ManageTicketAllotments = 'manage ticket allotments',
  ManageApiCalls = 'manage api calls',
}

export const ENV = process.env.REACT_APP_ENV as AppEnv;

export const setPersisted = (key: string, value: object | any[] | boolean | string | number | null) => {
  persisted.set(key, value);
  localStorage.setItem(key, JSON.stringify(value));
};

export const getPersisted = (key: string) => {
  if (!persisted.has(key)) {
    const retrieved = localStorage.getItem(key);
    persisted.set(key, retrieved === null ? undefined : JSON.parse(retrieved));
  }

  return persisted.get(key);
};

export const unsetPersisted = (key: string) => {
  persisted.delete(key);
  localStorage.removeItem(key);
};

export const hasPermission = (permission: UserPermission) => (getPersisted('permissions') || []).includes(permission);

export const ICON_OPTIONS: IconOption[] = Object.values(IconNames).map((iconName) => ({ id: iconName }));

export const EVENT_ORDER_OPTIONS: IOptionProps[] = [
  {
    value: '', // default
    label: 'Älteste zuerst',
  },
  {
    value: `START|${SortOrder.Desc}`,
    label: 'Neueste zuerst',
  },
];

export const PERSON_ORDER_OPTIONS: IOptionProps[] = [
  {
    value: '', // default
    label: 'Alphabetisch',
  },
  {
    value: `CREATED_AT|${SortOrder.Desc}`,
    label: 'Kürzlich registrierte zuerst',
  },
];

export const INTENT_OPTIONS: IOptionProps[] = [
  {
    value: Intent.None,
    label: 'Neutral / Grau',
  },
  {
    value: Intent.Primary,
    label: 'Wichtig / Blau',
  },
  {
    value: Intent.Success,
    label: 'Positiv / Grün',
  },
  {
    value: Intent.Warning,
    label: 'Warnung / Gelb',
  },
  {
    value: Intent.Danger,
    label: 'Negativ / Rot',
  },
];

export const TICKET_TYPE_OPTIONS: IOptionProps[] = [
  {
    value: EventTicketType.Unlimited,
    label: 'Freier Eintritt',
  },
  {
    value: EventTicketType.Fixed,
    label: 'Feste Anzahl für diese Veranstaltung',
  },
  {
    value: EventTicketType.Allotment,
    label: 'Kontingent nutzen',
  },
];

export const TICKET_RETRIEVAL_TYPE_OPTIONS: IOptionProps[] = [
  {
    value: EventTicketRetrievalType.Delivery,
    label: 'Zustellung per Post',
  },
  {
    value: EventTicketRetrievalType.Onsite,
    label: 'Abholen am Veranstaltungsort',
  },
];

export const EVENT_STATUS_OPTIONS: IOptionProps[] = [
  {
    value: EventStatus.Draft,
    label: 'Entwurf',
  },
  {
    value: EventStatus.Ready,
    label: 'Bereit zur Veröffentlichung',
  },
];

export const NEWSLETTER_SEGMENT_OPTIONS: IOptionProps[] = [
  {
    value: MailcoachSegment.Active,
    label: 'Alle aktiven Genießer und Begleiter',
  },
  {
    value: MailcoachSegment.Souls,
    label: 'Nur aktive Genießer',
  },
  {
    value: MailcoachSegment.Companions,
    label: 'Nur aktive Begleiter',
  },
  {
    value: MailcoachSegment.All,
    label: 'Alle (auch inaktive!) Genießer und Begleiter',
  },
];

export const SOUL_TARGET_GROUP_OPTIONS: IOptionProps[] = [
  {
    value: SoulTargetGroup.Disabled,
    label: 'Behinderung',
  },
  {
    value: SoulTargetGroup.Child,
    label: 'Kind',
  },
  {
    value: SoulTargetGroup.Immigrant,
    label: 'Migrationshintergrund',
  },
  {
    value: SoulTargetGroup.Senior,
    label: 'Senior',
  },
  {
    value: SoulTargetGroup.SociallyInNeed,
    label: 'Sozial bedürftig',
  },
  {
    value: SoulTargetGroup.None,
    label: '—',
  },
];
