import {
  NewsletterEventListItemFragment,
  NewsletterListItemFragment,
  MailcoachSegment,
  NewsletterStatus,
} from '../../generated/graphql';

export const dummyNewsletters: NewsletterListItemFragment[] = [
  {
    id: '1',
    status: NewsletterStatus.Sending,
    subject: 'Normal Newsletter Name',
    segment: MailcoachSegment.Active,
  },
];

export const dummyEvents: NewsletterEventListItemFragment[] = [
  {
    id: '1',
    name: 'Normal Event Name with long',
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 12:00:00',
    location: {
      id: '1',
      name: 'Super Location bit',
    },
  },
  {
    id: '2',
    name: 'Normal Event',
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 12:00:00',
    location: {
      id: '1',
      name: 'Super Location',
    },
  },
  {
    id: '3',
    name: 'Normal Event Name',
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 12:00:00',
    location: {
      id: '1',
      name: 'Super Location Longer title',
    },
  },
  {
    id: '4',
    name: 'Normal Event Name',
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 12:00:00',
    location: {
      id: '1',
      name: 'Super Location',
    },
  },
];
