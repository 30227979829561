import { Button, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { NewsletterDetailPageFragment } from '../../../generated/graphql';
import config from '../../../helpers/config';
import { openTab, getSubdomain } from '../../../helpers/utils';

type NewsletterDetailMenuProps = {
  newsletter: NewsletterDetailPageFragment;
};

const NewsletterDetailMenu = ({ newsletter }: NewsletterDetailMenuProps) => (
  <>
    <Popover2
      position={Position.BOTTOM}
      content={
        <Menu>
          <MenuItem
            text="PDF Veranstaltungsliste"
            icon={IconNames.DOWNLOAD}
            onClick={() => openTab(`${config.baseUrl}/${getSubdomain()}/downloads/newsletters/${newsletter.id}`)}
          />
        </Menu>
      }
    >
      <Button icon={IconNames.MORE} className="ml-2" />
    </Popover2>
  </>
);

export default NewsletterDetailMenu;
