import { Classes } from '@blueprintjs/core';
import React from 'react';
import { withoutBubbling } from '../helpers/utils';
import Text from './Text';

type EmailLinkProps = {
  email?: string | null;
  skeleton?: boolean;
};

const EmailLink = ({ email, skeleton }: EmailLinkProps) => {
  const skeletonClass = skeleton ? Classes.SKELETON : '';

  if (!email) {
    return (
      <Text skeleton={skeleton} muted>
        —
      </Text>
    );
  }

  return (
    <a href={`mailto:${email}`} onClick={withoutBubbling()} className={skeletonClass}>
      {email}
    </a>
  );
};

export default EmailLink;
