import { gql } from '@apollo/client';
import { Classes, Elevation, Tag } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { PropertyList, PropertyListHeader, PropertyListItem } from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useOrganisationDetailQuery } from '../../../generated/graphql';
import OrganisationCompanionList from '../components/OrganisationCompanionList';
import OrganisationCompanionListItem from '../components/OrganisationCompanionListItem';
import OrganisationDetailMenu from '../components/OrganisationDetailMenu';
import OrganisationEditOverlay from '../components/OrganisationEditOverlay';
import OrganisationSoulList from '../components/OrganisationSoulList';
import OrganisationSoulListItem from '../components/OrganisationSoulListItem';

type RouteParams = {
  organisationId?: string;
};

const OrganisationDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { organisationId } = useParams<RouteParams>();

  const options = {
    variables: {
      id: organisationId || '',
    },
  };

  const { data, loading, error } = useOrganisationDetailQuery(options);

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.organisation) return <NotFoundCard resource="Organisation" />;

  const { organisation } = data;

  return (
    <>
      <OrganisationEditOverlay
        id={organisation.id}
        isOpen={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
      />
      <ContentCard elevation={Elevation.TWO} className="mb-5">
        <ContentCardHeader
          leftElement={
            <>
              <Text large>{organisation.name}</Text>
              <Tag minimal className="ml-2">
                Organisation
              </Tag>
            </>
          }
          rightElement={<OrganisationDetailMenu organisation={organisation} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div className="flex py-4 px-5">
          <PropertyList>
            <PropertyListHeader>Details</PropertyListHeader>
            <PropertyListItem label="Beschreibung">
              {organisation.description ? (
                <div className={[Classes.RUNNING_TEXT, Classes.TEXT_MUTED].join(' ')}>{organisation.description}</div>
              ) : (
                <Text disabled>—</Text>
              )}
            </PropertyListItem>
            <PropertyListItem label="Interne Notizen">
              {organisation.notes ? (
                <div className={[Classes.RUNNING_TEXT, Classes.TEXT_MUTED].join(' ')}>{organisation.notes}</div>
              ) : (
                <Text disabled>—</Text>
              )}
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>
      <div className="flex">
        <div className="w-1/2 pr-2">
          <ContentCard elevation={Elevation.TWO} className="mb-5">
            <ContentCardHeader leftElement={<Text large>Begleiter</Text>} />
            <OrganisationCompanionList companions={organisation.companions} />
          </ContentCard>
        </div>
        <div className="w-1/2 pl-2">
          <ContentCard elevation={Elevation.TWO} className="mb-5">
            <ContentCardHeader leftElement={<Text large>Genießer</Text>} />
            <OrganisationSoulList souls={organisation.souls} />
          </ContentCard>
        </div>
      </div>
    </>
  );
};

OrganisationDetailPage.fragments = {
  organisation: gql`
    fragment OrganisationDetailPage on Organisation {
      id
      name
      description
      notes
      souls {
        ...OrganisationSoulListItem
      }
      companions {
        ...OrganisationCompanionListItem
      }
    }
    ${OrganisationSoulListItem.fragments.soul}
    ${OrganisationCompanionListItem.fragments.companion}
  `,
};

export default OrganisationDetailPage;
