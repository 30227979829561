import { endOfDay, subDays, subMonths } from 'date-fns';
import React from 'react';
import DateRangePicker from '../../../components/DateRangePicker';
import Filter from '../../../components/Filter';
import FilterItem from '../../../components/FilterItem';
import { DateTimeRange } from '../../../generated/graphql';
import { FutureDateTimeShortcuts, PastDateTimeShortcuts } from '../../../helpers/dateTimeUtils';

type NewsletterListFilterProps = {
  archive?: boolean;
};

const NewsletterListFilter = ({ archive }: NewsletterListFilterProps) => (
  <Filter
    filters={[
      {
        key: 'sentAt',
      },
    ]}
  >
    <FilterItem<DateTimeRange> name="sentAt" label="Datum">
      {({ value, onChange }) => (
        <DateRangePicker
          value={value}
          onChange={onChange}
          shortcuts={archive ? PastDateTimeShortcuts : FutureDateTimeShortcuts}
          initialMonth={archive ? subMonths(new Date(), 1) : new Date()}
          maxDate={archive ? endOfDay(subDays(new Date(), 1)) : undefined}
        />
      )}
    </FilterItem>
  </Filter>
);

export default NewsletterListFilter;
