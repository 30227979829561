import { HTMLTable, Spinner } from '@blueprintjs/core';
import React from 'react';
import { ContentCardFooter } from './ContentCard';
import Empty from './Empty';
import Error from './Error';
import InfiniteScrollWrapper from './InfiniteScrollWrapper';
import NoResults from './NoResults';
import TableHeaderCell from './TableHeaderCell';
import Text from './Text';

type ColumnType = {
  heading: string;
  width?: number;
  minWidth?: number;
};

type InfiniteScrollTableProps = {
  columns: ColumnType[];
  interactive?: boolean;
  loading: boolean;
  loadingMore: boolean;
  showLoadingMore?: boolean;
  onLoadMore: () => void;
  hasFilters?: boolean;
  empty: boolean;
  hasMorePages: boolean;
  hasError: boolean;
  children: React.ReactNode;
};

const InfiniteScrollTable = ({
  columns,
  interactive,
  loading,
  loadingMore,
  showLoadingMore = true,
  onLoadMore,
  hasFilters,
  hasMorePages,
  empty,
  hasError,
  children,
}: InfiniteScrollTableProps) => {
  if (!loading) {
    if (hasError) return <Error />;
    if (empty) return hasFilters ? <NoResults /> : <Empty />;
  }

  return (
    <InfiniteScrollWrapper loading={loading || loadingMore} onLoadMore={onLoadMore} hasMorePages={hasMorePages}>
      <HTMLTable interactive={interactive && (!loading || loadingMore)} condensed className="w-full">
        <thead>
          <tr>
            {columns.map(({ heading, minWidth, width }) => (
              <TableHeaderCell key={heading} width={width} minWidth={minWidth}>
                {heading}
              </TableHeaderCell>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </HTMLTable>
      {showLoadingMore && loadingMore && (
        <ContentCardFooter>
          <div className="flex flex-grow justify-center items-center">
            <Spinner size={16} />
            <Text muted className="pl-2">
              Lade mehr
            </Text>
          </div>
        </ContentCardFooter>
      )}
    </InfiniteScrollWrapper>
  );
};

export default InfiniteScrollTable;
