import { gql } from '@apollo/client';
import { Checkbox } from '@blueprintjs/core';
import EmailLink from '../../../components/EmailLink';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { ContactListItemFragment } from '../../../generated/graphql';
import { noop } from '../../../helpers/utils';
import { useNavigateSafely } from '../../../hooks/useNavigateSafely';
import { ListItemComponent } from '../../../types';

export type ContactListItemProps = {
  item: ContactListItemFragment;
  skeleton?: boolean;
} & ListItemComponent;

const ContactListItem = ({ item, skeleton, selected, onToggle }: ContactListItemProps) => {
  const buildNavigateSafely = useNavigateSafely();
  return (
    <tr onClick={onToggle || skeleton ? noop : buildNavigateSafely(`/contacts/${item!.id}`)}>
      {onToggle && (
        <TableCell>
          <Checkbox inline disabled={skeleton} checked={selected} onChange={() => onToggle(item.id)} className="m-0" />
        </TableCell>
      )}
      <TableCell>
        <Text skeleton={skeleton}>{item.display_name}</Text>
      </TableCell>
      <TableCell>
        <EmailLink email={item.email} skeleton={skeleton} />
      </TableCell>
      <TableCell muted>
        <Text skeleton={skeleton}>{item.phone || item.mobile || '—'}</Text>
      </TableCell>
    </tr>
  );
};

ContactListItem.fragments = {
  contact: gql`
    fragment ContactListItem on Contact {
      id
      display_name
      email
      phone
      mobile
    }
  `,
};

export default ContactListItem;
