import { Elevation } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import Text from '../../../components/Text';
import LocationDonorList from '../components/LocationDonorList';
import LocationEditOverlay from '../components/LocationEditOverlay';
import LocationInfo from '../components/LocationInfo';

type RouteParams = {
  locationId?: string;
};

const LocationDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { locationId } = useParams<RouteParams>();

  return (
    <>
      {/* Edit */}
      <LocationEditOverlay id={locationId || ''} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />

      {/* Info */}
      <LocationInfo onEdit={() => setIsEditDialogOpen(true)} />

      {/* Locations */}
      <ContentCard elevation={Elevation.TWO} className="mb-5">
        <ContentCardHeader leftElement={<Text large>Spender</Text>} />
        <LocationDonorList />
      </ContentCard>
    </>
  );
};

export default LocationDetailPage;
