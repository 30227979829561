import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Navigate, Route, Routes, useParams } from 'react-router-dom';
import client from '../client';
import config from '../helpers/config';
import EmailPage from '../modules/auth/pages/EmailPage';
import LoginPage from '../modules/auth/pages/LoginPage';
import ResetPage from '../modules/auth/pages/ResetPage';
import MainPage from '../pages/MainPage';
import NotFoundPage from '../pages/NotFoundPage';
import AutoUpdate from './AutoUpdate';
import ScrollToTop from './ScrollToTop';

const AcceptLinkRedirect = () => {
  const { token } = useParams<{ token: string }>();
  window.location.replace(`${config.baseUrl}/accept/${token}`);
  return null;
};

const App = () => (
  <ApolloProvider client={client}>
    <Router>
      <ScrollToTop />
      <AutoUpdate />
      <Routes>
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="/auth/login" element={<LoginPage />} />
        <Route path="/auth/email" element={<EmailPage />} />
        <Route path="/auth/reset" element={<ResetPage />} />
        {/* Redirecting legacy signup accept links: */}
        <Route path="/accept/:token" element={<AcceptLinkRedirect />} />
        <Route path="/" element={<Navigate to="/events" replace />} />
        <Route path="/*" element={<MainPage />} />
      </Routes>
    </Router>
  </ApolloProvider>
);

export default App;
