import { Card, IProps } from '@blueprintjs/core';
import React from 'react';
import Error, { ErrorProps } from './Error';

const ErrorCard = ({ className, ...props }: ErrorProps & IProps) => (
  <Card className={className}>
    <Error {...props} />
  </Card>
);

export default ErrorCard;
