import { Button, Callout, Card, Intent } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import ErrorCard from '../../../components/ErrorCard';
import FormGroup from '../../../components/FormGroup';
import LoadingCard from '../../../components/LoadingCard';
import ResourceLink from '../../../components/ResourceLink';
import Text from '../../../components/Text';
import TextInput from '../../../components/TextInput';
import { useCurrentTenantQuery } from '../../../generated/graphql';
import { AppEnv, ENV, setPersisted } from '../../../helpers/constants';

interface ILockButton {
  locked: boolean;
  disabled?: boolean;
  onClick: any;
}

const LockButton = ({ locked, disabled, onClick }: ILockButton) => (
  <Tooltip2 content={`Passwort ${locked ? 'zeigen' : 'verstecken'}`} disabled={disabled}>
    <Button
      disabled={disabled}
      icon={locked ? 'unlock' : 'lock'}
      intent={Intent.WARNING}
      minimal={true}
      onClick={onClick}
    />
  </Tooltip2>
);

export interface ILoginFormValues {
  email: string;
  password: string;
}

interface ILoginForm {
  onSubmit: (values: ILoginFormValues, formikHelpers: FormikHelpers<ILoginFormValues>) => void;
}

const LoginSchema = Yup.object({
  email: Yup.string().email('Keine gültige Email').required('Erforderlich'),
  password: Yup.string().min(2, 'Zu Kurz').max(50, 'Zu Lang').required('Erforderlich'),
});

const LoginForm = ({ onSubmit }: ILoginForm) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const { data, loading, error } = useCurrentTenantQuery({
    onCompleted: ({ currentTenant }) => {
      setPersisted('useLegacyNewsletter', currentTenant.use_legacy_newsletter);
    },
  });
  const togglePasswordShown = useCallback(() => setPasswordShown(!passwordShown), [passwordShown]);

  if (loading) return <LoadingCard />;
  if (error || !data?.currentTenant) return <ErrorCard />;

  const initialLoginFormValues: ILoginFormValues = {
    email: ENV === AppEnv.Dev ? 'admin@hamburg.kulturschluessel.org' : '',
    password: ENV === AppEnv.Dev ? 'testtest' : '',
  };

  return (
    <Formik initialValues={initialLoginFormValues} validationSchema={LoginSchema} onSubmit={onSubmit}>
      {({ isSubmitting, status }) => (
        <>
          <div className="mb-5">
            <Text large>{data.currentTenant?.name}</Text>
          </div>
          <Card>
            {status && status.error && (
              <Callout intent={Intent.DANGER} className="mb-4">
                {status.error}
              </Callout>
            )}
            <Form>
              <FormGroup label="Email" labelFor="email" name="email">
                <TextInput name="email" type="email" placeholder="Email" />
              </FormGroup>
              <FormGroup label="Passwort" labelFor="password" name="password">
                <TextInput
                  name="password"
                  type={passwordShown ? 'text' : 'password'}
                  rightElement={
                    <LockButton locked={!passwordShown} disabled={isSubmitting} onClick={togglePasswordShown} />
                  }
                />
              </FormGroup>
              <Button
                type="submit"
                intent={Intent.SUCCESS}
                rightIcon={IconNames.LOG_IN}
                text="Login"
                loading={isSubmitting}
                fill
              />
              <div className="pt-4 float-right">
                <ResourceLink path="/auth/email" text="Passwort vergessen?" muted />
              </div>
            </Form>
          </Card>
        </>
      )}
    </Formik>
  );
};

export default LoginForm;
