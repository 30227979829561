import { Button, ButtonGroup, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { useNavigate } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import {
  CompanionInfoFragment,
  useActivateCompanionMutation,
  useApproveCompanionMutation,
  useDeactivateCompanionMutation,
  useDeleteCompanionMutation,
  useDenyCompanionMutation,
  UserStatus,
  useSendResetLinkForUserMutation,
} from '../../../generated/graphql';
import { showErrorMessage } from '../../../helpers/graphql';

type CompanionDetailMenuProps = {
  companion: CompanionInfoFragment;
  onEdit: () => void;
};

const CompanionDetailMenu = ({ companion, onEdit }: CompanionDetailMenuProps) => {
  const navigate = useNavigate();
  const options = {
    variables: {
      id: companion.id,
    },
    onError: showErrorMessage,
  };
  const [approveCompanion, { loading: approving }] = useApproveCompanionMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${companion.display_name}  wurde freigeschaltet`),
  });
  const [denyCompanion, { loading: denying }] = useDenyCompanionMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${companion.display_name}  wurde abgelehnt`),
  });
  const [activateCompanion, { loading: activating }] = useActivateCompanionMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${companion.display_name}  wurde AKTIV geschaltet`),
  });
  const [deactivateCompanion, { loading: deactivating }] = useDeactivateCompanionMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${companion.display_name}  wurde PASSIV geschaltet`),
  });
  const [deleteCompanion, { loading: deleting }] = useDeleteCompanionMutation({
    ...options,
    onCompleted: () => {
      AppToaster.success(`${companion.display_name}  wurde in den Papierkorb gelegt`);
      navigate(-1);
    },
  });
  const [sendPasswordResetEmail, { loading: sendingPasswordResetEmail }] = useSendResetLinkForUserMutation({
    ...options,
    onCompleted: () =>
      AppToaster.success(`Anweisungen zum Zurücksetzen des Passwortes wurden an ${companion.display_name} verschickt!`),
  });

  if (companion.status === UserStatus.New) {
    return (
      <>
        <ButtonGroup className="ml-2">
          <Button text="Bestätigen" icon={IconNames.TICK} onClick={approveCompanion as any} loading={approving} />
          <Button text="Ablehnen" icon={IconNames.CROSS} onClick={denyCompanion as any} loading={denying} />
        </ButtonGroup>
        <Popover2
          position={Position.BOTTOM}
          content={
            <Menu>
              <MenuItem text="Bearbeiten" icon={IconNames.EDIT} onClick={onEdit} />
              <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteCompanion as any} />
            </Menu>
          }
        >
          <Button icon={IconNames.MORE} className="ml-2" />
        </Popover2>
      </>
    );
  }

  return (
    <>
      <Button text="Bearbeiten" icon={IconNames.EDIT} className="ml-2" onClick={onEdit} />
      <Popover2
        position={Position.BOTTOM}
        content={
          <Menu>
            {companion.status === UserStatus.Active ? (
              <MenuItem text="Passiv schalten" icon={IconNames.OFFLINE} onClick={deactivateCompanion as any} />
            ) : (
              <MenuItem text="Aktiv schalten" icon={IconNames.OFFLINE} onClick={activateCompanion as any} />
            )}
            <MenuItem text="Passwort zurücksetzen" icon={IconNames.KEY} onClick={sendPasswordResetEmail as any} />
            <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteCompanion as any} />
          </Menu>
        }
      >
        <Button
          icon={IconNames.MORE}
          className="ml-2"
          loading={activating || deactivating || deleting || sendingPasswordResetEmail}
        />
      </Popover2>
    </>
  );
};

export default CompanionDetailMenu;
