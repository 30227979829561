import React from 'react';
import { Classes, Intent, PopoverInteractionKind, Position, Tag } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';

type PopoverTagProps = {
  label: string;
  intent?: Intent;
  minimal?: boolean;
  position?: Position;
  skeleton?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
  children: React.ReactNode;
};

const PopoverTag = ({
  label,
  minimal,
  marginRight,
  marginLeft,
  intent,
  position,
  skeleton,
  children,
}: PopoverTagProps) => (
  <Popover2
    interactionKind={PopoverInteractionKind.HOVER}
    hoverOpenDelay={100}
    position={position}
    disabled={skeleton}
    content={
      <div className="py-4 px-5" style={{ maxWidth: 500 }}>
        {children}
      </div>
    }
  >
    <Tag
      intent={intent}
      minimal={minimal}
      className={[skeleton && Classes.SKELETON, marginRight && 'mr-1', marginLeft && 'ml-1'].join(' ')}
    >
      {label}
    </Tag>
  </Popover2>
);

export default PopoverTag;
