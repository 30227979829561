import React from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import InfiniteScrollTable from '../../../components/InfiniteScrollTable';
import {
  CompanionFutureSignupsDocument,
  CompanionFutureSignupsQuery,
  CompanionFutureSignupsQueryVariables,
  FutureSignupListItemFragment,
  SoulFutureSignupsDocument,
  SoulFutureSignupsQuery,
  SoulFutureSignupsQueryVariables,
} from '../../../generated/graphql';
import { dummyFutureSignups } from '../dummy';
import FutureSignupListItem from '../../../components/FutureSignupListItem';

type FutureSignupListProps = {
  isSoul: boolean;
  prospectId: string;
};

type QueryDataType = SoulFutureSignupsQuery | CompanionFutureSignupsQuery;
type QueryVariablesType = SoulFutureSignupsQueryVariables | CompanionFutureSignupsQueryVariables;

const isSoulFutureSignups = (data?: QueryDataType): data is SoulFutureSignupsQuery => !!data && 'soul' in data;

const FutureSignupList = ({ isSoul, prospectId }: FutureSignupListProps) => {
  const query = isSoul ? SoulFutureSignupsDocument : CompanionFutureSignupsDocument;
  const { data, loading, error, networkStatus, fetchMore } = useQuery<QueryDataType, QueryVariablesType>(query, {
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: prospectId,
      // infinite flag is a hack to make the cache's "FielPolicy" read function
      // return all items
      // @ts-ignore
      infinite: true,
    },
  });

  // For some reason, type inference does not work properly here
  // @ts-ignore
  const { data: futureSignups, paginatorInfo } = isSoulFutureSignups(data)
    ? data.soul?.future_signups
    : data?.companion?.future_signups || {};
  const { currentPage, hasMorePages } = paginatorInfo || {};
  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <InfiniteScrollTable
      columns={[{ heading: 'Veranstaltung' }, { heading: 'Status' }, { heading: 'Datum' }]}
      loading={loading}
      loadingMore={loadingMore}
      showLoadingMore={false}
      empty={!futureSignups?.length}
      hasMorePages={!!hasMorePages}
      hasError={!!error}
      onLoadMore={() =>
        fetchMore({
          variables: {
            page: (currentPage || 1) + 1,
          },
        })
      }
    >
      {(loading && !loadingMore ? dummyFutureSignups : futureSignups || []).map(
        (item: FutureSignupListItemFragment) => (
          <FutureSignupListItem key={item.id} signup={item} skeleton={loading && !loadingMore} />
        ),
      )}
      {hasMorePages && <FutureSignupListItem key={dummyFutureSignups[0].id} signup={dummyFutureSignups[0]} skeleton />}
    </InfiniteScrollTable>
  );
};

export default FutureSignupList;
