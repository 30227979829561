import { gql } from '@apollo/client';
import { Checkbox } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import EmailLink from '../../../components/EmailLink';
import PersonTagIcon from '../../../components/PersonTagIcon';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import { CompanionListItemFragment } from '../../../generated/graphql';
import { noop } from '../../../helpers/utils';
import { useNavigateSafely } from '../../../hooks/useNavigateSafely';
import { ListItemComponent } from '../../../types';

export type CompanionListProps = {
  item: CompanionListItemFragment;
  skeleton?: boolean;
} & ListItemComponent;

const CompanionListItem = ({ item, skeleton, selected, onToggle }: CompanionListProps) => {
  const buildNavigateSafely = useNavigateSafely();
  return (
    <tr onClick={onToggle || skeleton ? noop : buildNavigateSafely(`/companions/${item!.id}`)}>
      {onToggle && (
        <TableCell>
          <Checkbox inline disabled={skeleton} checked={selected} onChange={() => onToggle(item.id)} className="m-0" />
        </TableCell>
      )}
      <TableCell>
        <Text skeleton={skeleton}>{item.display_name}</Text> <br />
        <Text skeleton={skeleton} small muted>
          {item.organisation && item.organisation.name}
        </Text>
      </TableCell>
      <TableCell>
        {item.meta.can_wheelchair && (
          <TooltipIcon label="Kann Rollstuhl schieben" icon={IconNames.SHOPPING_CART} skeleton={skeleton} marginRight />
        )}
        {item.meta.can_wheeled_walker && (
          <TooltipIcon
            label="Kann Genießer mit Rollator begleiten"
            icon={IconNames.WALK}
            skeleton={skeleton}
            marginRight
          />
        )}
        {item.meta.can_blind && (
          <TooltipIcon label="Kann Blinde begleiten" icon={IconNames.EYE_OFF} skeleton={skeleton} marginRight />
        )}
        {item.meta.can_drive && (
          <TooltipIcon
            label="Kann Genießer von Zuhause abholen"
            icon={IconNames.DRIVE_TIME}
            skeleton={skeleton}
            marginRight
          />
        )}
        {item.tags.map((tag) => (
          <PersonTagIcon key={tag.id} tag={tag} skeleton={skeleton} />
        ))}
      </TableCell>
      <TableCell>
        <Text skeleton={skeleton} muted>
          {item.phone || item.mobile || '—'}
        </Text>
      </TableCell>
      <TableCell>
        <EmailLink email={item.email} skeleton={skeleton} />
      </TableCell>
    </tr>
  );
};

CompanionListItem.fragments = {
  companion: gql`
    fragment CompanionListItem on Companion {
      id
      status
      display_name
      email
      phone
      mobile
      meta {
        can_wheelchair
        can_wheeled_walker
        can_drive
        can_blind
      }
      organisation {
        id
        name
      }
      tags {
        ...PersonTagIcon
      }
    }
    ${PersonTagIcon.fragments.tag}
  `,
};

export default CompanionListItem;
