import React, { Fragment } from 'react';
import { gql } from '@apollo/client';
import CategoryTag from './CategoryTag';
import Text from './Text';
import { AttendListItemFragment } from '../generated/graphql';
import { formatToNowStrict } from '../helpers/dateTimeUtils';
import TableCell from './TableCell';
import { Link } from 'react-router-dom';

export type AttendListItemProps = {
  attend: AttendListItemFragment;
  skeleton?: boolean;
};

const renderMatchingInfo = (attend: AttendListItemFragment, skeleton?: boolean) => {
  // Display companion name
  if (attend.leaderSignup) {
    return (
      <Link to={`/companions/${attend.leaderSignup.companion?.id}`}>
        <Text skeleton={skeleton}>{attend.leaderSignup.companion?.display_name}</Text>
      </Link>
    );
  }

  // List group members
  if (attend.memberSignups.length) {
    return attend.memberSignups.map((item, index) => (
      <Fragment key={item.id}>
        <Link to={`/souls/${item.soul?.id}`}>
          <Text skeleton={skeleton}>{item.soul?.display_name}</Text>
        </Link>
        {index < attend.memberSignups.length - 1 && <br />}
      </Fragment>
    ));
  }

  // Show none
  return (
    <Text skeleton={skeleton} muted>
      —
    </Text>
  );
};

const AttendListItem = ({ attend, skeleton }: AttendListItemProps) => (
  <tr>
    <TableCell>
      {attend.event.categories.map((category) => (
        <CategoryTag key={category.id} category={category} skeleton={skeleton} className="mr-1" />
      ))}
      <Link to={`/events/${attend.event.id}`}>
        <Text skeleton={skeleton}>{attend.event.name}</Text>
      </Link>
    </TableCell>
    <TableCell>{renderMatchingInfo(attend, skeleton)}</TableCell>
    <TableCell>
      <Text skeleton={skeleton} muted>
        {formatToNowStrict(attend.event.start)}
      </Text>
    </TableCell>
  </tr>
);

AttendListItem.fragments = {
  attend: gql`
    fragment AttendListItem on Signup {
      id
      event {
        id
        name
        start
        categories {
          id
          name
          color
        }
      }
      leaderSignup {
        id
        companion {
          id
          display_name
        }
      }
      memberSignups {
        id
        soul {
          id
          display_name
        }
      }
    }
  `,
};

export default AttendListItem;
