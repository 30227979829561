import { Elevation } from '@blueprintjs/core';
import React from 'react';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import Text from '../../../components/Text';
import { EventDetailPageFragment, EventStatus } from '../../../generated/graphql';
import AttendeeList from './AttendeeList';
import AttendeeListMenu from './AttendeeListMenu';
import AttendeeListStatus from './AttendeeListStatus';

type AttendeeListContainerProps = {
  event: EventDetailPageFragment;
};

const AttendeeListContainer = ({ event }: AttendeeListContainerProps) => (
  <>
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <ContentCardHeader
        leftElement={<Text large>Teilnehmerliste</Text>}
        rightElement={<AttendeeListMenu event={event} />}
      />
      {event.status === EventStatus.Closed && (
        <div className="flex flex-col py-4 px-5">
          <AttendeeListStatus event={event} />
        </div>
      )}
    </ContentCard>
    <AttendeeList event={event} />
  </>
);

export default AttendeeListContainer;
