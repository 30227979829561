import { HTMLTable } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { OrganisationDetailPageFragment } from '../../../generated/graphql';
import OrganisationCompanionListItem from './OrganisationCompanionListItem';

type OrganisationCompanionListProps = {
  companions: OrganisationDetailPageFragment['companions'];
};

const OrganisationCompanionList = ({ companions }: OrganisationCompanionListProps) => {
  const navigate = useNavigate();

  return (
    <HTMLTable interactive condensed className="w-full">
      <thead>
        <tr>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Besonderheiten</TableHeaderCell>
        </tr>
      </thead>
      <tbody>
        {companions.map((companion) => (
          <OrganisationCompanionListItem
            key={companion.id}
            item={companion}
            onClick={() => navigate(`/companions/${companion.id}`)}
          />
        ))}
      </tbody>
    </HTMLTable>
  );
};

export default OrganisationCompanionList;
