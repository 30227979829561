import { IOverlayProps } from '@blueprintjs/core';
import React from 'react';
import ContentOverlay from '../../../components/ContentOverlay';
import { RoleCreateFormFragment } from '../../../generated/graphql';
import RoleForm from './RoleForm';

type RoleCreateOverlayProps = {
  onSuccess: (modelId: string) => void;
  onClose: () => void;
} & IOverlayProps;

const RoleCreateOverlay = ({ onSuccess, ...overlayProps }: RoleCreateOverlayProps) => {
  const initialValues: RoleCreateFormFragment = {
    name: '',
    permissions: [],
  };

  const closeOverlay = () => overlayProps.onClose?.();

  return (
    <ContentOverlay {...overlayProps}>
      <RoleForm
        onCancel={closeOverlay}
        onCreated={({ createRole }) => {
          onSuccess(createRole.id);
        }}
        initialValues={initialValues}
      />
    </ContentOverlay>
  );
};

export default RoleCreateOverlay;
