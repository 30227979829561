import { IconNames } from '@blueprintjs/icons';
import { Intent, SoulListItemFragment, Tag, UserStatus } from '../../generated/graphql';

export const dummyTags: Tag[] = [
  {
    id: '1',
    name: 'Rollstuhl',
    icon: IconNames.SHOPPING_CART,
    intent: Intent.None,
    description: null,
    created_at: '2019-01-04 12:00:00',
    updated_at: '2019-01-04 12:00:00',
  },
  {
    id: '2',
    name: 'Rollstuhl',
    icon: IconNames.SHOPPING_CART,
    intent: Intent.None,
    description: null,
    created_at: '2019-01-04 12:00:00',
    updated_at: '2019-01-04 12:00:00',
  },
  {
    id: '3',
    name: 'Rollstuhl',
    icon: IconNames.SHOPPING_CART,
    intent: Intent.None,
    description: null,
    created_at: '2019-01-04 12:00:00',
    updated_at: '2019-01-04 12:00:00',
  },
  {
    id: '4',
    name: 'Rollstuhl',
    icon: IconNames.SHOPPING_CART,
    intent: Intent.None,
    description: null,
    created_at: '2019-01-04 12:00:00',
    updated_at: '2019-01-04 12:00:00',
  },
];

export const dummySouls: SoulListItemFragment[] = [
  {
    id: '1',
    status: UserStatus.Active,
    display_name: 'Benjamin Wulff',
    email: 'benjamin.wulff@me.com',
    phone: '0000 0000 0000',
    meta: {
      buys_ticket: false,
      needs_wheelchair: false,
      needs_wheeled_walker: true,
      needs_drive: true,
      needs_blind: false,
      needs_trainstation_fetch: false,
    },
    organisation: {
      id: '1',
      name: 'Keine Organisation',
    },
    tags: [dummyTags[0], dummyTags[1]],
  },
  {
    id: '2',
    status: UserStatus.Active,
    display_name: 'Mario Speedwagon',
    email: 'mario.speedwagon@googlemail.com',
    phone: '0000 0000 00',
    meta: {
      buys_ticket: false,
      needs_wheelchair: false,
      needs_wheeled_walker: true,
      needs_drive: true,
      needs_blind: false,
      needs_trainstation_fetch: false,
    },
    organisation: null,
    tags: [dummyTags[0], dummyTags[1], dummyTags[2], dummyTags[3]],
  },
  {
    id: '3',
    status: UserStatus.Active,
    display_name: 'Anna Sthesia',
    email: 'anna.sthesia@gmail.de',
    phone: '000 0000 00',
    meta: {
      buys_ticket: false,
      needs_wheelchair: true,
      needs_wheeled_walker: true,
      needs_drive: true,
      needs_blind: false,
      needs_trainstation_fetch: false,
    },
    organisation: {
      id: '3',
      name: 'Organisation mit sehr langem Namen',
    },
    tags: [],
  },
  {
    id: '4',
    status: UserStatus.Active,
    display_name: 'Forcewind Forcewind',
    email: null,
    phone: '0000 0000 00',
    meta: {
      buys_ticket: false,
      needs_wheelchair: false,
      needs_wheeled_walker: false,
      needs_drive: false,
      needs_blind: false,
      needs_trainstation_fetch: false,
    },
    organisation: {
      id: '4',
      name: 'Kurzorganisation',
    },
    tags: [dummyTags[0], dummyTags[1], dummyTags[2]],
  },
];
