/** @jsxImportSource @emotion/react */
import { gql } from '@apollo/client';
import { Elevation, HTMLTable } from '@blueprintjs/core';
import { css } from '@emotion/react';
import CategoryTag from '../../../components/CategoryTag';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import NotFoundCard from '../../../components/NotFoundCard';
import TableCell from '../../../components/TableCell';
import TableHeaderCell from '../../../components/TableHeaderCell';
import Text from '../../../components/Text';
import { CategoryStatsFragment, StatsRangeType, useCategoryStatsQuery } from '../../../generated/graphql';
import { dummyCategoryStats } from '../dummy';
import ChangeTag from './ChangeTag';
import PieChart from './PieChart';

type CategoryStatsType = {
  rangeType: StatsRangeType;
  range: string;
};

type CategoryStatsItemProps = {
  item: CategoryStatsFragment;
  skeleton?: boolean;
};

const TableWrapper = ({ children }: { children: React.ReactNode }) => (
  <HTMLTable condensed className="w-full">
    <thead>
      <tr>
        <TableHeaderCell>Kategorie</TableHeaderCell>
        <TableHeaderCell>Karten</TableHeaderCell>
        <TableHeaderCell colSpan={2}>Anmeldungen</TableHeaderCell>
        <TableHeaderCell colSpan={2}>Auslastung</TableHeaderCell>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </HTMLTable>
);

const CategoryStatsItem = ({ item: { category, data }, skeleton }: CategoryStatsItemProps) => (
  <tr>
    <TableCell>
      <CategoryTag category={category} skeleton={skeleton} className="mr-1" />
    </TableCell>
    <TableCell>
      <Text skeleton={skeleton}>{data.tickets}</Text>
    </TableCell>
    <TableCell right>
      <Text skeleton={skeleton}>{data.signups}</Text>
    </TableCell>
    <TableCell>
      <ChangeTag now={data.signups} prev={data.previous_signups} skeleton={skeleton} />
    </TableCell>
    <TableCell right>
      <Text skeleton={skeleton}>{Math.round((data.signups / data.tickets || 0) * 100)}%</Text>
    </TableCell>
    <TableCell>
      <ChangeTag
        now={data.signups / data.tickets || 0}
        prev={data.previous_signups / data.previous_tickets || 0}
        skeleton={skeleton}
      />
    </TableCell>
  </tr>
);

const CategoryStats = ({ range, rangeType }: CategoryStatsType) => {
  const { data, loading, error } = useCategoryStatsQuery({
    variables: {
      input: {
        range,
        range_type: rangeType,
      },
    },
  });

  if (error) return <ErrorCard css={styles.categoryCard} />;
  if (!loading && !data?.categoryStats)
    return <NotFoundCard resource="Kulturgenießer nach Kategorien" css={styles.categoryCard} />;

  const stats = data?.categoryStats;
  const totalTickets = stats?.reduce((acc, item) => acc + item.data.tickets, 0) ?? 1;
  const pieData = stats
    ? stats.map((item) => ({
        id: item.category.id,
        label: item.category.name,
        value: item.data.tickets / totalTickets,
        color: item.category.color,
      }))
    : [];

  return (
    <ContentCard elevation={Elevation.TWO} css={styles.categoryCard}>
      <ContentCardHeader leftElement={<Text large>Kulturgenießer nach Kategorien</Text>} />
      <div css={styles.chartContainer}>{!loading && !!stats && <PieChart data={pieData} />}</div>
      <TableWrapper>
        {(stats && !loading ? stats : dummyCategoryStats).map((item) => (
          <CategoryStatsItem key={item.category.id} item={item} skeleton={loading || !stats} />
        ))}
      </TableWrapper>
    </ContentCard>
  );
};

CategoryStats.fragments = {
  stats: gql`
    fragment CategoryStats on CategoryStatsPayload {
      category {
        id
        name
        color
      }
      data {
        tickets
        signups
        previous_tickets
        previous_signups
      }
    }
  `,
};

export default CategoryStats;

const styles = {
  categoryCard: css`
    margin-bottom: 20px;
    flex-basis: calc(50% - 10px);
  `,
  chartContainer: css`
    height: 400px;
  `,
};
