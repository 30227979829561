import React from 'react';
import { Card, Elevation } from '@blueprintjs/core';
import { gql } from '@apollo/client';
import { AttendeeGroupFragment } from '../../../generated/graphql';
import AttendeeListItem from './AttendeeListItem';

type AttendeeGroupProps = {
  item: AttendeeGroupFragment;
};

const AttendeeGroup = ({ item: leaderSignup }: AttendeeGroupProps) => (
  <Card className="overflow-hidden p-0 mb-3" elevation={Elevation.TWO}>
    <AttendeeListItem item={leaderSignup} />
    {leaderSignup.memberSignups.map((memberSignup) => (
      <AttendeeListItem key={memberSignup.id} item={memberSignup} isInGroup />
    ))}
  </Card>
);

AttendeeGroup.fragments = {
  groups: gql`
    fragment AttendeeGroup on Signup {
      ...AttendeeListItem
      memberSignups {
        ...AttendeeListItem
      }
    }
    ${AttendeeListItem.fragments.attendees}
  `,
};

export default AttendeeGroup;
