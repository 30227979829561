import { Intent } from '@blueprintjs/core';
import React from 'react';
import PopoverTag from '../../../components/PopoverTag';
import Text from '../../../components/Text';
import { formatTime } from '../../../helpers/dateTimeUtils';

type ConflictingSignup = {
  id: string;
  event: {
    id: string;
    name: string;
    start: string;
  };
};

type ConflictingSignupsProps = {
  userName: string;
  signups: ConflictingSignup[];
};

const ConflictingSignups = ({ signups, userName }: ConflictingSignupsProps) =>
  !signups.length ? null : (
    <PopoverTag label="Konflikt" minimal intent={Intent.DANGER} marginRight>
      <Text small>{userName} ist an diesem Tag auch angemeldet für:</Text> <br />
      <ul>
        {signups.map((signup) => (
          <li key={signup.id}>
            <Text small>{signup.event.name}</Text>
            <Text small muted>{` um `}</Text>
            <Text small>{formatTime(signup.event.start)}</Text>
          </li>
        ))}
      </ul>
    </PopoverTag>
  );

export default ConflictingSignups;
