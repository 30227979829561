import { NetworkStatus } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { unserializeFilterValue } from '../../../components/Filter';
import InfiniteScrollTable from '../../../components/InfiniteScrollTable';
import { DateTimeRange, useNewsletterListQuery } from '../../../generated/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import { dummyNewsletters } from '../dummy';
import NewsletterListItem from './NewsletterListItem';

type NewsletterListProps = {
  archive?: boolean;
};

const NewsletterList = ({ archive }: NewsletterListProps) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const sentAt = unserializeFilterValue(queryParams.get('sentAt')) as DateTimeRange | null;
  const filtersUsed = sentAt !== null;

  const { data, loading, error, fetchMore, networkStatus } = useNewsletterListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      sentAt: sentAt || undefined,
      // infinite flag is a hack to make the cache's "FielPolicy" read function
      // return all items
      // @ts-ignore
      infinite: true,
    },
  });

  const { data: newsletters, paginatorInfo } = data?.newsletters || {};
  const { currentPage, hasMorePages } = paginatorInfo || {};
  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <InfiniteScrollTable
      interactive
      columns={[{ heading: 'Titel' }, { heading: 'Empfänger' }, { heading: 'Gesendet' }]}
      loading={loading}
      loadingMore={loadingMore}
      hasFilters={filtersUsed}
      empty={!newsletters?.length}
      hasMorePages={!!hasMorePages}
      hasError={!!error}
      onLoadMore={() =>
        fetchMore({
          variables: {
            page: (currentPage || 1) + 1,
          },
        })
      }
    >
      {(loading && !loadingMore ? dummyNewsletters : newsletters || []).map((item) => (
        <NewsletterListItem
          key={item.id}
          item={item}
          skeleton={loading && !loadingMore}
          onClick={() => !loading && navigate(`/newsletters/${item!.id}`)}
        />
      ))}
    </InfiniteScrollTable>
  );
};

export default NewsletterList;
