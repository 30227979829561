import { FormGroup as BPFormGroup, IFormGroupProps, Intent } from '@blueprintjs/core';
import { getIn, useFormikContext } from 'formik';
import React from 'react';

type FormGroupProps = {
  children: React.ReactNode;
  name: string;
} & IFormGroupProps;

const FormGroup = ({ children, name, ...props }: FormGroupProps) => {
  const { errors, touched, isSubmitting } = useFormikContext();
  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  return (
    <BPFormGroup
      {...props}
      disabled={props.disabled || isSubmitting}
      helperText={(error && touch && error) || props.helperText}
      intent={error && touch ? Intent.DANGER : props.intent || Intent.NONE}
    >
      {children}
    </BPFormGroup>
  );
};

export default FormGroup;
