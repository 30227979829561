import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { gql } from '@apollo/client';
import React from 'react';
import DropdownInput from '../../../components/DropdownInput';
import { LocationSelectFragment, useDonorLocationsQuery } from '../../../generated/graphql';

type LocationSelectProps = {
  donorId: string;
  name: string;
};

const LocationSelect = ({ donorId, name }: LocationSelectProps) => {
  const { data, loading, error } = useDonorLocationsQuery({
    variables: {
      id: donorId,
    },
  });

  if (loading) return <Button loading />;
  if (error || !data || !data.donor) {
    return <Button disabled text="Fehler" rightIcon={IconNames.DOUBLE_CARET_VERTICAL} />;
  }

  return (
    <DropdownInput<LocationSelectFragment>
      name={name}
      canClear={false}
      labelPath="name"
      items={data.donor.locations}
      filterable={false}
    />
  );
};

LocationSelect.fragments = {
  locations: gql`
    fragment LocationSelect on Location {
      id
      name
    }
  `,
};

export default LocationSelect;
