import { Checkbox, Classes, HTMLTable, Spinner } from '@blueprintjs/core';
import { NetworkStatus } from '@apollo/client';
import React, { useState } from 'react';
import { ContentCardFooter } from '../../../components/ContentCard';
import Empty from '../../../components/Empty';
import Error from '../../../components/Error';
import InfiniteScrollWrapper from '../../../components/InfiniteScrollWrapper';
import TableHeaderCell from '../../../components/TableHeaderCell';
import { EventStatus, useEventListQuery } from '../../../generated/graphql';
import { dummyEvents } from '../dummy';
import NewsletterEventListItem from './NewsletterEventListItem';

type TableWrapperProps = {
  interactive?: boolean;
  children: React.ReactNode;
  allSelected?: boolean;
  toggleAllSelected?: () => void;
};

type NewsletterEventListProps = {
  onChange: (selectedEvents: string[]) => void;
};

const TableWrapper = ({ allSelected, toggleAllSelected, interactive, children }: TableWrapperProps) => (
  <HTMLTable condensed interactive={interactive} className="w-full">
    <thead>
      <tr>
        <TableHeaderCell>
          <Checkbox checked={!!allSelected} onChange={() => toggleAllSelected?.()} />
        </TableHeaderCell>
        <TableHeaderCell>Titel</TableHeaderCell>
        <TableHeaderCell>Datum / Uhrzeit</TableHeaderCell>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </HTMLTable>
);

const NewsletterEventList = ({ onChange }: NewsletterEventListProps) => {
  const [areAllSelected, setAreAllSelected] = useState<boolean>(false);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const { data, loading, error, fetchMore, networkStatus } = useEventListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      status: EventStatus.Ready,
      withArchived: false,
      // infinite flag is a hack to make the cache's "FielPolicy" read function
      // return all items
      // @ts-ignore
      infinite: true,
    },
  });

  const onToggleAll = () => {
    const newSelectedEvents = areAllSelected ? [] : data?.events?.data.map((item) => item.id) ?? [];
    setSelectedEvents(newSelectedEvents);
    setAreAllSelected(!areAllSelected);
    onChange(newSelectedEvents);
  };

  const onToggleEvent = (eventId: string) => {
    const newSelectedEvents = selectedEvents.includes(eventId)
      ? selectedEvents.filter((selectedEventId) => selectedEventId !== eventId)
      : [...selectedEvents, eventId];

    setSelectedEvents(newSelectedEvents);
    onChange(newSelectedEvents);
  };

  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  if (loading && !loadingMore)
    return (
      <TableWrapper>
        {dummyEvents.map((dummyEvent) => (
          <NewsletterEventListItem
            key={dummyEvent.id}
            item={dummyEvent}
            selected={selectedEvents.includes(dummyEvent.id)}
            onToggle={onToggleEvent}
            skeleton
          />
        ))}
      </TableWrapper>
    );
  if (error) return <Error />;
  if (!data?.events?.data.length) return <Empty />;

  const hasMorePages = data.events.paginatorInfo.hasMorePages;
  const nextPage = data.events.paginatorInfo.currentPage + 1;

  return (
    <InfiniteScrollWrapper
      loading={loadingMore}
      onLoadMore={() =>
        fetchMore({
          variables: {
            page: nextPage,
          },
        })
      }
      hasMorePages={hasMorePages}
    >
      <TableWrapper allSelected={areAllSelected} toggleAllSelected={onToggleAll}>
        {data &&
          data.events.data.map((item) => (
            <NewsletterEventListItem
              key={item.id}
              item={item}
              selected={selectedEvents.includes(item.id)}
              onToggle={onToggleEvent}
            />
          ))}
      </TableWrapper>
      {loadingMore && (
        <ContentCardFooter>
          <div className="flex flex-grow justify-center items-center">
            <Spinner size={16} />
            <div className={[Classes.TEXT_MUTED, 'pl-2'].join(' ')}>Lade mehr</div>
          </div>
        </ContentCardFooter>
      )}
    </InfiniteScrollWrapper>
  );
};

export default NewsletterEventList;
