import { Button, Callout, FileInput, FileInputProps, Spinner } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { useField, useFormikContext } from 'formik';
import { ChangeEvent, useRef, useState } from 'react';
import AppToaster from '../AppToaster';
import config from '../helpers/config';
import { useFocusOnError } from '../helpers/forms';
import { getSubdomain, readCookie } from '../helpers/utils';
import Text from './Text';

type FileUploadInputProps = {
  name: string;
  onUpload: () => void;
  onSuccess: () => void;
  onError: () => void;
} & FileInputProps;

const upload = async (file: File) => {
  const csrfCookie = readCookie('XSRF-TOKEN');
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(`${config.baseUrl}/${getSubdomain()}/upload`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      ...(csrfCookie && { 'X-XSRF-TOKEN': csrfCookie }),
    },
    credentials: 'include',
    body: formData,
  });

  const data = await response.json();
  if (!response.ok) {
    if (response.status === 422) {
      throw new Error('Die Datei ' + data.errors?.file[0]);
    }
    throw new Error(data.message);
  }

  return data;
};

const FileUploadInput = ({ name, onUpload, onSuccess, onError, disabled, ...otherProps }: FileUploadInputProps) => {
  const [uploading, setUploading] = useState(false);
  const formik = useFormikContext();
  const [field] = useField<{ url: string; name: string } | null | undefined>(name);
  const fieldRef = useRef<HTMLInputElement>();
  useFocusOnError({ fieldRef, name: name });

  const onFileSelected = async ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    if (!currentTarget?.files?.[0]) return;

    try {
      onUpload();
      setUploading(true);
      const uploadData = await upload(currentTarget.files[0]);
      setUploading(false);
      onSuccess();
      formik.setFieldValue(name, {
        url: uploadData.url,
        name: uploadData.filename,
      });
    } catch (e) {
      setUploading(false);
      onError();
      if (e instanceof Error) {
        AppToaster.danger(e.message);
      }
    }
  };

  const onDeleteFile = () => {
    formik.setFieldValue(name, null);
  };

  return uploading ? (
    <Spinner />
  ) : (
    <>
      {field.value ? (
        <Callout>
          <div className="flex justify-between">
            <Text>{field.value.name}</Text>
            <Tooltip2 content="Entfernen">
              <Button icon={IconNames.SMALL_CROSS} onClick={onDeleteFile} small />
            </Tooltip2>
          </div>
        </Callout>
      ) : (
        <FileInput
          {...otherProps}
          text="Datei wählen..."
          buttonText="Wählen"
          onInputChange={onFileSelected}
          inputProps={{
            ref: (ref) => (fieldRef.current = ref || undefined), // Blueprint still uses old callback refs
          }}
          disabled={disabled || formik.isSubmitting}
        />
      )}
    </>
  );
};

export default FileUploadInput;
