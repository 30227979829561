import { Icon, InputGroup, Intent, MenuItem, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemPredicate, ItemRenderer, MultiSelect2 } from '@blueprintjs/select';
import { FieldArray, getIn } from 'formik';
import { gql } from '@apollo/client';
import React from 'react';
import { RoleMultiSelectFragment, useRolesQuery } from '../../../generated/graphql';

type Role = RoleMultiSelectFragment;

type RoleMultiSelectProps = {
  name: string;
};

const renderTag = (role: Role) => role.name;

const RoleMultiSelect = ({ name }: RoleMultiSelectProps) => {
  const { data, loading, error } = useRolesQuery();

  return (
    <FieldArray name={name}>
      {({ form, remove, push }) => {
        const selectedRoles: Role[] = getIn(form.values, name) || [];

        const isRoleSelected = (role: Role) => getSelectedRoleIndex(role) !== -1;

        const getSelectedRoleIndex = (role: Role) =>
          selectedRoles.findIndex((selectedRole) => selectedRole.id === role.id);

        const filterRoles: ItemPredicate<Role> = (query, role) =>
          role.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;

        // NOTE: not using Films.itemRenderer here so we can set icons.
        const renderRole: ItemRenderer<Role> = (role, { modifiers, handleClick }) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }
          return (
            <MenuItem
              active={modifiers.active}
              icon={isRoleSelected(role) ? 'tick' : 'blank'}
              key={role.id}
              onClick={handleClick}
              text={role.name}
              shouldDismissPopover={false}
            />
          );
        };

        const handleRoleSelect = (role: Role) => {
          if (!isRoleSelected(role)) {
            push(role);
          } else {
            remove(getSelectedRoleIndex(role));
          }
        };

        const handleTagRemove = (_tag: React.ReactNode, index: number) => {
          remove(index);
        };

        if (loading) {
          return <InputGroup rightElement={<Spinner size={16} />} value="Lade..." disabled />;
        }

        if (error || !data || !data.roles) {
          return (
            <InputGroup leftIcon={<Icon icon={IconNames.ERROR} intent={Intent.DANGER} />} value="Fehler" disabled />
          );
        }

        return (
          <MultiSelect2<Role>
            fill
            itemRenderer={renderRole}
            itemPredicate={filterRoles}
            tagRenderer={renderTag}
            resetOnSelect
            itemsEqual="id"
            items={data.roles}
            selectedItems={selectedRoles}
            onItemSelect={handleRoleSelect}
            noResults={<MenuItem disabled={true} text="Keine Resultate." />}
            popoverProps={{ minimal: true, rootBoundary: 'viewport' }}
            tagInputProps={{
              onRemove: handleTagRemove,
              disabled: form.isSubmitting,
            }}
          />
        );
      }}
    </FieldArray>
  );
};

RoleMultiSelect.fragments = {
  roles: gql`
    fragment RoleMultiSelect on Role {
      id
      name
    }
  `,
};

export default RoleMultiSelect;
