import { DatePickerLocaleUtils } from '@blueprintjs/datetime';
import { format as formatDateFns, parse, parseISO } from 'date-fns';

const WEEKDAYS_LONG: { [k: string]: string[] } = {
  en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  de: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
};
const WEEKDAYS_SHORT: { [k: string]: string[] } = {
  en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
};
const MONTHS: {
  [k: string]: [string, string, string, string, string, string, string, string, string, string, string, string];
} = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  de: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
};

const FIRST_DAY: { [k: string]: number } = {
  en: 0,
  de: 1, // Use Monday as first day of the week
};

const formatDay = (d: Date, locale: string | undefined = 'en') =>
  `${WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;

const formatMonthTitle = (d: Date, locale = 'en') => `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;

const formatWeekdayShort = (weekday: number, locale: string | undefined = 'en') => WEEKDAYS_SHORT[locale][weekday];

const formatWeekdayLong = (weekday: number, locale: string | undefined = 'en') => WEEKDAYS_SHORT[locale][weekday];

const getFirstDayOfWeek = (locale: string | undefined = 'en') => FIRST_DAY[locale];

const getMonths = (locale: string | undefined = 'en') => MONTHS[locale];

const parseDate = (str: string, format?: string, locale?: string) => {
  if (!str) {
    return new Date(-1);
  }

  if (!format) {
    return parseISO(str);
  }

  return parse(str, format, new Date());
};

const formatDate = (date: Date, format?: string | string[], locale?: string) => formatDateFns(date, 'dd.MM.yyyy HH:mm');

export const LocaleUtils: DatePickerLocaleUtils = {
  formatDate,
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
  parseDate,
};
