import { HTMLSelect } from '@blueprintjs/core';
import { useNavigate } from 'react-router-dom';
import { EVENT_ORDER_OPTIONS } from '../../../helpers/constants';
import useQueryParams from '../../../hooks/useQueryParams';

type EventListSorterProps = {
  archive?: boolean;
};

const EventListSorter = ({ archive }: EventListSorterProps) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const orderBy = queryParams.get('orderBy') || EVENT_ORDER_OPTIONS[0].value;

  const changeOrderBy = (newOrderBy: string) => {
    queryParams.set('orderBy', newOrderBy);
    navigate({ search: `?${queryParams.toString()}` });
  };

  if (archive) {
    return null;
  }

  return (
    <HTMLSelect
      className="ml-2"
      value={orderBy}
      onChange={(event) => changeOrderBy(event.currentTarget.value)}
      options={EVENT_ORDER_OPTIONS}
    />
  );
};

export default EventListSorter;
