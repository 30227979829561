import { EventListItemFragment, EventStatus } from '../../generated/graphql';

export const dummyEvents: EventListItemFragment[] = [
  {
    id: '1',
    name: 'Normal Event Name',
    status: EventStatus.Draft,
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 12:00:00',
    is_archived: false,
    taken_tickets: 2,
    available_tickets: 2,
    location: {
      id: '1',
      name: 'Super Location',
      accessible: true,
    },
    candidateCount: 2,
    attendeeCount: 1,
    attendees: [
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: false,
        has_rejected: false,
      },
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: true,
        has_rejected: false,
      },
    ],
    signups: [
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: false,
        has_rejected: false,
      },
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: true,
        has_rejected: false,
      },
    ],
  },
  {
    id: '2',
    name: 'Longer Event name with extension',
    status: EventStatus.Draft,
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 14:00:00',
    is_archived: false,
    taken_tickets: 2,
    available_tickets: 2,
    location: {
      id: '1',
      name: 'Super Location hey',
      accessible: true,
    },
    candidateCount: 2,
    attendeeCount: 1,
    attendees: [
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: false,
        has_rejected: false,
      },
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: true,
        has_rejected: false,
      },
    ],
    signups: [
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: false,
        has_rejected: false,
      },
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: true,
        has_rejected: false,
      },
    ],
  },
  {
    id: '3',
    name: 'Normal Event Name asdf',
    status: EventStatus.Draft,
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 22:00:00',
    is_archived: false,
    taken_tickets: 2,
    available_tickets: 2,
    location: {
      id: '1',
      name: 'Super',
      accessible: true,
    },
    candidateCount: 2,
    attendeeCount: 1,
    attendees: [
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: false,
        has_rejected: false,
      },
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: true,
        has_rejected: false,
      },
    ],
    signups: [
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: false,
        has_rejected: false,
      },
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: true,
        has_rejected: false,
      },
    ],
  },
  {
    id: '4',
    name: 'Normal Event',
    status: EventStatus.Draft,
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 12:00:00',
    is_archived: false,
    taken_tickets: 2,
    available_tickets: 2,
    location: {
      id: '1',
      name: 'Super Location super super',
      accessible: true,
    },
    candidateCount: 2,
    attendeeCount: 1,
    attendees: [
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: false,
        has_rejected: false,
      },
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: true,
        has_rejected: false,
      },
    ],
    signups: [
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: false,
        has_rejected: false,
      },
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: true,
        has_rejected: false,
      },
    ],
  },
  {
    id: '5',
    name: 'Normal Event Name And really really long',
    status: EventStatus.Draft,
    start: '2019-04-01 12:00:00',
    end: '2019-04-01 13:00:00',
    is_archived: false,
    taken_tickets: 2,
    available_tickets: 2,
    location: {
      id: '1',
      name: 'Super Location blu',
      accessible: true,
    },
    candidateCount: 2,
    attendeeCount: 1,
    attendees: [
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: false,
        has_rejected: false,
      },
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: true,
        has_rejected: false,
      },
    ],
    signups: [
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: false,
        has_rejected: false,
      },
      {
        id: '1',
        is_candidate: true,
        is_invited: true,
        is_soul: true,
        has_accepted: true,
        has_rejected: false,
      },
    ],
  },
];
