import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { EmailListItemFragment } from '../../../generated/graphql';
import { formatDayAndTime } from '../../../helpers/dateTimeUtils';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import EmailOverlay from './EmailOverlay';
import { emailStatusMap } from '../../../helpers/dataUtils';

export type EmailListProps = {
  item: EmailListItemFragment;
  skeleton?: boolean;
};

const EmailListItem = ({ item, skeleton }: EmailListProps) => {
  const [isEmailOverlayOpen, setIsEmailOverlayOpen] = useState(false);

  return (
    <tr onClick={() => !skeleton && setIsEmailOverlayOpen(true)}>
      <EmailOverlay id={item.id} isOpen={isEmailOverlayOpen} onClose={() => setIsEmailOverlayOpen(false)} />
      <TableCell>
        <TooltipIcon
          label={emailStatusMap[item.status].label}
          icon={emailStatusMap[item.status].icon}
          intent={emailStatusMap[item.status].intent}
          skeleton={skeleton}
          marginRight
        />
      </TableCell>
      <TableCell>
        <Text skeleton={skeleton}>{item.subject}</Text>
      </TableCell>
      <TableCell>
        {item.sent_at ? (
          <Text skeleton={skeleton}>{formatDayAndTime(item.sent_at)}</Text>
        ) : (
          <Text muted skeleton={skeleton}>
            -
          </Text>
        )}
      </TableCell>
    </tr>
  );
};

EmailListItem.fragments = {
  emails: gql`
    fragment EmailListItem on Email {
      id
      status
      subject
      sent_at
    }
  `,
};

export default EmailListItem;
