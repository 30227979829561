import React from 'react';
import MainContent from '../components/MainContent';
import Sidebar from '../components/Sidebar';
import TopNavBar from '../components/TopNavbar';
import { useQuery } from '@apollo/client';
import { Navigate, useLocation } from 'react-router-dom';
import { IS_AUTHENTICATED } from '../modules/auth/queries';

const MainPage = () => {
  const { data } = useQuery(IS_AUTHENTICATED);

  const location = useLocation();

  if (!data) {
    return null;
  }

  if (!data.isAuthenticated) {
    return <Navigate to={'/auth/login'} replace state={{ from: location }} />;
  }

  return (
    <div className="flex min-h-screen my-0 mx-auto" style={{ maxWidth: 1280 }}>
      <Sidebar />
      <div className="flex-grow" style={{ marginLeft: 230 }}>
        <TopNavBar />
        <MainContent />
      </div>
    </div>
  );
};

export default MainPage;
