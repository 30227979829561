import { DonorListItemFragment, ContactListItemFragment } from '../../generated/graphql';

export const dummyContacts: ContactListItemFragment[] = [
  {
    id: '1',
    display_name: 'Benjamin Wulff',
  },
  {
    id: '2',
    display_name: 'Hans Hammerhead',
  },
];

export const dummyDonors: DonorListItemFragment[] = [
  {
    id: '1',
    name: 'Normal Donor Name',
    contacts: [
      {
        id: '1',
        display_name: 'Benjamin Wulff',
      },
      {
        id: '2',
        display_name: 'Hans Hammerhead',
      },
    ],
  },
  {
    id: '2',
    name: 'Normal Donor Name',
    contacts: [
      {
        id: '1',
        display_name: 'Benjamin Wulff',
      },
    ],
  },
  {
    id: '3',
    name: 'Very, very long donor name haha',
    contacts: [
      {
        id: '1',
        display_name: 'Herz Mohr',
      },
      {
        id: '2',
        display_name: 'Marlin Hundtschmidt',
      },
    ],
  },
  {
    id: '4',
    name: 'Gemeinde mit gemeinschaftlich gGmbH',
    contacts: [
      {
        id: '1',
        display_name: 'Benjamin Wulff',
      },
    ],
  },
];
