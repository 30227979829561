import React from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useSubmit } from 'formik-apollo';
import { Navigate, useLocation } from 'react-router-dom';
import { useLoginMutation } from '../../../generated/graphql';
import { setPersisted } from '../../../helpers/constants';
import { getLaravelValidationErrors, showFormErrorMessage } from '../../../helpers/graphql';
import LoginForm, { ILoginFormValues } from '../components/LoginForm';
import { IS_AUTHENTICATED } from '../queries';

const defaultPage = { pathname: '/' };

const LoginPage = () => {
  const client = useApolloClient();
  const { state }: { state: { from?: string } } = useLocation();
  const { data } = useQuery(IS_AUTHENTICATED);
  const [login] = useLoginMutation();

  const handleSubmit = useSubmit({
    mutate: (values: ILoginFormValues) =>
      login({
        variables: {
          input: values,
        },
      }),
    onCompleted: ({ data }) => {
      if (data && data.login) {
        setPersisted('isLoggedIn', true);
        setPersisted(
          'permissions',
          data.login.user.permissions.map((permission) => permission.name),
        );
        client.writeQuery({
          query: IS_AUTHENTICATED,
          data: {
            isAuthenticated: true,
          },
        });
      }
    },
    onError: showFormErrorMessage,
    getErrors: getLaravelValidationErrors,
  });

  if (!data) return null;
  if (data.isAuthenticated) return <Navigate to={(state && state.from) || defaultPage} replace />;
  return (
    <div className="flex min-h-screen items-center justify-center flex-col">
      <LoginForm onSubmit={handleSubmit} />
    </div>
  );
};

export default LoginPage;
