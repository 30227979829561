import { IMenuItemProps, MenuItem } from '@blueprintjs/core';
import { useMatch } from 'react-router-dom';
import { useNavigateSafely } from '../hooks/useNavigateSafely';

type NavMenuItemProps = {
  path: string;
} & IMenuItemProps;

const NavMenuItem = ({ path, ...rest }: NavMenuItemProps) => {
  const match = useMatch({ path });
  const buildNavigateSafely = useNavigateSafely();

  return (
    <MenuItem
      {...rest}
      href={path}
      onClick={buildNavigateSafely(path)}
      active={!!match}
      popoverProps={{
        usePortal: true, // This is needed for z-index to work properly
      }}
    />
  );
};

export default NavMenuItem;
