import { Alert, Button, Intent, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import {
  EventDetailPageFragment,
  EventStatus,
  useDeleteEventMutation,
  usePublishEventMutation,
} from '../../../generated/graphql';
import { isInFuture } from '../../../helpers/dateTimeUtils';
import { showErrorMessage } from '../../../helpers/graphql';

type EventDetailMenuProps = {
  event: EventDetailPageFragment;
  onEdit: () => void;
  onClone: () => void;
  onCancel: () => void;
};

const EventDetailMenu = ({ event, onEdit, onClone, onCancel }: EventDetailMenuProps) => {
  const [isPublishAlertOpen, setIsPublishAlertOpen] = useState(false);
  const navigate = useNavigate();

  const options = {
    variables: {
      id: event.id,
    },
    onError: showErrorMessage,
  };

  const [deleteEvent, { loading: deleting }] = useDeleteEventMutation({
    ...options,
    onCompleted: () => {
      AppToaster.success(`${event.name}  wurde in den Papierkorb gelegt`);
      navigate(-1);
    },
  });
  const [publishEvent, { loading: publishing }] = usePublishEventMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${event.name}  wurde veröffentlicht!`),
  });

  return (
    <>
      <Alert
        isOpen={isPublishAlertOpen}
        cancelButtonText="Abbrechen"
        confirmButtonText="Veröffentlichen"
        icon={IconNames.GLOBE_NETWORK}
        intent={Intent.SUCCESS}
        onClose={() => setIsPublishAlertOpen(false)}
        onConfirm={publishEvent as any}
      >
        <p>Soll diese Veranstaltung wirklich veröffentlicht werden?</p>
        <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
      </Alert>
      <Button text="Bearbeiten" icon={IconNames.EDIT} className="ml-2" onClick={onEdit} />
      <Popover2
        position={Position.BOTTOM}
        content={
          <Menu>
            {(event.status === EventStatus.Draft || event.status === EventStatus.Ready) && (
              <MenuItem
                text="Veröffentlichen"
                icon={IconNames.GLOBE_NETWORK}
                intent={Intent.SUCCESS}
                onClick={() => setIsPublishAlertOpen(true)}
                disabled={event.status !== EventStatus.Ready}
              />
            )}
            <MenuItem text="Duplizieren" icon={IconNames.DUPLICATE} onClick={onClone} />
            {event.status === EventStatus.Closed && isInFuture(event.start) ? (
              <MenuItem text="Absagen" icon={IconNames.BAN_CIRCLE} intent={Intent.DANGER} onClick={onCancel} />
            ) : (
              <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteEvent as any} />
            )}
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} className="ml-2" loading={deleting || publishing} />
      </Popover2>
    </>
  );
};

export default EventDetailMenu;
