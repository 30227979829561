import { Button, Divider, Intent } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { IconNames } from '@blueprintjs/icons';
import { getIn } from 'formik';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useQueryParams from '../hooks/useQueryParams';
import FilterHeader from './FilterHeader';
import Text from './Text';

export type FilterType = {
  key: string;
  labelPath?: string;
};

type FilterProps = {
  filters: FilterType[];
  children: React.ReactNode;
};

type FilterValues = { [k: string]: any };

type FilterContextType = {
  values: FilterValues;
  setFilterValue: (name: string, value: any) => void;
  commitFilters: () => void;
  resetFilters: () => void;
};

const FilterContext = createContext<FilterContextType>({} as FilterContextType);

const reviveLabelPath = (label: string, labelPath: string): object => {
  const [key, ...nextKeyParts] = labelPath.split('.');
  return {
    [key]: nextKeyParts.length ? reviveLabelPath(label, nextKeyParts.join('.')) : label,
  };
};

const toFilterValues = (queryParams: URLSearchParams, filters: FilterType[]) =>
  filters.reduce(
    (acc, filter) => ({
      ...acc,
      [filter.key]: unserializeFilterValue(queryParams.get(filter.key), filter.labelPath),
    }),
    {},
  );

export const unserializeFilterValue = (value: string | null, labelPath?: string): any => {
  if (value === null) {
    return undefined;
  }

  let res: any = value;
  try {
    res = JSON.parse(res);
  } catch {}

  // Revive flattened object
  if (res.id !== undefined && res.id !== undefined) {
    if (!labelPath) throw Error('labelPath must be provided');

    res = {
      id: res.id,
      ...reviveLabelPath(res.label, labelPath),
    };
  }

  return res;
};

const serializeFilterValue = (value: any, labelPath?: string): string => {
  let res = value;

  // If value is an object, we only want to keep id and label
  if (value.id !== undefined) {
    if (!labelPath) throw Error('labelPath must be provided');

    res = {
      id: value.id,
      label: getIn(value, labelPath),
    };
  }
  return JSON.stringify(res);
};

const Filter = ({ filters, children }: FilterProps) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const [values, setValues] = useState<FilterValues>({});
  const filterCount = filters
    .map((filter) => filter.key)
    .filter((key) => values[key] !== null && values[key] !== undefined).length;

  const resetFilters = useCallback(() => {
    setValues({});
  }, []);

  useEffect(() => setValues(toFilterValues(queryParams, filters)), [filters, queryParams]);

  const setFilterValue = useCallback(
    (name: string, value: any) =>
      setValues((currentFilters) => ({
        ...currentFilters,
        [name]: value,
      })),
    [],
  );

  const commitFilters = useCallback(() => {
    filters.forEach(({ key, labelPath }) =>
      values[key] === null || values[key] === undefined
        ? queryParams.delete(key)
        : queryParams.set(key, serializeFilterValue(values[key], labelPath)),
    );
    navigate({ search: `?${queryParams.toString()}` });
  }, [filters, navigate, queryParams, values]);

  return (
    <FilterContext.Provider value={{ values, setFilterValue, resetFilters, commitFilters }}>
      <Popover2
        content={
          <div>
            <FilterHeader />
            {children}
          </div>
        }
      >
        <Button
          text={
            <div className="flex">
              Filter
              {!!filterCount && (
                <>
                  <Divider
                    style={{
                      margin: '2px 7px !important',
                    }}
                  />
                  <Text intent={Intent.PRIMARY}>{filterCount}</Text>
                </>
              )}
            </div>
          }
          icon={IconNames.FILTER}
        />
      </Popover2>
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => useContext(FilterContext);

export default Filter;
