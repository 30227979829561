import React from 'react';
import AttendList from './AttendList';

type AttendsTabProps = {
  isSoul: boolean;
  prospectId: string;
};

const AttendsTab = ({ isSoul, prospectId }: AttendsTabProps) => (
  <div className="overflow-y-auto -mx-5">
    <AttendList isSoul={isSoul} prospectId={prospectId} />
  </div>
);

export default AttendsTab;
