import { gql } from '@apollo/client';
import { Elevation, Tag } from '@blueprintjs/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { PropertyList, PropertyListHeader, PropertyListItem } from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useRoleDetailQuery } from '../../../generated/graphql';
import RoleDetailMenu from '../components/RoleDetailMenu';
import RoleEditOverlay from '../components/RoleEditOverlay';

type RouteParams = {
  roleId?: string;
};

const RoleDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { roleId } = useParams<RouteParams>();
  const { data, loading, error } = useRoleDetailQuery({
    variables: {
      id: roleId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data?.role) return <NotFoundCard resource="Veranstaltungsort" />;

  const { role } = data;

  return (
    <>
      <RoleEditOverlay id={role.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} className="mb-5">
        <ContentCardHeader
          leftElement={
            <>
              <Text large>{role.name}</Text>
              <Tag minimal className="ml-2">
                Rolle
              </Tag>
            </>
          }
          rightElement={<RoleDetailMenu role={role} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div className="flex py-4 px-5">
          <PropertyList>
            <PropertyListHeader>Details</PropertyListHeader>
            <PropertyListItem label="Berechtigungen">
              {role.permissions.map((permission) => (
                <Text key={permission.id}>{`${permission.title}, `}</Text>
              ))}
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>
    </>
  );
};

RoleDetailPage.fragments = {
  role: gql`
    fragment RoleDetailPage on Role {
      id
      name
      permissions {
        id
        title
      }
    }
  `,
};

export default RoleDetailPage;
