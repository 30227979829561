import { gql } from '@apollo/client';

export const IS_AUTHENTICATED = gql`
  query IsUserAuthenticated {
    isAuthenticated @client
  }
`;

export const CURRENT_TENANT = gql`
  query CurrentTenant {
    currentTenant {
      id
      subdomain
      name
      use_legacy_newsletter
    }
  }
`;

export const LOGIN_USER = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      user {
        id
        email
        display_name
        permissions {
          id
          name
        }
      }
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation Logout {
    logout {
      status
      message
    }
  }
`;

export const SEND_RESET_EMAIL = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      status
      message
    }
  }
`;

export const RESET_USER = gql`
  mutation UpdateForgottenPassword($input: NewPasswordWithCodeInput!) {
    updateForgottenPassword(input: $input) {
      status
      message
    }
  }
`;
