import React from 'react';
import { Classes } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

type ResourceLinkProps = {
  path: string;
  skeleton?: boolean;
  text: string;
  muted?: boolean;
};

const ResourceLink = ({ path, text, skeleton, muted }: ResourceLinkProps) => {
  const classes = [];

  if (skeleton) {
    classes.push(Classes.SKELETON);
  }

  if (!path || muted) {
    classes.push(Classes.TEXT_MUTED);
  }

  if (!path) {
    return <span className={classes.join(' ')}>{text}</span>;
  }

  return (
    <Link to={path} className={classes.join(' ')}>
      {text}
    </Link>
  );
};

export default ResourceLink;
