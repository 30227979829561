import React from 'react';
import PaginatedTable from '../../../components/PaginatedTable';
import { Trashed, useCategoryListQuery } from '../../../generated/graphql';
import { ListComponent } from '../../../types';
import { dummyCategories } from '../dummy';
import CategoryListItem from './CategoryListItem';

const CategoryList = ({ isSelected, onToggle, trash }: ListComponent) => {
  const { data, loading, error } = useCategoryListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    fetchPolicy: 'network-only',
    variables: {
      trashed: trash ? Trashed.Only : Trashed.Without,
    },
  });

  const categories = data?.categories || [];

  return (
    <PaginatedTable
      columns={[...(!!onToggle ? [''] : []), 'Name', 'Beschreibung']}
      interactive={!onToggle}
      loading={loading}
      hasError={!!error}
      empty={!categories.length}
    >
      {(loading ? dummyCategories : categories).map((item) => (
        <CategoryListItem
          key={item.id}
          item={item}
          skeleton={loading}
          selected={isSelected && isSelected(item.id)}
          onToggle={onToggle}
        />
      ))}
    </PaginatedTable>
  );
};

export default CategoryList;
