import { ApolloError, isApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import AppToaster from '../AppToaster';

/**
 * The problems associated with a validation error.
 */
export type Problems = Record<string, string[]>;

export type ErrorBag = { [k: string]: string | ErrorBag };

/**
 * A `GraphQLError` instance that looks like a validation error.
 */
export interface ValidationError extends GraphQLError {
  extensions: { validation: Problems };
}

export interface OperationError extends GraphQLError {
  extensions: { category: 'operation' };
}

const isString = (value: any): value is string => typeof value === 'string';
const isObject = (value: any) => value && typeof value === 'object';

const obsoleteKeysRegex = /input\.|create\.|update\.|sync\.|connect\./gi;
const cleanKey = (key: string) => key.replace(obsoleteKeysRegex, '');

/**
 * Indicates that an error is a validation error.
 */
export const VALIDATION_ERROR = 'validation';
/**
 * Indicates that an error is an operation error.
 */
export const OPERATION_ERROR = 'operation';

/**
 * Is the error a validation error?
 */
export const isValidationError = (error: any): error is ValidationError =>
  isObject(error) &&
  isObject(error.extensions) &&
  isObject(error.extensions!.validation) &&
  error.extensions!.category === VALIDATION_ERROR;

/**
 * Is the error an operation error?
 */
export const isOperationError = (error: any): error is OperationError =>
  isObject(error) && isObject(error.extensions) && error.extensions!.category === OPERATION_ERROR;

const addError = (errors: { [k: string]: {} }, keyPath: string, message: string): {} => {
  const [key, ...nextKeyParts] = keyPath.split('.');
  return {
    ...errors,
    [key]: nextKeyParts.length ? addError(errors[key] || {}, nextKeyParts.join('.'), message) : message,
  };
};

/**
 * Extract all validation errors from an error.
 */
export const getLaravelValidationErrors = (error: ApolloError | GraphQLError | Error): Record<string, string> => {
  if (error && isApolloError(error)) {
    return error.graphQLErrors.reduce((acc, e) => Object.assign(acc, getLaravelValidationErrors(e)), {});
  }

  if (error && isValidationError(error)) {
    return Object.entries(error.extensions.validation).reduce((acc, [key, value]) => {
      const message = Array.isArray(value) ? value[0] : value;
      return isString(message) ? addError(acc, cleanKey(key), message) : acc;
    }, {});
  }

  return {};
};

const getFirstValidationError = (errors: ErrorBag): string => {
  const firstMessage = Object.values(errors)[0];

  if (!firstMessage) return 'Interner Fehler';
  return isString(firstMessage) ? firstMessage : getFirstValidationError(firstMessage);
};

export const showFormErrorMessage = (error: ApolloError | GraphQLError | Error) => {
  AppToaster.danger(getUserErrorMessage(error, true));
};

export const showErrorMessage = (error: ApolloError | GraphQLError | Error) => {
  AppToaster.danger(getUserErrorMessage(error));
};

export const getUserErrorMessage = (
  error: ApolloError | GraphQLError | Error,
  hideValidationMessage: boolean = false,
): string => {
  if (!error) return 'Interner Fehler';

  if (isApolloError(error)) {
    if (error.graphQLErrors.length) return getUserErrorMessage(error.graphQLErrors[0], hideValidationMessage);
    if (error.networkError) return getUserErrorMessage(error.networkError, hideValidationMessage);
    return 'Interner Fehler';
  }

  if (isValidationError(error)) {
    return hideValidationMessage ? 'Ungültige Daten' : getFirstValidationError(getLaravelValidationErrors(error));
  }

  if (isOperationError(error)) {
    return error.message;
  }

  return 'Interner Fehler';
};
