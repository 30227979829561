/** @jsxImportSource @emotion/react */
import { Colors, Classes, Props } from '@blueprintjs/core';
import { css } from '@emotion/react';

type TableCellProps = {
  small?: boolean;
  muted?: boolean;
  center?: boolean;
  right?: boolean;
  children: React.ReactNode;
} & Props;

const TableCell = ({ children, small, muted, center, right, className }: TableCellProps) => {
  const classes = [className];

  if (small) {
    classes.push(Classes.TEXT_SMALL);
  }

  if (muted) {
    classes.push(Classes.TEXT_MUTED);
  }

  if (muted) {
    classes.push(Classes.TEXT_MUTED);
  }

  if (center) {
    classes.push('text-center');
  }

  if (right) {
    classes.push('text-right');
  }

  return (
    <td css={styles.tableCell} className={classes.join(' ')}>
      {children}
    </td>
  );
};

export default TableCell;

const styles = {
  tableCell: css`
    vertical-align: middle !important;
    border-top: 1px ${Colors.LIGHT_GRAY1} solid;
    height: 55px;
    &:first-of-type {
      padding-left: 20px;
    }
    &:last-of-type {
      padding-right: 20px;
    }
    tr:first-of-type & {
      border-top: none;
    }
    tr.active & {
      background-color: ${Colors.LIGHT_GRAY5} !important;
    }
    tr:hover & {
      background-color: ${Colors.LIGHT_GRAY5} !important;
    }
  `,
};
