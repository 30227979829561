import { EmployeeListItemFragment, Role } from '../../generated/graphql';

export const dummyRoles: Role[] = [
  {
    id: '1',
    name: 'Admin',
    created_at: '2019-01-04 12:00:00',
    updated_at: '2019-01-04 12:00:00',
    permissions: [],
  },
  {
    id: '2',
    name: 'Mitarbeiter',
    created_at: '2019-01-04 12:00:00',
    updated_at: '2019-01-04 12:00:00',
    permissions: [],
  },
  {
    id: '3',
    name: 'Entwickler',
    created_at: '2019-01-04 12:00:00',
    updated_at: '2019-01-04 12:00:00',
    permissions: [],
  },
];

export const dummyEmployees: EmployeeListItemFragment[] = [
  {
    id: '1',
    meta: {
      position: 'Entwickler',
    },
    display_name: 'Benjamin Wulff',
    email: 'benjamin.wulff@me.com',
    phone: '0000 0000 0000',
    roles: [dummyRoles[0], dummyRoles[1]],
  },
  {
    id: '2',
    meta: {
      position: 'Werksstudentin',
    },
    display_name: 'Mario Speedwagon',
    email: 'mario.speedwagon@googlemail.com',
    phone: '0000 0000 00',
    roles: [dummyRoles[2]],
  },
  {
    id: '3',
    meta: {
      position: 'Kulturschlüssel-Mitarbeiter',
    },
    display_name: 'Anna Sthesia',
    email: 'anna.sthesia@gmail.de',
    phone: '000 0000 00',
    roles: [dummyRoles[0], dummyRoles[1], dummyRoles[2]],
  },
  {
    id: '4',
    meta: {
      position: 'Projektleiter',
    },
    display_name: 'Forcewind Forcewind',
    email: 'mitarbeiter@email.de',
    phone: '0000 0000 00',
    roles: [dummyRoles[0]],
  },
];
